import React from "react";
import "../styles/UploadProgressBar.css"; // Make sure to import your CSS

const UploadProgressBar = ({ progress }) => {
  // Make sure progress is valid (between 0-100)
  const validProgress = Math.max(0, Math.min(100, progress || 0));

  // Only render if there's valid progress to show
  if (validProgress <= 0 || validProgress >= 100) {
    return null; // Don't render anything if progress is 0 or 100
  }

  return (
    <div className="upload-progress-container">
      <div className="progress-label">Uploading files...</div>
      <div className="progress-bar-container">
        <div
          className="progress-bar-fill"
          style={{ width: `${validProgress}%` }}
        ></div>
      </div>
      <div className="progress-percentage">{validProgress}%</div>
    </div>
  );
};

export default UploadProgressBar;
