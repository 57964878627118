import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { TailSpin } from "react-loader-spinner";
import "../styles/PricingPage.css";

export default function PricingPage() {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [currency, setCurrency] = useState("usd"); // Default to USD
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setClientSecret(decoded.stripeClientSecret);
      setUserEmail(decoded.email);
    }

    // Detect user location
    detectUserLocation();
  }, []);

  const detectUserLocation = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();

      // Set currency to CAD if user is in Canada
      if (data.country_code === "CA") {
        setCurrency("cad");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error detecting location:", error);
      setLoading(false);
    }
  };

  const handleCurrencySwitch = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  // Get the appropriate pricing table ID based on currency
  const getPricingTableId = () => {
    return currency === "cad"
      ? process.env.REACT_APP_STRIPE_CAD_PRICING_TABLE_ID
      : process.env.REACT_APP_STRIPE_USD_PRICING_TABLE_ID;
  };

  // Show loading while we detect location or get user data
  if (loading || (!clientSecret && !userEmail)) {
    return (
      <div className="pricing-page-container">
        <button className="back-button" onClick={handleBackClick}>
          &larr; Back
        </button>
        <div className="loading-container">
          <TailSpin color="#0033a0" height={50} width={50} />
          <p>Loading pricing options...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pricing-page-container">
      <button className="back-button" onClick={handleBackClick}>
        &larr; Back
      </button>
      <h1 className="pricing-page-title">Choose Your Plan</h1>

      {/* Currency switcher */}
      <div className="currency-switcher">
        <button
          className={currency === "usd" ? "active" : ""}
          onClick={() => handleCurrencySwitch("usd")}
        >
          USD
        </button>
        <button
          className={currency === "cad" ? "active" : ""}
          onClick={() => handleCurrencySwitch("cad")}
        >
          CAD
        </button>
      </div>

      <stripe-pricing-table
        pricing-table-id={getPricingTableId()}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        {...(clientSecret
          ? { "customer-session-client-secret": clientSecret }
          : { "customer-email": userEmail })}
      />
    </div>
  );
}
