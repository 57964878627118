import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    if (pathParts[1] === "invite" && pathParts[2]) {
      const inviteToken = pathParts[2];
      localStorage.setItem("pendingInviteToken", inviteToken);
      localStorage.setItem("returnPath", `/invite/${inviteToken}`);
    }
  }, []);

  const handleGoogleLogin = () => {
    window.location.href = "/api/auth?skipConsent=true";
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section - Enhanced messaging while keeping clean design */}
      <header className="relative px-4 pt-6 pb-10 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <img
            src="/eblogo_2.png"
            alt="ExpenseBot"
            className="h-16 mx-auto mb-8"
          />

          <h1 className="mb-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Snap. Submit. Done.
          </h1>

          <p className="max-w-xl mx-auto mb-8 text-xl text-gray-600">
            Try your first receipt now - 30 seconds from click to spreadsheet.
            No app download required!
          </p>

          {/* Video Section */}
          <div className="relative mx-auto overflow-hidden bg-gray-100 rounded-xl aspect-video max-w-4xl mb-4">
            <iframe
              className="absolute inset-0 w-full h-full"
              src="https://www.youtube.com/embed/pZ-J6JzFPAI?autoplay=1&mute=1&rel=0"
              title="ExpenseBot Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </header>

      {/* Device Compatibility Banner */}
      <div className="bg-gray-50 py-3 border-y border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-8">
            <div className="flex items-center text-gray-600">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 384 512">
                <path
                  fill="currentColor"
                  d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                />
              </svg>
              iPhone
            </div>
            <div className="flex items-center text-gray-600">
              <svg
                className="w-5 h-5 mr-2 android-svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M6 18c0 .55.45 1 1 1h1v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V19h2v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V19h1c.55 0 1-.45 1-1V8H6v10zM3.5 8C2.67 8 2 8.67 2 9.5v7c0 .83.67 1.5 1.5 1.5S5 17.33 5 16.5v-7C5 8.67 4.33 8 3.5 8zm17 0c-.83 0-1.5.67-1.5 1.5v7c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5zm-4.97-5.84l1.3-1.3c.2-.2.2-.51 0-.71-.2-.2-.51-.2-.71 0l-1.48 1.48C13.85 1.23 12.95 1 12 1c-.96 0-1.86.23-2.66.63L7.85.15c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71l1.31 1.31C6.97 3.26 6 5.01 6 7h12c0-1.99-.97-3.75-2.47-4.84zM10 5H9V4h1v1zm5 0h-1V4h1v1z" />
              </svg>
              Android
            </div>
            <div className="flex items-center text-gray-600">
              <svg
                className="w-5 h-5 mr-2"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              Desktop
            </div>
          </div>
        </div>
      </div>

      {/* Features Section - Refined messaging */}
      <section className="py-16 bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-12 text-3xl font-bold text-center text-gray-900">
            Expense Tracking, Simplified.
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {/* Feature 1: Fastest Expense Capture */}
            <div className="p-6 transition-all bg-white rounded-2xl hover:shadow-lg text-center">
              <div className="flex items-center justify-center h-48 mb-6">
                <img
                  src="/phone3.png"
                  alt="Snap a receipt with your phone"
                  className="object-contain w-full h-full"
                />
              </div>
              <h3 className="mb-2 text-xl font-semibold text-gray-900">
                Snap & Done
              </h3>
              <p className="text-gray-600">
                No apps, no extra steps.{" "}
                <strong>
                  Just snap a picture, and your expense is instantly
                  processed—seconds from receipt to spreadsheet.
                </strong>
              </p>
            </div>

            {/* Feature 2: AI-Powered, Zero Manual Work */}
            <div className="p-6 transition-all bg-white rounded-2xl hover:shadow-lg text-center">
              <div className="flex items-center justify-center h-48 mb-6">
                <img
                  src="/middleimage.png"
                  alt="Smart expense processing"
                  className="object-contain w-full h-full"
                />
              </div>
              <h3 className="mb-2 text-xl font-semibold text-gray-900">
                AI-Powered, Zero Manual Work
              </h3>
              <p className="text-gray-600">
                <strong>
                  ExpenseBot's AI instantly extracts every detail—totals, taxes,
                  tips, even handwritten notes.
                </strong>{" "}
                No manual input, no hassle.
              </p>
            </div>

            {/* Feature 3: Native Spreadsheet Integration */}
            <div className="p-6 transition-all bg-white rounded-2xl hover:shadow-lg text-center">
              <div className="flex items-center justify-center h-48 mb-6">
                <img
                  src="/3rdphoto.png"
                  alt="Expense data in your Drive"
                  className="object-contain w-full h-full"
                />
              </div>
              <h3 className="mb-2 text-xl font-semibold text-gray-900">
                Native Spreadsheet Integration
              </h3>
              <p className="text-gray-600">
                <strong>
                  Works directly in your spreadsheets—no new systems to learn.
                </strong>{" "}
                Perfect for spreadsheet power users and finance teams.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Currency Support Banner */}
      <div className="bg-gray-50 py-3 border-y border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-8">
            <div className="text-gray-600 text-sm font-medium text-center">
              Multi-Currency
            </div>
            <div className="flex items-center space-x-6">
              <span className="text-xl">🇺🇸</span>
              <span className="text-xl">🇨🇦</span>
              <span className="text-xl">🇪🇺</span>
              <span className="text-xl">🇬🇧</span>
              <span className="text-xl">🇦🇺</span>
            </div>
          </div>
        </div>
      </div>

      {/* Why Teams Choose ExpenseBot - Enhanced competitive section */}
      <section className="py-16 bg-gradient-to-b from-white to-blue-50">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-12 text-3xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-yellow-400">
            Expense Automation, Done Right
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {/* 1. Perfect AI Automation */}
            <div className="p-8 transition-all bg-white rounded-2xl hover:shadow-lg">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-xl font-semibold text-gray-900">
                Perfect AI Automation
              </h3>
              <p className="text-gray-600 mb-4">
                AI that gets every detail right the first time - total, tax,
                tip, and even handwritten notes. No manual fixes needed.
              </p>
              <div className="mt-6 rounded-xl bg-gradient-to-r from-gray-50 to-white p-4 border border-gray-100">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      ExpenseBot
                    </div>
                    <div className="text-sm text-gray-800 font-medium">
                      AI-driven accuracy, zero hassle
                    </div>
                  </div>
                  <div className="w-px h-8 bg-gray-200"></div>
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      Others
                    </div>
                    <div className="text-sm text-red-600">
                      Time-wasting manual fixes
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 2. Smart Auto-Grouping */}
            <div className="p-8 transition-all bg-white rounded-2xl hover:shadow-lg">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-xl font-semibold text-gray-900">
                Smart Auto-Grouping
              </h3>
              <p className="text-gray-600 mb-4">
                Our #1 requested feature: AI instantly sorts between personal,
                business, client, project, and trip expenses—zero manual
                organization needed.
              </p>
              <div className="mt-6 rounded-xl bg-gradient-to-r from-gray-50 to-white p-4 border border-gray-100">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      ExpenseBot
                    </div>
                    <div className="text-sm text-gray-800 font-medium">
                      AI-powered expense organization
                    </div>
                  </div>
                  <div className="w-px h-8 bg-gray-200"></div>
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      Others
                    </div>
                    <div className="text-sm text-red-600">
                      Time-consuming, frustrating manual sorting
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 3. Automatic Compliance Review */}
            <div className="p-8 transition-all bg-white rounded-2xl hover:shadow-lg">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-xl font-semibold text-gray-900">
                Automatic Compliance Review
              </h3>
              <p className="text-gray-600 mb-4">
                AI instantly checks every expense against your corporate policy,
                marking each as compliant or not with a clear rationale. No more
                manual review—just instant, audit-ready compliance.
              </p>
              <div className="mt-6 rounded-xl bg-gradient-to-r from-gray-50 to-white p-4 border border-gray-100">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      ExpenseBot
                    </div>
                    <div className="text-sm text-gray-800 font-medium">
                      Automated compliance & control
                    </div>
                  </div>
                  <div className="w-px h-8 bg-gray-200"></div>
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      Others
                    </div>
                    <div className="text-sm text-red-600">
                      Time-consuming, manual policy reviews
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 4. No App Required */}
            <div className="p-8 transition-all bg-white rounded-2xl hover:shadow-lg">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-xl font-semibold text-gray-900">
                No App Required
              </h3>
              <p className="text-gray-600 mb-4">
                Works instantly on any device—no downloads, no approvals, no IT
                headaches. Just open and use.
              </p>
              <div className="mt-6 rounded-xl bg-gradient-to-r from-gray-50 to-white p-4 border border-gray-100">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      ExpenseBot
                    </div>
                    <div className="text-sm text-gray-800 font-medium">
                      Instant access, zero hassles
                    </div>
                  </div>
                  <div className="w-px h-8 bg-gray-200"></div>
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      Others
                    </div>
                    <div className="text-sm text-red-600">
                      Forced apps & constant updates
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 5. True Data Ownership */}
            <div className="p-8 transition-all bg-white rounded-2xl hover:shadow-lg">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-xl font-semibold text-gray-900">
                True Data Ownership
              </h3>
              <p className="text-gray-600 mb-4">
                Your data lives in your Google Drive, not our servers—no tedious
                exports needed—you own and control your data, always.
              </p>
              <div className="mt-6 rounded-xl bg-gradient-to-r from-gray-50 to-white p-4 border border-gray-100">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      ExpenseBot
                    </div>
                    <div className="text-sm text-gray-800 font-medium">
                      Your data, your Google Drive, your control
                    </div>
                  </div>
                  <div className="w-px h-8 bg-gray-200"></div>
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      Others
                    </div>
                    <div className="text-sm text-red-600">
                      Trapped data, forced exports, limited access
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 6. Spreadsheet Power */}
            <div className="p-8 transition-all bg-white rounded-2xl hover:shadow-lg">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14 flex items-center justify-center">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-xl font-semibold text-gray-900">
                Spreadsheet Power
              </h3>
              <p className="text-gray-600 mb-4">
                All the flexibility of spreadsheets, supercharged with AI
                automation. No new systems to learn—just effortless expense
                tracking in the tools you already use.
              </p>
              <div className="mt-6 rounded-xl bg-gradient-to-r from-gray-50 to-white p-4 border border-gray-100">
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      ExpenseBot
                    </div>
                    <div className="text-sm text-gray-800 font-medium">
                      AI-enhanced spreadsheets, zero friction
                    </div>
                  </div>
                  <div className="w-px h-8 bg-gray-200"></div>
                  <div className="flex-1">
                    <div className="text-xs uppercase tracking-wider text-gray-500 mb-1">
                      Others
                    </div>
                    <div className="text-sm text-red-600">
                      Locked in their interface, rigid workflows
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Interactive Walkthrough Section */}
      <section className="py-20 bg-gradient-to-r from-blue-50 to-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto mb-16 text-center">
            <h2 className="mb-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              See How It Works
            </h2>
            <p className="text-xl leading-relaxed text-gray-600">
              Experience how easy expense management can be. Takes just seconds
              to try.
            </p>
          </div>

          <div className="relative max-w-4xl mx-auto">
            {/* Decorative elements */}
            <div className="absolute -top-4 -left-4 w-24 h-24 bg-blue-50 rounded-full opacity-50 blur-xl"></div>
            <div className="absolute -bottom-4 -right-4 w-32 h-32 bg-yellow-50 rounded-full opacity-50 blur-xl"></div>

            {/* Demo container with enhanced styling */}
            <div className="relative bg-white rounded-2xl shadow-2xl overflow-hidden">
              {/* Top bar design */}
              <div className="bg-gray-50 border-b border-gray-200 p-4 flex items-center justify-between">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 rounded-full bg-red-400"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                  <div className="w-3 h-3 rounded-full bg-green-400"></div>
                </div>
                <div className="text-sm font-medium text-gray-500">
                  Interactive Demo
                </div>
                <div className="w-16"></div>
              </div>

              {/* Supademo iframe container */}
              <div
                className="relative"
                style={{ aspectRatio: "1.7563451776649746" }}
              >
                <iframe
                  src="https://app.supademo.com/embed/cm6i5qcto08hho60p6cu3e3nw?embed_v=2"
                  loading="lazy"
                  title="ExpenseBot Receipt Capture"
                  allow="clipboard-write"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                />
              </div>
            </div>

            <p className="mt-6 text-center text-gray-600 text-sm">
              👆 Click or tap anywhere to advance through the demo
            </p>
          </div>
        </div>
      </section>

      {/* Visual separator */}
      <div className="bg-gray-50 py-3 border-y border-gray-100"></div>
      {/* Benefits Section - Enhanced user-specific messaging */}
      {/* Built for Everyone - Optimized with Missing Features Added */}
      <section className="py-16 bg-gradient-to-r from-blue-50 to-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-12 text-3xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-yellow-400">
            Built for Everyone
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {/* For Individuals */}
            <div className="p-8 bg-white rounded-2xl hover:shadow-lg transition-all">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-900">
                For Individuals
              </h3>
              <p className="text-gray-600 mb-4">
                Perfect for freelancers, gig workers, and self-employed
                professionals.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li>✅ Keep business expenses organized for tax time</li>
                <li>✅ Create & edit custom categories in spreadsheets</li>
                <li>✅ Easily share records with your accountant</li>
                <li>✅ Support documents ready for review & audit</li>
                <li>✅ Track personal tax receipts (medical, donations)</li>
              </ul>
            </div>

            {/* For Business Teams */}
            <div className="p-8 bg-white rounded-2xl hover:shadow-lg transition-all">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-900">
                For Business Teams
              </h3>
              <p className="text-gray-600 mb-4">
                Submit expenses in seconds, get reimbursed faster—no training
                needed.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li>✅ Use your phone’s camera—no app needed</li>
                <li>✅ Automatic trip and project grouping</li>
                <li>✅ Auto-tag expenses by client for easy bill-back</li>
                <li>✅ Multi-currency support built-in</li>
                <li>✅ Grant assistant access for team management</li>
                <li>✅ Track mileage effortlessly</li>
                <li>
                  ✅ <strong>Upload hundreds of receipts in seconds</strong>
                </li>{" "}
                {/* <-- New bullet point */}
              </ul>
            </div>

            {/* For Finance Teams */}
            <div className="p-8 bg-white rounded-2xl hover:shadow-lg transition-all">
              <div className="p-3 mb-6 text-blue-600 bg-blue-50 rounded-xl w-14 h-14">
                <svg
                  className="w-8 h-8"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-900">
                For Finance Teams
              </h3>
              <p className="text-gray-600 mb-4">
                Streamline processing with automation and ensure compliance with
                ease.
              </p>
              <ul className="space-y-2 text-gray-600">
                <li>
                  ✅ Full compliance trail with automatic tax breakdowns (city,
                  state, federal)
                </li>
                <li>
                  ✅{" "}
                  <strong>
                    AI-powered policy compliance scanning & auto-approvals
                  </strong>
                </li>

                <li>✅ One-click bill creation in QuickBooks</li>
                <li>✅ Audit-ready documentation & tracking</li>
                <li>✅ AI-powered receipt data extraction</li>
                <li>✅ Direct ERP/accounting integration</li>
                <li>✅ Access, edit, and submit reports for your team</li>
                <li>✅ Detects duplicate receipts automatically</li>
                <li>
                  ✅ Ensure compliance with receipts linked to every transaction
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Integration Partners Banner */}
      <div className="bg-gray-50 py-3 border-y border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-8">
            <div className="text-gray-600 text-sm font-medium text-center">
              Integrates With
            </div>
            <div className="flex items-center space-x-6">
              <img
                src="/qblogo2.png"
                alt="QuickBooks"
                className="h-8 w-auto object-contain"
              />
              <img
                src="/wavelogo.png"
                alt="Wave"
                className="h-10 w-auto object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Pricing Section - Enhanced transparency messaging */}
      <section className="py-16 bg-gradient-to-r from-blue-50 to-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-6 text-3xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-yellow-400">
            Try Free For 30 Days
          </h2>

          <div className="flex justify-center gap-6 text-gray-600 mb-8">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Start in 30 seconds
            </div>
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              No credit card needed
            </div>
            <div className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-green-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              Cancel anytime
            </div>
          </div>
          <script
            async
            src="https://js.stripe.com/v3/pricing-table.js"
          ></script>
          <stripe-pricing-table
            pricing-table-id="prctbl_0QRcK8ABrmtPgfeVFLMyUxPP"
            publishable-key="pk_live_1ABrmtPgfeVnuSAIytHIFtemaBw23COIp8nCpzbQYSYr50tUlYKgL6BM51fn2wnyrGI9tX0PPyHzW7SA86QcQImem00zk4jRlbw"
          ></stripe-pricing-table>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Simplify Your Expenses?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Try your first receipt now - no credit card needed
          </p>
        </div>
      </section>

      {/* Login Button - Floating */}
      <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-sm px-4">
        <button
          onClick={handleGoogleLogin}
          className="flex items-center justify-center w-full px-6 py-3 text-lg font-medium text-gray-700 transition-all bg-white rounded-lg shadow-lg hover:shadow-xl hover:-translate-y-1"
        >
          <img src="/g-logo.png" alt="Google" className="w-6 h-6 mr-3" />
          Sign in with Google
        </button>
      </div>

      {/* Footer */}
      <footer className="py-8 text-center text-gray-600">
        <p className="text-sm">
          By signing up, you agree to the ExpenseBot{" "}
          <a href="/privacy" className="text-blue-600 hover:underline">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="/terms" className="text-blue-600 hover:underline">
            Terms of Service
          </a>
          . <br />
          Have questions? Check out our{" "}
          <a href="/faq" className="text-blue-600 hover:underline">
            FAQ
          </a>
          .
        </p>
      </footer>
    </div>
  );
};

export default Home;
