import React from "react";
import "../../styles/DeleteConfirmationModal.css";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  onConfirmWithRevert,
  reportId,
}) => {
  if (!isOpen) return null;

  return (
    <div className="eb-modal-overlay" onClick={onClose}>
      <div className="eb-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="eb-modal-header">
          <h3>Delete Report</h3>
          <button
            className="eb-modal-close"
            onClick={onClose}
            aria-label="Close"
          >
            ×
          </button>
        </div>
        <div className="eb-modal-body">
          <p className="eb-modal-question">
            Are you sure you want to delete this report?
          </p>

          <div className="eb-modal-info">
            <div className="eb-info-header">
              <i className="fas fa-info-circle"></i>
              <span>Choose what happens to the expenses in this report:</span>
            </div>

            <div className="eb-option-cards">
              <div
                className="eb-option-card"
                onClick={() => onConfirm(reportId)}
              >
                <h4>Keep Expenses Submitted</h4>
                <p>
                  Delete only the report. All expenses remain submitted in the
                  system.
                </p>
                <div className="eb-button-container">
                  <button
                    className="eb-modal-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      onConfirm(reportId);
                    }}
                  >
                    Delete Report Only
                  </button>
                </div>
              </div>

              <div
                className="eb-option-card"
                onClick={() => onConfirmWithRevert(reportId)}
              >
                <h4>Return Expenses to Unsubmitted</h4>
                <p>
                  Delete the report and return all its expenses to your
                  unsubmitted expenses.
                </p>
                <div className="eb-button-container">
                  <button
                    className="eb-modal-delete-revert"
                    onClick={(e) => {
                      e.stopPropagation();
                      onConfirmWithRevert(reportId);
                    }}
                  >
                    Delete & Return to Unsubmitted
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="eb-modal-footer">
          <button className="eb-modal-cancel" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
