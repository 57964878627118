import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import GoogleMapsDistance from "./GoogleMapsDistance";
import TagSelector from "./TagSelector";
import "../styles/MileageTracker.css";

const MileageTracker = ({ isOpen, onClose, processInfo, onSuccess }) => {
  // Add new state for settings
  const [settings, setSettings] = useState({
    unit: processInfo?.mileageUnit || "mi",
    allowancePerUnit: processInfo?.mileageRate || 0,
  });

  // Add state for settings modal
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [updatedSettings, setUpdatedSettings] = useState({
    unit: "",
    allowancePerUnit: 0,
  });
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);

  // Add state for categories dropdown
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  useEffect(() => {
    if (isOpen) {
      axios
        .get("/api/mileage-settings", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.data.success) {
            setSettings({
              unit: res.data.mileageUnit,
              allowancePerUnit: res.data.mileageRate,
            });
            // Also initialize the updated settings
            setUpdatedSettings({
              unit: res.data.mileageUnit,
              allowancePerUnit: res.data.mileageRate,
            });
          }
        })
        .catch((err) => {
          console.error("Error fetching mileage settings:", err);
        });

      // Fetch categories for the dropdown
      setIsLoadingCategories(true);
      axios
        .get("/api/categories", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.data.success && Array.isArray(res.data.categories)) {
            // Extract just the category names from the response
            const categoryNames = res.data.categories.map((c) => c.category);
            setCategories(categoryNames);

            // Find a default mileage category - look for something with "travel" or "mileage" in the name
            const defaultCategory = findDefaultCategory(categoryNames);
            setTrip((prev) => ({
              ...prev,
              category: defaultCategory,
            }));
          }
        })
        .catch((err) => {
          console.error("Error fetching expense categories:", err);
        })
        .finally(() => {
          setIsLoadingCategories(false);
        });
    }
  }, [isOpen]);

  // Helper function to find a default mileage category
  const findDefaultCategory = (categoryList) => {
    if (!categoryList || categoryList.length === 0) return "";

    // Look for categories containing these terms (in order of preference)
    const preferredTerms = [
      "mileage",
      "travel",
      "transportation",
      "auto",
      "car",
    ];

    for (const term of preferredTerms) {
      const match = categoryList.find((cat) =>
        cat.toLowerCase().includes(term.toLowerCase())
      );
      if (match) return match;
    }

    // If no match found, return the first category
    return categoryList[0];
  };

  useEffect(() => {
    if (processInfo?.mileageUnit || processInfo?.mileageRate) {
      setSettings({
        unit: processInfo.mileageUnit || "mi",
        allowancePerUnit: processInfo.mileageRate || 0,
      });
    }
  }, [processInfo]);

  useEffect(() => {
    if (isOpen) {
      // Reset trip state to initial values
      setTrip({
        date: new Date().toISOString().split("T")[0],
        name: "",
        miles: "",
        mapLink: "",
        notes: "",
        city: "",
        category: "", // Will be populated when categories are loaded
        billingAccount: "", // Will be populated by TagSelector
      });
      // Clear any existing errors
      setError("");
    }
  }, [isOpen]);

  const [trip, setTrip] = useState({
    date: new Date().toISOString().split("T")[0],
    name: "",
    miles: "",
    mapLink: "",
    notes: "",
    city: "",
    category: "",
    billingAccount: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);

  // Audio recording states and refs
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const showError = (errorMessage) => {
    setError(errorMessage);
    const modalContent = document.querySelector(".mileage-modal-content");
    if (modalContent) {
      modalContent.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Audio recording functions
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        await transcribeAudio(audioBlob);
      };

      mediaRecorder.start();
      setIsRecording(true);

      // Automatically stop recording after 15 seconds
      setTimeout(() => {
        if (mediaRecorder.state === "recording") {
          stopRecording();
        }
      }, 15000);
    } catch (error) {
      console.error("Error starting voice recording:", error);
      showError("Couldn't access microphone. Please check permissions.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      // Stop all tracks in the stream
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  const transcribeAudio = async (audioBlob) => {
    try {
      setIsTranscribing(true);

      // Create FormData to send the audio file
      const formData = new FormData();
      formData.append("audio", audioBlob, "voice-note.webm");

      // Send to the transcription endpoint
      const response = await fetch("/api/transcribe-audio", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        // For 503 Service Unavailable, show a specific message
        if (response.status === 503) {
          showError(
            "Voice service temporarily unavailable. Please try again later."
          );
          return;
        }

        throw new Error(`Failed to transcribe audio: ${response.status}`);
      }

      // Check if the response indicates a failure
      if (!data.success) {
        showError(
          data.message || "Failed to process voice note. Please try again."
        );
        return;
      }

      // Add the transcribed text to the notes if we got a transcription
      if (data.transcription && data.transcription.trim() !== "") {
        // If there's already some text in the notes, add a line break
        setTrip((prev) => {
          const updatedNotes = prev.notes
            ? `${prev.notes}\n${data.transcription}`
            : data.transcription;
          return { ...prev, notes: updatedNotes };
        });
      }
    } catch (error) {
      console.error("Error transcribing audio:", error);
      showError("Failed to process voice note. Please try again.");
    } finally {
      setIsTranscribing(false);
    }
  };

  const openSettingsModal = () => {
    setUpdatedSettings({
      unit: settings.unit,
      allowancePerUnit: settings.allowancePerUnit,
    });
    setIsSettingsModalOpen(true);
  };

  const handleSaveSettings = async () => {
    setIsUpdatingSettings(true);
    try {
      const response = await axios.post(
        "/api/mileage-settings",
        {
          mileageUnit: updatedSettings.unit,
          mileageRate: updatedSettings.allowancePerUnit,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setSettings({
          unit: response.data.mileageUnit,
          allowancePerUnit: response.data.mileageRate,
        });
        setIsSettingsModalOpen(false);
      } else {
        showError(response.data.message || "Failed to update settings.");
      }
    } catch (error) {
      console.error("Error updating mileage settings:", error);
      showError("Failed to update mileage settings. Please try again.");
    } finally {
      setIsUpdatingSettings(false);
    }
  };

  // Handle tag selection
  const handleTagSelect = (tag) => {
    setTrip((prev) => ({
      ...prev,
      billingAccount: tag,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    showError("");
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "/api/processMileage",
        {
          date: trip.date,
          description: trip.name,
          distance: parseFloat(trip.miles),
          city: trip.city,
          notes: trip.notes,
          mapLink: trip.mapLink,
          category: trip.category,
          billingAccount: trip.billingAccount,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        if (response.data.settings) {
          // Update local settings state
          setSettings(response.data.settings);
        }
        onClose();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (response.data.subscriptionStatus?.isExpiredTrial) {
          // Handle expired trial with simple message, no upgrade button
          showError(
            "Your trial has expired. Please subscribe to continue using this feature."
          );
        } else if (response.data.settingsUrl) {
          showError(
            <div className="mileage-alert-content">
              <p>{response.data.error}</p>
              <button
                onClick={() => window.open(response.data.settingsUrl, "_blank")}
                className="mileage-alert-link"
              >
                Configure Mileage Settings
              </button>
            </div>
          );
        } else {
          showError(response.data.error);
        }
      }
    } catch (error) {
      // Check for 403 status code which often indicates subscription/permission issues
      if (error.response?.status === 403) {
        if (error.response.data?.subscriptionStatus?.isExpiredTrial) {
          // Simple error message without upgrade button
          showError(
            "Your trial has expired. Please upgrade to continue using this feature."
          );
        } else {
          showError(
            error.response.data?.message ||
              "Access denied. Please check your subscription."
          );
        }
      } else if (error.response?.data?.settingsUrl) {
        showError(
          <div className="mileage-alert-content">
            <p>{error.response.data.error}</p>
            <button
              onClick={() =>
                window.open(error.response.data.settingsUrl, "_blank")
              }
              className="mileage-alert-link"
            >
              Configure Mileage Settings
            </button>
          </div>
        );
      } else {
        showError("Failed to save mileage expense. Please try again.");
      }
      console.error("Error saving mileage:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCalculateClick = () => {
    setTrip((prev) => ({
      ...prev,
      miles: "",
      mapLink: "",
      name: "",
      city: "",
    }));
    setIsMapModalOpen(false);
    // Force a complete reset before reopening
    requestAnimationFrame(() => {
      setIsMapModalOpen(true);
    });
  };

  const handleDistanceCalculated = (result) => {
    setTrip((prev) => ({
      ...prev,
      miles: result.distance,
      mapLink: result.mapUrl,
      name: prev.name || result.destinationName || prev.name,
      city: prev.city || result.city || prev.city,
    }));
    setIsMapModalOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="mileage-modal-backdrop">
      <div className="mileage-modal-wrapper">
        <div className="mileage-modal-header">
          <h2 className="mileage-modal-title">
            <i className="fas fa-car"></i>
            Add Mileage
          </h2>
          <button className="mileage-modal-close" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="mileage-modal-content">
          {/* Settings Info */}
          <div className="mileage-settings-card">
            <div className="mileage-settings-header">
              <span className="mileage-settings-title">Current Settings</span>
              <button
                onClick={openSettingsModal}
                className="mileage-settings-edit"
              >
                <i className="fas fa-edit"></i>
                Edit Settings
              </button>
            </div>
            <div className="mileage-settings-item">
              <div className="mileage-settings-label">Current Rate</div>
              <div className="mileage-settings-value">
                ${settings.allowancePerUnit.toFixed(2)}/{settings.unit}
              </div>
            </div>
          </div>

          {error && (
            <div className="mileage-alert">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}
          {/* --- STEP 2: Big CTA block at the top --- */}
          <div className="calculate-cta">
            <button
              type="button"
              onClick={handleCalculateClick}
              className="mileage-button mileage-button-primary calculate-cta-button"
            >
              <i className="fas fa-map-marker-alt"></i>
              Calculate Distance
            </button>
          </div>

          <form onSubmit={handleSubmit} className="mileage-form">
            <div className="mileage-form-grid">
              <div className="mileage-form-group">
                <label htmlFor="date" className="mileage-label">
                  Trip Date *
                </label>
                <input
                  id="date"
                  type="date"
                  required
                  className="mileage-input"
                  value={trip.date}
                  onChange={(e) => setTrip({ ...trip, date: e.target.value })}
                />
              </div>
              <div className="mileage-form-group">
                <label htmlFor="name" className="mileage-label">
                  Trip Description *
                </label>
                <input
                  id="name"
                  type="text"
                  required
                  className="mileage-input"
                  placeholder="e.g., Client Meeting NYC"
                  value={trip.name}
                  onChange={(e) => setTrip({ ...trip, name: e.target.value })}
                />
              </div>
            </div>

            <div className="mileage-form-grid">
              <div className="mileage-form-group">
                <label htmlFor="miles" className="mileage-label">
                  Distance ({settings.unit}) *
                </label>
                <div className="mileage-distance-input">
                  <input
                    id="miles"
                    type="number"
                    required
                    step="0.01"
                    min="0"
                    className="mileage-input"
                    placeholder={`Enter distance in ${settings.unit}`}
                    value={trip.miles}
                    onChange={(e) =>
                      setTrip({ ...trip, miles: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mileage-form-group">
                <label htmlFor="city" className="mileage-label">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  className="mileage-input"
                  placeholder="Optional"
                  value={trip.city}
                  onChange={(e) => setTrip({ ...trip, city: e.target.value })}
                />
              </div>
            </div>

            {/* Add Category Dropdown */}
            <div className="mileage-form-group">
              <label htmlFor="category" className="mileage-label">
                Expense Category *
              </label>
              <select
                id="category"
                required
                className="mileage-input"
                value={trip.category}
                onChange={(e) => setTrip({ ...trip, category: e.target.value })}
                disabled={isLoadingCategories || categories.length === 0}
              >
                <option value="" disabled>
                  {isLoadingCategories
                    ? "Loading categories..."
                    : categories.length === 0
                    ? "No categories available"
                    : "Select a category"}
                </option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            {/* Add Tag Selector for Billing Account */}
            <div className="mileage-form-group">
              <label htmlFor="billingAccount" className="mileage-label">
                Tag for Grouping
              </label>
              <div className="mileage-tag-selector">
                <TagSelector
                  selectedTag={trip.billingAccount}
                  onTagSelect={handleTagSelect}
                  buttonLabel={trip.billingAccount || "Select Tag"}
                  className="mileage-tag-selector-component"
                />
              </div>
            </div>

            <div className="mileage-form-group mileage-notes">
              <label htmlFor="notes" className="mileage-label">
                Notes
              </label>
              <div className="mileage-notes-container">
                <textarea
                  id="notes"
                  className="mileage-textarea"
                  placeholder="Add any additional details about this trip"
                  value={trip.notes}
                  onChange={(e) => setTrip({ ...trip, notes: e.target.value })}
                  rows={2}
                />
                <button
                  type="button"
                  className={`voice-note-button ${
                    isRecording ? "recording" : ""
                  } ${isTranscribing ? "transcribing" : ""}`}
                  onClick={isRecording ? stopRecording : startRecording}
                  disabled={isTranscribing}
                >
                  {isTranscribing ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : isRecording ? (
                    <i className="fas fa-stop"></i>
                  ) : (
                    <i className="fas fa-microphone"></i>
                  )}
                </button>
              </div>
            </div>

            <div className="mileage-actions">
              <button
                type="button"
                onClick={onClose}
                className="mileage-button mileage-button-secondary"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="mileage-button mileage-button-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="mileage-button-loading">
                    <i className="fas fa-spinner fa-spin"></i>
                    Saving...
                  </span>
                ) : (
                  "Save Mileage Expense"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <GoogleMapsDistance
        isOpen={isMapModalOpen}
        onClose={() => setIsMapModalOpen(false)}
        onDistanceCalculated={handleDistanceCalculated}
        unit={settings.unit}
      />

      {/* Settings Mini Modal */}
      {isSettingsModalOpen && (
        <div className="mileage-modal-backdrop">
          <div className="mileage-modal-wrapper" style={{ maxWidth: "400px" }}>
            <div className="mileage-modal-header">
              <h2 className="mileage-modal-title">
                <i className="fas fa-cog"></i>
                Mileage Settings
              </h2>
              <button
                className="mileage-modal-close"
                onClick={() => setIsSettingsModalOpen(false)}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="mileage-modal-content">
              <div className="mileage-form-group">
                <label htmlFor="mileageUnit" className="mileage-label">
                  Distance Unit
                </label>
                <select
                  id="mileageUnit"
                  className="mileage-input"
                  value={updatedSettings.unit}
                  onChange={(e) =>
                    setUpdatedSettings({
                      ...updatedSettings,
                      unit: e.target.value,
                    })
                  }
                >
                  <option value="mi">Miles (mi)</option>
                  <option value="km">Kilometers (km)</option>
                </select>
              </div>

              <div className="mileage-form-group">
                <label htmlFor="mileageRate" className="mileage-label">
                  Rate per {updatedSettings.unit}
                </label>
                <div className="mileage-rate-input">
                  <span className="mileage-currency-symbol">$</span>
                  <input
                    id="mileageRate"
                    type="number"
                    step="0.01"
                    min="0"
                    className="mileage-input"
                    value={updatedSettings.allowancePerUnit}
                    onChange={(e) =>
                      setUpdatedSettings({
                        ...updatedSettings,
                        allowancePerUnit: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </div>
              </div>

              <div className="mileage-actions">
                <button
                  type="button"
                  onClick={() => setIsSettingsModalOpen(false)}
                  className="mileage-button mileage-button-secondary"
                  disabled={isUpdatingSettings}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSaveSettings}
                  className="mileage-button mileage-button-primary"
                  disabled={isUpdatingSettings}
                >
                  {isUpdatingSettings ? (
                    <span className="mileage-button-loading">
                      <i className="fas fa-spinner fa-spin"></i>
                      Saving...
                    </span>
                  ) : (
                    "Save Settings"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MileageTracker;
