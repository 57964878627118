import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Tag, RefreshCw } from "lucide-react";
import { toast } from "react-toastify";
import "../styles/TagSelector.css";

/**
 * TagSelector - A reusable component for selecting and creating tags
 * Now with DOM portals for bulletproof rendering
 */
const TagSelector = ({
  selectedTag = "",
  onTagSelect,
  isCompact = false,
  buttonLabel = "Add Tag",
  className = "",
  isDropdownOpen = null,
  onDropdownToggle = null,
}) => {
  // State
  const [availableTags, setAvailableTags] = useState([]);
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [isCreatingNewTag, setIsCreatingNewTag] = useState(false);
  const [newTagInput, setNewTagInput] = useState("");
  const [internalError, setInternalError] = useState("");

  // Portal container ref
  const [portalContainer, setPortalContainer] = useState(null);

  // Use external dropdown state if provided, otherwise use internal state
  const dropdownIsOpen =
    isDropdownOpen !== null ? isDropdownOpen : internalIsOpen;

  // Setup portal container on mount
  useEffect(() => {
    // Always create a fresh container when the component mounts to avoid conflicts
    let container = document.getElementById("tag-selector-portal");

    // If it already exists, don't remove it as other instances might be using it
    if (!container) {
      container = document.createElement("div");
      container.id = "tag-selector-portal";
      // Ensure it's the last element in the body for highest z-index precedence
      document.body.appendChild(container);
    }

    setPortalContainer(container);

    // Clean up only if no other TagSelector instances exist
    return () => {
      // Delay the check to ensure React has finished processing all unmounts
      setTimeout(() => {
        if (
          container &&
          document.querySelectorAll(".tag-selector").length === 0 &&
          document.getElementById("tag-selector-portal")
        ) {
          try {
            document.body.removeChild(container);
          } catch (e) {
            // It's possible another component removed it already
            console.log("Portal container already removed");
          }
        }
      }, 0);
    };
  }, []);

  // Fetch available tags
  const fetchAvailableTags = async () => {
    try {
      setIsLoadingTags(true);
      const response = await fetch("/api/tags", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch tags");
      }

      const data = await response.json();

      if (data.success && data.tags) {
        // Filter out any empty tags at the data level
        const validTags = data.tags.filter(
          (tagObj) => tagObj.tag && tagObj.tag.trim() !== ""
        );
        setAvailableTags(validTags);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
      setInternalError("Failed to load tags");
    } finally {
      setIsLoadingTags(false);
    }
  };

  // Create a new tag
  const createNewTag = async (tagName) => {
    if (!tagName.trim()) return false;

    try {
      const response = await fetch("/api/tags", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tags: [{ tag: tagName, isActive: true }],
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create tag");
      }

      // Refresh the tag list
      await fetchAvailableTags();

      return true;
    } catch (error) {
      console.error("Error creating tag:", error);
      setInternalError("Failed to create tag. Please try again.");
      return false;
    }
  };

  // Handle tag selection
  const handleTagSelect = (tag) => {
    onTagSelect(tag);

    // Close the dropdown
    if (onDropdownToggle) {
      onDropdownToggle(false);
    } else {
      setInternalIsOpen(false);
    }
  };

  // Handle new tag input changes
  const handleNewTagChange = (e) => {
    setNewTagInput(e.target.value);
  };

  // Toggle tag creation mode
  const toggleNewTagCreation = (e) => {
    if (e) e.stopPropagation();

    // Check if we've reached the tag limit
    if (
      !isCreatingTag &&
      availableTags.filter((t) => t.tag && t.tag.trim() !== "").length >= 20
    ) {
      toast.warning(
        "Maximum of 20 tags allowed. Delete some tags in your spreadsheet before creating a new one."
      );
      return;
    }

    setIsCreatingTag(!isCreatingTag);
    setNewTagInput("");
    setInternalError("");
  };

  // Save new tag
  const saveNewTag = async (e) => {
    if (e) e.stopPropagation();

    if (!newTagInput.trim()) {
      setInternalError("Tag name cannot be empty");
      return;
    }

    setIsCreatingNewTag(true);

    try {
      const success = await createNewTag(newTagInput.trim());
      if (success) {
        // Select the new tag
        onTagSelect(newTagInput.trim());

        // Reset creation mode
        setIsCreatingTag(false);
        setNewTagInput("");

        // Close dropdown
        if (onDropdownToggle) {
          onDropdownToggle(false);
        } else {
          setInternalIsOpen(false);
        }
      }
    } catch (error) {
      console.error("Error saving new tag:", error);
      setInternalError("Failed to save tag");
    } finally {
      setIsCreatingNewTag(false);
    }
  };

  // Toggle tag dropdown
  const toggleTagDropdown = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    // If using external toggle
    if (onDropdownToggle) {
      const newState = !dropdownIsOpen;
      onDropdownToggle(newState);
    } else {
      setInternalIsOpen(!internalIsOpen);
    }

    // Reset state
    setIsCreatingTag(false);
    setNewTagInput("");
    setInternalError("");
  };

  // Fetch tags when dropdown opens
  useEffect(() => {
    if (dropdownIsOpen) {
      fetchAvailableTags();
    }
  }, [dropdownIsOpen]);

  // Clear error message after a delay
  useEffect(() => {
    if (internalError) {
      const timer = setTimeout(() => {
        setInternalError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [internalError]);

  // Detect clicks outside to close dropdown
  useEffect(() => {
    if (!dropdownIsOpen) return;

    const handleClickOutside = (e) => {
      // Close if clicking outside of any tag-selector-dropdown or its children
      const clickedOnDropdown = e.target.closest(".tag-selector-dropdown");
      const clickedOnButton = e.target.closest(".tag-selector-button");

      if (!clickedOnDropdown && !clickedOnButton) {
        if (onDropdownToggle) {
          onDropdownToggle(false);
        } else {
          setInternalIsOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownIsOpen, onDropdownToggle]);

  // Render dropdown content
  const renderDropdownContent = () => {
    if (isLoadingTags) {
      return (
        <div className="tag-loading">
          <RefreshCw size={16} className="spin-icon" />
          <span>Loading tags...</span>
        </div>
      );
    }

    if (isCreatingTag) {
      return (
        <div className="create-tag-container">
          <input
            type="text"
            value={newTagInput}
            onChange={handleNewTagChange}
            placeholder="Enter new tag name"
            className="new-tag-input"
            maxLength={30}
            onClick={(e) => e.stopPropagation()}
            autoFocus
          />
          {internalError && <div className="tag-error">{internalError}</div>}
          <div className="tag-actions">
            <button
              className="tag-cancel-btn"
              onClick={(e) => {
                e.stopPropagation();
                setIsCreatingTag(false);
                setNewTagInput("");
              }}
              disabled={isCreatingNewTag}
            >
              Cancel
            </button>
            <button
              className="tag-save-btn"
              onClick={saveNewTag}
              disabled={isCreatingNewTag}
            >
              {isCreatingNewTag ? (
                <span className="tag-loading-spinner">
                  <RefreshCw size={12} className="spin-icon" />
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      );
    }

    if (availableTags.length > 0) {
      return (
        <>
          <div className="tag-header">
            <span>Select a tag</span>
            <div className="tag-header-buttons">
              <button
                className="create-new-tag-btn"
                onClick={toggleNewTagCreation}
              >
                + New
              </button>
              <button
                className="tag-close-btn"
                onClick={toggleTagDropdown}
                title="Close"
              >
                ×
              </button>
            </div>
          </div>
          <div
            className="tag-option no-tag"
            onClick={() => handleTagSelect("")}
          >
            No Tag
          </div>
          {availableTags.map((tagObj) => (
            <div
              key={tagObj.tag}
              className={`tag-option ${
                selectedTag === tagObj.tag ? "selected" : ""
              }`}
              onClick={() => handleTagSelect(tagObj.tag)}
            >
              {tagObj.tag}
            </div>
          ))}
        </>
      );
    }

    return (
      <div className="no-tags-available">
        <p>No tags available</p>
        <button className="create-first-tag-btn" onClick={toggleNewTagCreation}>
          Create your first tag
        </button>
      </div>
    );
  };

  // Create the dropdown in a portal if available
  const createDropdownPortal = () => {
    if (!portalContainer) return null;

    return createPortal(
      <>
        <div
          className="tag-dropdown-overlay"
          onClick={toggleTagDropdown}
          style={{ zIndex: 2147483646 }}
        />
        <div
          className="tag-selector-dropdown"
          onClick={(e) => e.stopPropagation()}
          style={{
            zIndex: 2147483647,
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {renderDropdownContent()}
        </div>
      </>,
      portalContainer
    );
  };

  return (
    <div
      className={`tag-selector ${isCompact ? "compact" : ""} ${className} ${
        dropdownIsOpen ? "dropdown-open" : ""
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className={`tag-selector-button ${selectedTag ? "has-tag" : ""}`}
        onClick={toggleTagDropdown}
      >
        <Tag size={isCompact ? 12 : 16} />
        <span>{selectedTag || buttonLabel}</span>
      </button>

      {dropdownIsOpen && createDropdownPortal()}
    </div>
  );
};

export default TagSelector;
