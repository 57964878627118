// src/components/FileUploader.jsx
import React from "react";
import BaseUploader from "./BaseUploader";
import { offlineStorage } from "../services/offlineStorage";
import axios from "axios";
import heic2any from "heic2any"; // Import for HEIC support

const FileUploader = ({
  onClose,
  onPhotosSelected,
  onError,
  onUploadProgress,
}) => {
  // Image-specific configurations
  const config = {
    acceptTypes: "image/jpeg,image/png,image/heic",
    maxFileSize: 20 * 1024 * 1024, // 20MB
    maxFiles: 50,
    fileTypeName: "image",
    buttonText: {
      selectInitial: "Select Receipt Images",
      selectMore: "Add More Images",
    },
    // File type validation function that handles HEIC files properly
    fileTypeValidationFn: (file) => {
      return (
        file.type.match(/^image\/(jpeg|png|heic)$/i) ||
        file.name.toLowerCase().endsWith(".heic")
      );
    },
    processingText: "Processing",
  };

  // Helper function to optimize images with HEIC support
  const optimizeImage = async (file, quality = 0.7) => {
    try {
      // Convert HEIC to JPEG first if needed
      let processedFile = file;
      if (
        file.type === "image/heic" ||
        file.name.toLowerCase().endsWith(".heic")
      ) {
        console.log("Converting HEIC to JPEG...");
        processedFile = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.85, // Slightly higher quality for the conversion step
        });
      }

      const img = new Image();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      return new Promise((resolve, reject) => {
        img.onload = () => {
          try {
            // OCR-Optimized Dimensions - preserve detail for text recognition
            const MAX_LONG_SIDE = 2000;
            const ratio = Math.min(
              MAX_LONG_SIDE / Math.max(img.width, img.height),
              1
            );

            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;

            // For receipts, we want higher quality to preserve text detail
            // Since this component is specifically for receipts, we set a minimum
            const finalQuality = Math.max(quality, 0.6);

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  console.log(
                    `Original size: ${(file.size / (1024 * 1024)).toFixed(
                      2
                    )}MB, ` +
                      `Compressed size: ${(blob.size / (1024 * 1024)).toFixed(
                        2
                      )}MB, ` +
                      `Compression ratio: ${(
                        (blob.size / file.size) *
                        100
                      ).toFixed(1)}%`
                  );
                  resolve(blob);
                } else {
                  reject(new Error("Failed to compress image"));
                }
              },
              "image/jpeg",
              finalQuality
            );
          } catch (error) {
            reject(error);
          }
        };

        img.onerror = () => {
          reject(new Error("Failed to load image"));
        };

        img.src = URL.createObjectURL(processedFile);
      });
    } catch (error) {
      console.error("Error in optimizeImage:", error);
      throw error;
    }
  };

  // Image processing function - updated to handle tags
  const processImageFiles = async (
    filesWithTags,
    progressCallback,
    errorCallback
  ) => {
    const mediaItems = [];

    // Calculate progress increment per file
    const validFiles = filesWithTags.map((item) => item.file);
    const progressIncrement = 100 / validFiles.length;
    let currentProgress = 0;

    // Log the files to process with their tags
    console.log(
      "Processing images with tags:",
      filesWithTags.map((item) => ({
        filename: item.file.name,
        tag: item.tag,
      }))
    );

    // Process files sequentially to avoid memory issues on mobile
    for (const fileItem of filesWithTags) {
      const file = fileItem.file;
      const tag = fileItem.tag;

      try {
        // Determine compression quality based on file size
        let compressionQuality;
        if (file.size > 15 * 1024 * 1024) {
          compressionQuality = 0.2;
        } else if (file.size > 10 * 1024 * 1024) {
          compressionQuality = 0.3;
        } else if (file.size > 5 * 1024 * 1024) {
          compressionQuality = 0.4;
        } else if (file.size > 2 * 1024 * 1024) {
          compressionQuality = 0.5;
        } else {
          compressionQuality = 0.7;
        }

        // Update progress for compression start
        currentProgress += progressIncrement * 0.3;
        progressCallback(Math.round(currentProgress));

        // Compress the image with our optimization function
        const blob = await optimizeImage(file, compressionQuality);

        // Update progress after compression
        currentProgress += progressIncrement * 0.3;
        progressCallback(Math.round(currentProgress));

        // Generate a unique file ID
        const fileId = `receipt_${Date.now()}_${Math.random()
          .toString(36)
          .substring(2, 10)}`;

        if (!navigator.onLine) {
          // Handle offline case - now including tag
          await offlineStorage.storePhoto(blob, {
            filename: file.name,
            timestamp: Date.now(),
            isOfflinePending: true,
            tag: tag, // Include the tag in offline storage
          });
          errorCallback?.(
            `File ${file.name} saved for upload when back online.`
          );
          currentProgress += progressIncrement * 0.4;
          progressCallback(Math.round(currentProgress));
        } else {
          // ONLINE FLOW: Use GCS upload
          try {
            // Step 1: Get a signed URL from your server
            const getUrlResponse = await axios.post(
              "/api/get-upload-url",
              {
                filename: file.name,
                contentType: blob.type || "image/jpeg",
                fileType: "receipt", // Specify receipt type
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                },
              }
            );

            const { signedUrl, gcsFileName } = getUrlResponse.data;

            // Capture the current progress value for use in the closure
            const baseProgress = currentProgress;

            // Step 2: Upload directly to GCS
            await axios.put(signedUrl, blob, {
              headers: {
                "Content-Type": blob.type || "image/jpeg",
              },
              onUploadProgress: (progressEvent) => {
                // Use baseProgress instead of currentProgress
                const fileProgress = progressEvent.loaded / progressEvent.total;
                const additionalProgress =
                  fileProgress * progressIncrement * 0.4;
                progressCallback(Math.round(baseProgress + additionalProgress));
              },
            });

            // Add the uploaded media item to the list with the tag
            mediaItems.push({
              id: fileId,
              gcsPath: gcsFileName,
              baseUrl: signedUrl.split("?")[0], // Store the base URL without query params
              mimeType: blob.type || "image/jpeg",
              filename: file.name,
              photoName: file.name, // Add photoName property
              mediaMetadata: {
                creationTime: new Date().toISOString(),
              },
              type: "receipt", // Important: Mark as receipt type
              tag: tag, // Include the selected tag
            });

            // Update progress after successful GCS upload
            currentProgress += progressIncrement * 0.4;
            progressCallback(Math.round(currentProgress));
          } catch (error) {
            console.error(`Error uploading ${file.name} to GCS:`, error);
            errorCallback?.(`Failed to upload ${file.name}: ${error.message}`);
            // Continue with next file instead of failing completely
            currentProgress += progressIncrement * 0.4;
            progressCallback(Math.round(currentProgress));
          }
        }
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        errorCallback?.(`Failed to process ${file.name}: ${error.message}`);
        // Continue with next file
        currentProgress += progressIncrement;
        progressCallback(Math.round(currentProgress));
      }
    }

    // Ensure progress is at 100% when done
    progressCallback(100);

    return mediaItems;
  };

  return (
    <BaseUploader
      onClose={onClose}
      onFilesSelected={onPhotosSelected}
      onError={onError}
      onUploadProgress={onUploadProgress}
      processFiles={processImageFiles}
      autoOpenFileDialog={false} // Add true  to skip first screen
      {...config}
    />
  );
};

export default FileUploader;
