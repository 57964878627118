import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/faq.css";

const FAQPage = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("quickstart");

  const handleBackClick = () => {
    navigate("/");
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setActiveSection(sectionId);
  };

  return (
    <div className="faq-container">
      <header className="faq-header">
        <button className="back-button" onClick={handleBackClick}>
          ← Back
        </button>
        <div className="logo-container">
          <a href="/">
            <img
              src="./eblogo_2.png"
              alt="ExpenseBot Logo"
              className="faq-logo"
            />
          </a>
        </div>
        <div className="faq-title-container">
          <h1 className="faq-title">ExpenseBot Guide & FAQ</h1>
        </div>
      </header>

      {/* YouTube Links */}
      {/* YouTube Links */}
      <div className="faq-nav">
        <div className="flex flex-col items-center gap-6 w-full max-w-2xl mx-auto p-4">
          {/* Subscribe Button */}
          <a
            href="https://www.youtube.com/@expensebot"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-item flex items-center gap-2 w-full max-w-md"
          >
            <i
              className="fab fa-youtube"
              style={{ fontSize: "24px", color: "#ff0000" }}
            ></i>
            Subscribe to ExpenseBot Channel
          </a>

          {/* Enhanced Playlist Button */}
          <div className="w-full max-w-md bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
            <a
              href="https://www.youtube.com/playlist?list=PLjwcWbInP0mIQv7xhos1-74t7pDz19lD7"
              target="_blank"
              rel="noopener noreferrer"
              className="block p-4"
            >
              <div className="flex items-center gap-3 mb-3">
                <i
                  className="fab fa-youtube"
                  style={{ fontSize: "24px", color: "#ff0000" }}
                ></i>
                <span className="font-medium text-lg">
                  Watch Getting Started Playlist
                </span>
              </div>

              <div className="text-sm text-gray-600 pl-9">
                <p className="mb-2">✓ Adding Receipts</p>
                <p className="mb-2">✓ Spreadsheet Overview</p>
                <p className="mb-2">✓ Creating reports</p>
                <p className="mb-2">
                  ✓ Group reports by client, trip or project
                </p>
              </div>

              <div className="mt-3 text-sm text-blue-600 pl-9">Watch Now →</div>
            </a>
          </div>
        </div>
      </div>

      {/* Navigation Menu Yes*/}
      <nav className="faq-nav">
        <button
          className={`nav-item ${
            activeSection === "quickstart" ? "active" : ""
          }`}
          onClick={() => scrollToSection("quickstart")}
        >
          Quick Start
        </button>

        <button
          className={`nav-item ${activeSection === "faq" ? "active" : ""}`}
          onClick={() => scrollToSection("faq")}
        >
          FAQ
        </button>
        <button
          className={`nav-item ${activeSection === "tips" ? "active" : ""}`}
          onClick={() => scrollToSection("tips")}
        >
          Tips & Best Practices
        </button>
      </nav>

      {/* Quick Start Guide Section */}
      <section id="quickstart" className="section">
        <h2 className="section-header">Quick Start Guide</h2>

        {/* Required App Setup - NEW LOCATION */}
        <div className="subsection">
          <h3 className="subsection-title">Required App Setup</h3>
          <p className="faq-answer" style={{ marginBottom: "20px" }}>
            ExpenseBot works exclusively with Google Photos. You must have the
            Google Photos app installed and automatic photo syncing enabled on
            your device.
          </p>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Download and set up Google Photos
                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://apps.apple.com/app/google-photos/id962194608"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{
                      display: "inline-block",
                      padding: "5px 15px",
                      marginRight: "10px",
                    }}
                  >
                    Download Google Photos for iPhone
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.photos"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{ display: "inline-block", padding: "5px 15px" }}
                  >
                    Download Google Photos for Android
                  </a>
                </div>
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Enable automatic backup of Photos in the Google Photos app
                settings
                <div style={{ marginTop: "10px" }}>
                  <a
                    href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DiOS&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{
                      display: "inline-block",
                      padding: "5px 15px",
                      marginRight: "10px",
                    }}
                  >
                    iPhone Setup Guide
                  </a>
                  <a
                    href="https://support.google.com/photos/answer/6193313?co=GENIE.Platform%3DAndroid&hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-item"
                    style={{ display: "inline-block", padding: "5px 15px" }}
                  >
                    Android Setup Guide
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="subsection">
          <h3 className="subsection-title">Getting Started</h3>
          <div className="step-list">
            <div className="step-item">
              <span className="step-number">1</span>
              <div className="step-content">
                Click "Get Photos from Google Photos" and select a few images of
                receipts. TIP : When selecting photos choose the search icon and
                type in "Receipts" and it will show all your photos of receipts.
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">2</span>
              <div className="step-content">
                Wait for processing completion (you'll receive an email)
              </div>
            </div>
            <div className="step-item">
              <span className="step-number">3</span>
              <div className="step-content">
                Click the "View Expense Spreadsheet" button to see them or click
                on the link in the email.
              </div>
            </div>
          </div>

          <div className="mt-6 mb-6">
            <p className="text-gray-700 mb-3">
              Watch this quick video tutorial to see how it works:
            </p>
            <div className="relative w-full overflow-hidden pt-[56.25%] rounded-lg shadow-md">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/Gpx74dqoYXQ?si=c0Rz8YDG2ogNAZI9&rel=0"
                title="How to use ExpenseBot with Google Photos"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                referrerPolicy="strict-origin-when-cross-origin"
              />
            </div>
          </div>

          <div className="important-note">
            Important: Add info@expensebot.ai to your safe sender list to
            receive processing notifications
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="section">
        <h2 className="section-header">Frequently Asked Questions</h2>

        {/* General Questions */}
        <div className="subsection">
          <h3 className="subsection-title">General Questions</h3>
          <div className="faq-item space-y-4">
            <div className="space-y-2">
              <p className="faq-question font-medium text-lg">
                How do I handle emailed receipts?
              </p>
              <p className="faq-answer text-gray-600">
                Simply take a screen capture of the receipt. You can then submit
                it using the "Find Receipts in Google Photos" button or by
                choosing the "Upload Screenshot" option—and that's it!
              </p>
            </div>

            <div className="relative w-full overflow-hidden pt-[56.25%]">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/KGzwxlrO3VE?si=RCmzSq5htIXfhfh7?rel=0"
                title="How to handle emailed receipts"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                referrerPolicy="strict-origin-when-cross-origin"
              />
            </div>
          </div>
          <div className="faq-item">
            <p className="faq-question">
              Can I forward emails directly to ExpenseBot?
            </p>
            <div className="faq-answer">
              <p className="mb-4">
                Yes! Simply forward or send your emailed receipts to
                receipts@expensebot.ai.
              </p>
              <div
                style={{
                  position: "relative",
                  boxSizing: "content-box",
                  maxHeight: "80vh",
                  width: "100%",
                  aspectRatio: "1.7532656023222062",
                  padding: "40px 0",
                }}
              >
                <iframe
                  src="https://app.supademo.com/embed/cm90an0fo178zeyp0dlr2zpjh?embed_v=2"
                  loading="lazy"
                  title="Email: Forward a Receipt"
                  allow="clipboard-write"
                  frameBorder="0"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="faq-item">
            <p className="faq-question">
              Does ExpenseBot handle duplicate receipts?
            </p>
            <p className="faq-answer">
              Yes! ExpenseBot automatically detects and eliminates duplicate
              receipts to keep your reports accurate.
            </p>
          </div>
          <div className="faq-item">
            <p className="faq-question">
              How does the ExpenseBot AI determine compliance with Expense
              Policies?
            </p>
            <p className="faq-answer">
              Upload your policy pdf, and ExpenseBot can evaluate any report
              instantly against it.
            </p>
            <div
              style={{
                position: "relative",
                boxSizing: "content-box",
                maxHeight: "80vh",
                width: "100%",
                aspectRatio: "1.7563",
                padding: "40px 0",
              }}
            >
              <iframe
                src="https://app.supademo.com/embed/cm79jgz4l0kjs11ono5aqsqj6?embed_v=2"
                loading="lazy"
                title="Automated Expense Policy Compliance"
                allow="clipboard-write"
                frameBorder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          </div>

          <div className="faq-item">
            <p className="faq-question">Can I connect QuickBooks?</p>
            <div className="faq-answer">
              <p className="mb-4">
                Yes, you or your accounting professional can submit your reports
                to QuickBooks.
              </p>
              <a
                href="https://www.expensebot.ai/quickbooksintegration"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
                View QuickBooks Integration Guide
              </a>
            </div>
          </div>

          <div className="faq-item">
            <p className="faq-question">
              Can I group expenses by client to bill-back?
            </p>
            <div className="faq-answer">
              <p className="mb-4">
                Yes, you can. Here's an end-to-end video walkthrough:
              </p>
              <div className="relative w-full overflow-hidden bg-gray-100 rounded-xl aspect-video">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/WrlkZv7LOFA?si=GD7pNMVF34oKrHby?rel=0"
                  title="How to handle emailed receipts"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  referrerPolicy="strict-origin-when-cross-origin"
                />
              </div>
            </div>
          </div>
          <div className="faq-item">
            <p className="faq-question">Do I need an app?</p>
            <p className="faq-answer">
              No, just sign in at https://www.expensebot.ai
            </p>
          </div>
        </div>

        {/* Privacy & Security */}
        <div className="subsection">
          <h3 className="subsection-title">Privacy & Security</h3>

          <div className="faq-item">
            <p className="faq-question">How does ExpenseBot handle my data?</p>
            <p className="faq-answer">
              ExpenseBot uses a <strong>privacy-first</strong> approach. Unlike
              most apps, ExpenseBot does not store your data. All data (photos
              and spreadsheets) is stored on your own Google Drive, accessible
              only by you.
            </p>
          </div>

          <div className="faq-item">
            <p className="faq-question">
              Can I reset my account or delete my data?
            </p>
            <p className="faq-answer">
              Yes, under <strong>Settings</strong>, choose{" "}
              <strong>Reset Account</strong> to delete the ExpenseBot folder on
              your Drive and start fresh. This won't affect your subscription
              status.
            </p>
          </div>
        </div>
      </section>

      {/* Tips & Best Practices Section */}
      <section id="tips" className="section">
        <h2 className="section-header">Tips & Best Practices</h2>

        {/* Receipt Tips */}
        <div className="tips-card">
          <h3 className="tips-card-title">Receipt Management</h3>
          <ul className="tips-list">
            <li className="tips-item">
              <i className="fas fa-trash-alt tips-icon"></i>
              <span className="tips-content">
                Once you submit a photo of a receipt to ExpenseBot, you can
                delete it from your camera roll.
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-receipt tips-icon"></i>
              <div className="flex flex-col gap-2">
                <span className="tips-content">
                  For restaurants receipts, include both the itemized receipt
                  and credit card slip in the same photo to capture tax, tip and
                  total
                </span>
                <div className="mt-2 rounded-lg overflow-hidden shadow-sm">
                  <img
                    src="/sidebyside.png"
                    alt="Example of itemized receipt next to credit card slip"
                    className="w-full object-cover rounded-lg hover:opacity-95 transition-opacity"
                    style={{ maxWidth: "300px" }}
                  />
                </div>
              </div>
            </li>
            <li className="tips-item">
              <i className="fas fa-pen tips-icon"></i>
              <div className="space-y-3 w-full">
                <span className="tips-content">
                  Write Tag names directly on receipts before photographing for
                  auto-grouping by client, project or trip
                </span>
                <div className="relative w-full overflow-hidden pt-[56.25%] rounded-lg shadow-sm mt-3">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/uQWMOdJNkwE?si=EQGGsvZVTBH3vGNA&rel=0"
                    title="How to use tags on receipts"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    referrerPolicy="strict-origin-when-cross-origin"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="tips-card">
          <h3 className="tips-card-title">Currency Management</h3>
          <ul className="tips-list">
            <li className="tips-item">
              <i className="fas fa-clock tips-icon"></i>
              <div className="space-y-3 w-full">
                <span className="tips-content">
                  Wait for credit card statements before submitting foreign
                  currency expenses and add converted amounts in the Func. Total
                  column
                </span>
                <div className="relative w-full overflow-hidden pt-[56.25%] rounded-lg shadow-sm mt-3">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/PX_foWzqiTg?si=_EuD7hhtPdGGKeP1&rel=0"
                    title="How to handle foreign currency expenses"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    referrerPolicy="strict-origin-when-cross-origin"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>

        {/* Organization Tips */}
        <div className="tips-card">
          <h3 className="tips-card-title">Organization Best Practices</h3>
          <ul className="tips-list">
            <li className="tips-item">
              <i className="fas fa-user-plus tips-icon"></i>
              <div className="w-full space-y-4">
                <div className="tips-content">
                  Invite your assistant, accountant, or approver to view your
                  reports
                  <span className="menu-path">
                    Reports → People with access
                  </span>
                </div>
                <div className="space-y-4">
                  <div className="relative w-full overflow-hidden pt-[56.25%] rounded-lg shadow-sm">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full"
                      src="https://www.youtube.com/embed/R-V3rkFPwFQ?si=FjQgkPYtjVcO85ZE&rel=0"
                      title="How to share reports with others"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      referrerPolicy="strict-origin-when-cross-origin"
                    />
                  </div>
                  <div className="relative w-full overflow-hidden pt-[56.25%] rounded-lg shadow-sm">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full"
                      src="https://www.youtube.com/embed/iXsdaEmyCEE?si=Jm7BxoJKkCDqZ4-2&rel=0"
                      title="How to view someone else's reports"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      referrerPolicy="strict-origin-when-cross-origin"
                    />
                  </div>
                </div>
              </div>
            </li>
            <li className="tips-item">
              <i className="fas fa-cog tips-icon"></i>
              <span className="tips-content">
                Customize "Tips for AI" rules in the spreadsheet under the
                Expense Account or Tags tabs
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-highlighter tips-icon"></i>
              <div className="flex flex-col gap-2">
                <span className="tips-content">
                  Review yellow highlighted cells to check AI's suggestions
                </span>
                <div className="mt-2 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow">
                  <img
                    src="/yellow.png"
                    alt="Example of yellow highlighted cells for AI suggestions"
                    className="w-full object-cover rounded-lg"
                    style={{ maxWidth: "300px" }}
                  />
                </div>
              </div>
            </li>
            <li className="tips-item">
              <i className="fas fa-undo-alt tips-icon"></i>
              <span className="tips-content">
                To resubmit expenses, remove the 'Submit Date' and 'Batch ID'
                entries from the main spreadsheet
              </span>
            </li>
            <li className="tips-item">
              <i className="fas fa-folder tips-icon"></i>
              <span className="tips-content">
                All ExpenseBot data is stored in your Google Drive "ExpenseBot
                Expenses" folder
              </span>
            </li>
          </ul>
        </div>

        <div className="important-note">
          Need help? Email support@expensebot.ai
        </div>
      </section>
    </div>
  );
};

export default FAQPage;
