import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";

const ComplianceContext = createContext(null);

export function ComplianceProvider({ children }) {
  const [hasComplianceDocuments, setHasComplianceDocuments] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Add a document version counter
  const [documentVersion, setDocumentVersion] = useState(0);
  const isVerifying = useRef(false);
  const verificationTimeout = useRef(null);
  const verifyFn = useRef(null);

  // Store verify function in a ref to keep it stable
  verifyFn.current = async () => {
    if (isVerifying.current) {
      return hasComplianceDocuments;
    }

    try {
      isVerifying.current = true;
      console.log("Checking for compliance documents...");

      // Check for policy documents first
      const policyResponse = await fetch("/api/documents?type=policy", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      // Then check for formAdv documents
      const formAdvResponse = await fetch("/api/documents?type=formAdv", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      let hasPolicyDocs = false;
      let hasFormAdvDocs = false;

      if (policyResponse.ok) {
        const policyData = await policyResponse.json();
        console.log("Policy documents response:", policyData);
        hasPolicyDocs =
          policyData.success &&
          Array.isArray(policyData.documents) &&
          policyData.documents.length > 0;
      }

      if (formAdvResponse.ok) {
        const formAdvData = await formAdvResponse.json();
        console.log("Form ADV documents response:", formAdvData);
        hasFormAdvDocs =
          formAdvData.success &&
          Array.isArray(formAdvData.documents) &&
          formAdvData.documents.length > 0;
      }

      const hasAnyComplianceDocs = hasPolicyDocs || hasFormAdvDocs;
      console.log("Has policy documents:", hasPolicyDocs);
      console.log("Has Form ADV documents:", hasFormAdvDocs);

      setHasComplianceDocuments(hasAnyComplianceDocs);
      return hasAnyComplianceDocs;
    } catch (error) {
      console.error("Error checking for compliance documents:", error);
      setHasComplianceDocuments(false);
      return false;
    } finally {
      setIsLoading(false);
      isVerifying.current = false;
    }
  };

  // Expose a stable version of verify
  const verifyDocuments = async () => {
    return verifyFn.current();
  };

  // Regular function to update document existence
  const setDocumentsExist = (exists) => {
    console.log("Directly setting compliance documents exist:", exists);
    setHasComplianceDocuments(exists);
  };

  // New function specifically for signaling document changes
  const notifyDocumentChange = () => {
    console.log("Document change notification - incrementing version");
    setDocumentVersion((prev) => prev + 1);
  };

  useEffect(() => {
    // Initial verification
    verifyFn.current();

    // Set up interval for periodic checks - less frequent than QuickBooks since docs don't change often
    const intervalId = setInterval(() => {
      // Clear any pending verification
      if (verificationTimeout.current) {
        clearTimeout(verificationTimeout.current);
      }

      // Set new verification with slight delay to prevent bunching
      verificationTimeout.current = setTimeout(() => {
        verifyFn.current();
      }, 1000);
    }, 30 * 60 * 1000); // Check every 30 minutes

    // Cleanup
    return () => {
      clearInterval(intervalId);
      if (verificationTimeout.current) {
        clearTimeout(verificationTimeout.current);
      }
    };
  }, []);

  // Single value object with all context properties
  const value = {
    hasComplianceDocuments,
    setHasComplianceDocuments,
    isLoading,
    verifyDocuments,
    setDocumentsExist,
    notifyDocumentChange,
    documentVersion,
  };

  return (
    <ComplianceContext.Provider value={value}>
      {children}
    </ComplianceContext.Provider>
  );
}

export function useCompliance() {
  const context = useContext(ComplianceContext);
  if (!context) {
    throw new Error("useCompliance must be used within a ComplianceProvider");
  }
  return context;
}
