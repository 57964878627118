import React, { useState } from "react";
import { PlayCircle, Receipt, Film } from "lucide-react";
import "../styles/Modal.css";

const WelcomeModal = ({ onClose }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCheckboxChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("hideWelcomeModal", "true");
    }
    onClose();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content bg-white rounded-xl shadow-2xl w-full max-w-md transform transition-all">
        <div className="p-2">
          <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
            Get Started with ExpenseBot
          </h2>

          <div className="space-y-6">
            {/* Receipt Card */}
            <a
              href="https://youtube.com/shorts/Gpx74dqoYXQ?feature=share"
              target="_blank"
              rel="noopener noreferrer"
              className="block bg-blue-50 rounded-lg p-4 transform transition-all hover:scale-105"
            >
              <div className="flex items-start space-x-4">
                <div className="bg-blue-100 p-3 rounded-lg">
                  <Receipt className="w-6 h-6 text-blue-600" />
                </div>
                <div className="flex-1">
                  <h3 className="font-semibold text-gray-800 mb-2">
                    Add a Receipt Photo
                  </h3>
                  <p className="text-sm text-gray-600 mb-2">
                    See how easy it is to capture and process your receipts
                  </p>
                  {/* Instead of an <a> link, just use a <div> or <span> for the icon & label */}
                  <div className="inline-flex items-center text-blue-600 hover:text-blue-700 font-medium">
                    <PlayCircle className="w-4 h-4 mr-2" />
                    <span>Watch Demo</span>
                  </div>
                </div>
              </div>
            </a>

            {/* Getting Started Card */}
            <a
              href="https://www.youtube.com/playlist?list=PLjwcWbInP0mIQv7xhos1-74t7pDz19lD7"
              target="_blank"
              rel="noopener noreferrer"
              className="block bg-purple-50 rounded-lg p-4 transform transition-all hover:scale-105"
            >
              <div className="flex items-start space-x-4">
                <div className="bg-purple-100 p-3 rounded-lg">
                  <Film className="w-6 h-6 text-purple-600" />
                </div>
                <div className="flex-1">
                  <h3 className="font-semibold text-gray-800 mb-2">
                    Getting Started
                  </h3>
                  <p className="text-sm text-gray-600 mb-2">
                    Watch our comprehensive tutorial playlist
                  </p>
                  {/* Replace the old <a> tag with a non-anchor element to avoid nesting anchors */}
                  <div className="inline-flex items-center text-purple-600 hover:text-purple-700 font-medium">
                    <PlayCircle className="w-4 h-4 mr-2" />
                    <span>Watch Playlist</span>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div className="modal-footer mt-6">
            <label className="checkbox-container inline-flex items-center">
              <input
                type="checkbox"
                checked={dontShowAgain}
                onChange={handleCheckboxChange}
                className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <span className="checkbox-label ml-2 text-sm text-gray-600">
                Don't show again
              </span>
            </label>
            <button
              onClick={handleClose}
              className="close-button px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
