import React, { useState } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import "../styles/ApprovalOverrideModal.css"; // Use new dedicated styles

const ApprovalOverrideModal = ({
  isOpen,
  onClose,
  expenseId,
  reportId,
  spreadsheetId,
  clientEmail,
  rowIndex,
  merchant,
  amount,
  violation,
  category,
  onSuccess,
}) => {
  const [justification, setJustification] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      // Get the current user's info (name or email)
      const currentUser = localStorage.getItem("userEmail") || "admin";
      const currentTime = new Date().toISOString();

      // Format the override note with a standardized format for easy detection later
      const overrideNote = `COMPLIANCE OVERRIDE [${currentTime}] by ${currentUser}:\nViolation: "${violation}"\nJustification: ${justification}`;

      // First, get the existing notes to append to them
      const getNotesResponse = await axios.post(
        "/api/get-spreadsheet-cell",
        {
          spreadsheetId: reportId, // Use reportId as the spreadsheetId, just like in BusinessPurposeModal
          rowIndex,
          columnId: "L", // Notes column
          clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      let existingNotes = "";
      if (getNotesResponse.data.success && getNotesResponse.data.value) {
        existingNotes = getNotesResponse.data.value;
      }

      // Combine existing notes with override note
      const combinedNotes = existingNotes
        ? `${existingNotes}\n\n${overrideNote}`
        : overrideNote;

      // Update the notes field with the combined content
      const updateResponse = await axios.post(
        "/api/update-spreadsheet-cell",
        {
          spreadsheetId: reportId, // Use reportId as the spreadsheetId
          reportId,
          rowIndex,
          columnId: "L", // Notes column
          value: combinedNotes,
          clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (updateResponse.data.success) {
        // Now record the override in our database for future learning
        // This stores the override linked to the policy/report/expense
        // In the handleSubmit function, before making the axios.post call:
        await axios.post(
          "/api/compliance/record-override",
          {
            reportId,
            expenseId,
            spreadsheetId: reportId, // Use reportId as the spreadsheetId
            rowIndex,
            violation,
            justification,
            approver: currentUser,
            timestamp: currentTime,
            merchant,
            amount,
            category: category || "Uncategorized",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (onSuccess) {
          onSuccess({
            notes: combinedNotes,
            approver: currentUser,
            timestamp: currentTime,
            justification,
          });
        }
        onClose();
      } else {
        setError(
          updateResponse.data.message || "Failed to record approval override"
        );
      }
    } catch (error) {
      console.error("Error recording approval override:", error);
      setError(
        error.response?.data?.message || "Failed to record approval override"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="approval-override-backdrop">
      <div className="approval-override-wrapper">
        <div className="approval-override-header">
          <h2 className="approval-override-title">
            <i className="fas fa-check-circle"></i>
            Approval Override
          </h2>
          <button className="approval-override-close" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="approval-override-content">
          {error && (
            <div className="approval-override-alert">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}

          <div className="override-expense-details">
            <div className="override-expense-detail-item">
              <span className="override-detail-label">Merchant:</span>
              <span className="override-detail-value">{merchant}</span>
            </div>
            <div className="override-expense-detail-item">
              <span className="override-detail-label">Amount:</span>
              <span className="override-detail-value">
                ${parseFloat(amount).toFixed(2)}
              </span>
            </div>
            <div className="override-expense-detail-item">
              <span className="override-detail-label">Violation:</span>
              <span
                className="override-detail-value"
                style={{ wordBreak: "break-word" }}
              >
                {(() => {
                  console.log(
                    "Violation in render:",
                    violation,
                    typeof violation
                  );
                  return violation || "No violation specified";
                })()}
              </span>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="approval-override-form">
            <div className="approval-override-form-group">
              <label
                htmlFor="justification"
                className="approval-override-label"
              >
                Justification for Override *
              </label>
              <textarea
                id="justification"
                className="approval-override-textarea"
                value={justification}
                onChange={(e) => setJustification(e.target.value)}
                placeholder="Explain why you are approving this expense despite the policy violation"
                rows={4}
                required
              />
              <div className="approval-override-hint">
                This override will be recorded with your name and timestamp.
                Similar expenses may be automatically approved in the future
                based on this pattern.
              </div>
            </div>

            <div className="approval-override-actions">
              <button
                type="button"
                onClick={onClose}
                className="approval-override-button approval-override-button-secondary"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="approval-override-button approval-override-button-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="approval-override-button-loading">
                    <TailSpin color="#ffffff" height={16} width={16} />
                    <span>Processing...</span>
                  </span>
                ) : (
                  "Approve & Override"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApprovalOverrideModal;
