import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import PhotoSelector from "./PhotoSelector";
import DateRangeSelector from "./DateRangeSelector";
import "../styles/ProcessInfo.css";
import { jwtDecode } from "jwt-decode";
import CheckoutButton from "./CheckoutButton";
import WelcomeModal from "./WelcomeModal";
import ShareToQuickBooks from "./ShareToQuickBooks";
import ShareToWave from "./ShareToWave";
//import ICloudPhotoSelector from "./iCloudPhotoSelector";
import AdminView from "./AdminView";
import LicenseManager from "./LicenseManager";
import CameraSelector from "./EnhancedCameraSelector";
import FileUploader from "./FileUploader";
import PdfUploader from "./PdfUploader"; // Import PdfUploader
import { toast } from "react-toastify";
import GmailProcessor from "./GmailProcessor"; // Add this import
import TagManagementModal from "./TagManagementModal";
import ExpenseCategoryManagementModal from "./ExpenseCategoryManagementModal";
import ComplianceDocumentsModal from "./ComplianceDocumentsModal";

import { LoadScript } from "@react-google-maps/api";
import PhotoSelectionContainer from "./PhotoSelectionContainer";
import AdminPortal from "./adminPortal";
import { uploadToGooglePhotos } from "./cameraPhotosPicker";

import MileageTracker from "./MileageTracker";
import { offlineStorage } from "../services/offlineStorage";
import ComplianceCheck from "./ComplianceCheck";
import DeleteConfirmationModal from "./utils/DeleteConfirmationModal";
import ReportWizardModal from "./ReportWizardModal";
import { ComplianceProvider } from "./ComplianceContext";

import EmailManagement from "./EmailManagement";
import ExpensePolicyStatus from "./ExpensePolicyStatus";

import "../styles/EmailManagement.css";

const libraries = ["places"];

const SHOW_ICLOUD_BUTTON = false;
const SHOW_WAVE_BUTTON = true;

const ProcessInfo = ({ user, setUser, processInfo, setProcessInfo }) => {
  console.log("ProcessInfo received user:", user); // Add this
  console.log("Is user admin?", user?.isAdmin);
  const location = useLocation();
  const [isSavingNote, setIsSavingNote] = useState(false);

  const navigate = useNavigate();
  const [resetError, setResetError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [showComplianceDocs, setShowComplianceDocs] = useState(false);
  const [showGmailProcessor, setShowGmailProcessor] = useState(false);
  const [showTagManagementModal, setShowTagManagementModal] = useState(false);
  const [showExpenseCategoryModal, setShowExpenseCategoryModal] =
    useState(false);

  const [photoSource, setPhotoSource] = useState(null);
  const pollIntervalRef = useRef(null);
  const [quickBooksInitialized, setQuickBooksInitialized] = useState(false);
  const [waveConnectionStatus, setWaveConnectionStatus] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [activeReportForEmail, setActiveReportForEmail] = useState(null);
  const currentFetchRef = useRef(null);
  const fetchTimeoutRef = useRef(null);
  const isFetchingRef = useRef(false);
  const [isMileageModalOpen, setMileageModalOpen] = useState(false);
  const [reportsFetched, setReportsFetched] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState(() => {
    const storedMenu = localStorage.getItem("selectedMenu");
    return storedMenu || "addExpenses";
  });
  const [processing, setProcessing] = useState(
    !processInfo?.processingCompleted
  );
  const [lastSynced, setLastSynced] = useState(processInfo?.endDate || "");
  const [autoSyncEnabled, setAutoSyncEnabled] = useState(
    processInfo?.automaticSyncEnabled
  );
  const [nextSyncMessage, setNextSyncMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showWizard, setShowWizard] = useState(false);

  // const [noOutstandingExpenses, setNoOutstandingExpenses] = useState(false);
  // Add this near the top of your component
  const [showLabel, setShowLabel] = useState(window.innerWidth >= 500);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // Modify existing loading state
  const [loading, setLoading] = useState({
    reviewerToggle: false,
    complianceToggle: false,
    reports: false,
    reset: false,
    deleteReport: null, // Add this line
  });

  //const [isModalOpen, setIsModalOpen] = useState(false); // Controls modal visibility
  //const [formattedReportData, setFormattedReportData] = useState([]);
  // const [dataLoading, setDataLoading] = useState(false);
  //const [showPreview, setShowPreview] = useState(false);
  const [quickbooksConnectionStatus, setQuickbooksConnectionStatus] = useState(
    processInfo?.quickbooks?.connected || false
  );

  const params = useParams(); // Add this to get client email from route
  const isAccountantView = location.state?.isAccountantView;
  const clientEmail = params?.clientEmail;
  const [adminViewEnabled, setAdminViewEnabled] = useState(false);
  const [editingReportId, setEditingReportId] = useState(null);
  const [editedNote, setEditedNote] = useState("");
  const [reportFilter, setReportFilter] = useState("All");
  // const [reportFunctionalTotal, setReportFunctionalTotal] = useState(0);
  // const [hasEmptyColumnR, setHasEmptyColumnR] = useState(0);
  //  const [reportHomeCurrency, setReportHomeCurrency] = useState(null);

  //Offline capability
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const queueProcessingRef = useRef(false);
  const isProcessingRef = useRef(false);
  const lastRequestIdRef = useRef(null);

  // Listen for outside clicks to close download menu
  const [downloadingReportId, setDownloadingReportId] = useState(null);
  const downloadRefs = useRef({});

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    reportId: null,
  });

  const handlePreviewClick = () => {
    // Instead of opening the old ReportModal, open the wizard modal
    setShowWizard(true);
  };

  const handleEmailManagement = (report) => {
    setActiveReportForEmail(report);
    setShowEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
    setActiveReportForEmail(null);
  };

  const handleDownload = async (reportId, format) => {
    try {
      setDownloadingReportId(null); // Close the dropdown

      // Get the download using Axios with responseType blob - using your pattern
      const response = await axios({
        url: `/api/downloadReport/${reportId}/${format}`,
        method: "GET",
        responseType: "blob", // Important for handling file downloads
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Extract filename from Content-Disposition header if present
      let filename = `expense-report.${format}`;
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      // Create a temporary link and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Show success toast
      toast.success(
        `Report downloaded successfully as ${format.toUpperCase()}`
      );
    } catch (error) {
      console.error("Error downloading report:", error);

      // Display error message
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(
          `Server error: ${error.response.status}`,
          error.response.data
        );
        toast.error(
          `Failed to download: ${
            error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received", error.request);
        toast.error("Server did not respond. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Request error", error.message);
        toast.error(`Download error: ${error.message}`);
      }
    }
  };

  const handleUpdateEmails = async (reportId, addEmails, removeEmails) => {
    try {
      const response = await axios.post(
        "/api/report-access",
        {
          reportId,
          addRecipients: addEmails,
          removeRecipients: removeEmails,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          reports: prevInfo.reports.map((report) =>
            report.reportId === reportId
              ? {
                  ...report,
                  sentTo: response.data.shared_with,
                  shared_with: response.data.shared_with,
                }
              : report
          ),
        }));

        handleCloseEmailModal();
      } else {
        console.error("Failed to update report access:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating report access:", error);
    }
  };

  // Replace the handleReportSubmitted function with this improved version
  const handleReportSubmitted = (reportData) => {
    console.log("New report submitted:", reportData);

    // Update processInfo state with the new report
    setProcessInfo((prevInfo) => {
      // Make sure we have a reports array to work with
      const currentReports = Array.isArray(prevInfo.reports)
        ? [...prevInfo.reports]
        : [];

      // Create the new report object
      const newReport = {
        reportId: reportData.reportId,
        batchNumber: reportData.batchNumber,
        link: reportData.link,
        submissionDate: reportData.submissionDate,
        title: reportData.title,
        status: reportData.status || "Submitted",
        notes: reportData.notes || "",
        receiptCount: reportData.receiptCount,
        functionalTotal: reportData.functionalTotal,
        homeCurrency: reportData.homeCurrency,
        sentTo: reportData.sentTo,
        quickbooks: {
          status: "not_submitted",
          submittedAt: null,
          transactionId: null,
          error: null,
        },
      };

      // Add the new report to the beginning of the array
      return {
        ...prevInfo,
        lastSubmittedDate: reportData.submissionDate,
        unsubmittedReceiptCount: reportData.unsubmittedReceiptCount,
        reports: [newReport, ...currentReports],
      };
    });

    // Reset to first page and fetch fresh data
    setPage(1);

    // Wait a moment before refreshing to avoid race conditions
    setTimeout(() => {
      fetchReports(1);
    }, 500);
  };

  // This effect listens for clicks outside the currently-open download menu
  useEffect(() => {
    function handleClickOutside(event) {
      // If no dropdown is open, do nothing
      if (!downloadingReportId) return;

      // Get the ref for the currently open dropdown
      const currentDropdownRef = downloadRefs.current[downloadingReportId];

      // If we do have a ref and the click is outside it, close the menu
      if (currentDropdownRef && !currentDropdownRef.contains(event.target)) {
        setDownloadingReportId(null);
      }
    }

    // Listen for all mousedown (or click) events on the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [downloadingReportId]);

  useEffect(() => {
    // When switching to the expense reports tab, set loading to true only if we need to fetch data
    if (selectedMenu === "expenseReports") {
      console.log("Switching to expense reports tab");

      // Only set loading if we don't already have reports or if forced refresh
      const shouldFetchReports = !processInfo?.reports?.length || page !== 1;

      if (shouldFetchReports) {
        setLoading((prev) => ({ ...prev, reports: true }));
      } else {
        console.log("Reports already loaded, not triggering loading state");
      }
    }
  }, [selectedMenu, page, processInfo?.reports?.length]);

  useEffect(() => {
    // Only set isInitialLoad to false when reports are finished loading
    // and we actually have data
    if (!loading.reports && isInitialLoad) {
      console.log(
        "Initial load completed, reports loaded:",
        Array.isArray(processInfo?.reports) ? processInfo.reports.length : 0
      );

      // Add a small delay to ensure state updates complete properly
      setTimeout(() => {
        setIsInitialLoad(false);
      }, 300);
    }
  }, [loading.reports, isInitialLoad, processInfo?.reports]);

  useEffect(() => {
    // Initialize the admin view state from localStorage (default to false if not set)
    const storedAdminView = localStorage.getItem("adminViewEnabled") === "true";
    setAdminViewEnabled(storedAdminView);
  }, []);

  // Toggle Admin View function
  /*  const toggleAdminView = () => {
    setAdminViewEnabled((prev) => {
      const newValue = !prev;
      localStorage.setItem("adminViewEnabled", newValue);
      return newValue;
    });
  }; */

  const fetchReports = useCallback(
    async (pageNum = 1) => {
      // If already fetching, don't start another fetch
      if (isFetchingRef.current) {
        console.log("Already fetching reports, canceling this request");
        return;
      }

      // Record the start time for minimum loading duration
      const fetchStartTime = Date.now();
      const minimumLoadingTime = 500; // ms

      // Set fetching ref to true at the start
      isFetchingRef.current = true;

      // Always reset loading state when starting
      setLoading((prev) => ({ ...prev, reports: true }));
      console.log("Setting reports loading state to TRUE");

      try {
        console.log(
          `Fetching reports for page ${pageNum}, filter: ${reportFilter}`
        );
        const response = await axios.get(
          `/api/reports?page=${pageNum}&filter=${reportFilter}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        console.log("Reports API response:", response.data);

        // Check if we got a valid response with reports
        if (response.data?.data?.reports) {
          // Update reports in processInfo
          setProcessInfo((prev) => ({
            ...prev,
            reports: response.data.data.reports,
          }));

          // Calculate total pages
          const totalPages = response.data?.data?.pagination?.totalPages || 1;
          setTotalPages(totalPages);

          console.log(
            `Fetched ${response.data.data.reports.length} reports, total pages: ${totalPages}`
          );
        } else {
          console.log("No reports found in API response");
          // Set an empty array to be clear
          setProcessInfo((prev) => ({
            ...prev,
            reports: [],
          }));
        }

        // Set reportsFetched to true ONLY if we successfully got a response
        setReportsFetched(true);
      } catch (error) {
        console.error("Error fetching reports:", error);
        // On error, ensure we also set reportsFetched to avoid infinite loading
        setReportsFetched(true);
        // Set reports to empty array on error to be safe
        setProcessInfo((prev) => ({
          ...prev,
          reports: [],
        }));
      } finally {
        // Calculate time elapsed since fetch started
        const timeElapsed = Date.now() - fetchStartTime;

        // If less than minimum loading time, wait before clearing loading state
        if (timeElapsed < minimumLoadingTime) {
          const waitTime = minimumLoadingTime - timeElapsed;
          console.log(`Waiting ${waitTime}ms to ensure minimum loading time`);

          await new Promise((resolve) => setTimeout(resolve, waitTime));
        }

        // Always clear loading state
        console.log("Setting reports loading state to FALSE");
        setLoading((prev) => ({ ...prev, reports: false }));

        // Set fetching ref to false when done
        isFetchingRef.current = false;
      }
    },
    [reportFilter, setProcessInfo]
  );

  const handleReviewerToggle = useCallback(async () => {
    setLoading((prev) => ({ ...prev, reviewerToggle: true }));
    try {
      const willBeEnabled = !user?.roles?.accountant;

      const response = await fetch("/api/toggle-accountant-role", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          enabled: willBeEnabled,
        }),
      });

      if (!response.ok) throw new Error("Failed to toggle reviewer role");

      const data = await response.json();
      if (data.success) {
        setUser((prev) => ({
          ...prev,
          roles: data.roles,
        }));
      }
    } catch (error) {
      console.error("Error toggling reviewer role:", error);
    } finally {
      setLoading((prev) => ({ ...prev, reviewerToggle: false }));
    }
  }, [user?.roles?.accountant, setUser, setLoading]);

  useEffect(() => {
    // Safety timer to clear loading state if it gets stuck
    let safetyTimer = null;

    if (loading.reports) {
      console.log("Starting safety timer for reports loading");
      safetyTimer = setTimeout(() => {
        console.log(
          "⚠️ Safety timer triggered - forcing loading.reports to false"
        );
        setLoading((prev) => ({ ...prev, reports: false }));
        isFetchingRef.current = false; // Also reset the fetching flag
      }, 5000); // 5 seconds maximum loading time
    }

    return () => {
      if (safetyTimer) {
        clearTimeout(safetyTimer);
      }
    };
  }, [loading.reports]);

  useEffect(() => {
    // Nothing needed in the setup phase

    return () => {
      // Create a local reference to avoid the warning
      const currentTimeout = fetchTimeoutRef.current;
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      // Reset the ref
      fetchTimeoutRef.current = null;
      currentFetchRef.current = null;
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setShowLabel(window.innerWidth >= 500);
    };

    // Initialize on mount
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const checkWaveConnection = async () => {
      try {
        const response = await fetch(
          isAccountantView
            ? `/api/wave/connection-status?clientEmail=${encodeURIComponent(
                clientEmail
              )}`
            : "/api/wave/connection-status",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok)
          throw new Error("Failed to get Wave connection status");

        const data = await response.json();
        setWaveConnectionStatus(data.connected);
      } catch (error) {
        console.error("Error checking Wave connection:", error);
        setWaveConnectionStatus(false);
      }
    };

    checkWaveConnection();
  }, [clientEmail, isAccountantView]);
  useEffect(() => {
    // If we have reports data (even empty array) and we're not fetching new ones,
    // make sure loading.reports is false
    if (processInfo?.reports !== undefined && !isFetchingRef.current) {
      console.log("Ensuring loading state is properly reset");
      setLoading((prev) => {
        if (prev.reports) {
          console.log("Fixing loading state - was loading but now have data");
          return { ...prev, reports: false };
        }
        return prev;
      });
    }
  }, [processInfo?.reports]);
  /* 
  useEffect(() => {
    // Set appropriate default menu when role changes
    if (user?.roles?.type === "Employer") {
      setSelectedMenu("licenses");
    } else if (user?.roles?.type === "I-Basic") {
      setSelectedMenu("allExpenses");
    } else if (
      user?.roles?.type === "Employee" ||
      user?.roles?.type === "I-Pro" ||
      user?.subscription?.isTrial
    ) {
      setSelectedMenu("allExpenses");
    } else {
      setSelectedMenu("allExpenses"); // default fallback
    }
  }, [user?.roles?.type, user?.roles?.accountant, user?.subscription?.isTrial]);
*/

  const [didAutoEnable, setDidAutoEnable] = useState(false);

  useEffect(() => {
    const handleAdminViewReturn = async () => {
      // Only auto-enable accountant if we haven't done it yet
      if (location.state?.returnPage === "adminView" && !didAutoEnable) {
        // Switch to adminView
        setSelectedMenu("adminView");

        // If user is NOT already accountant, enable it once
        if (!user?.roles?.accountant) {
          await handleReviewerToggle();
        }

        // Mark that we've auto-enabled once so it won't happen again
        setDidAutoEnable(true);

        // Optionally remove or keep these lines:
        if (!adminViewEnabled) {
          setAdminViewEnabled(true);
          localStorage.setItem("adminViewEnabled", "true");
        }

        window.history.replaceState({}, document.title, location.pathname);
      }
    };

    handleAdminViewReturn();
  }, [
    location.state,
    user?.roles?.accountant,
    handleReviewerToggle,
    adminViewEnabled,
    didAutoEnable,
    location.pathname,
  ]);

  // In ProcessInfo.jsx, add to your useEffect
  useEffect(() => {
    // Check if there was a pending Gmail auth
    const pendingGmailAuth = localStorage.getItem("pendingGmailAuth");

    if (pendingGmailAuth === "true") {
      // Clear the flag
      localStorage.removeItem("pendingGmailAuth");

      // Show a toast message
      toast.success("Gmail authorization successful!");

      // Show the Gmail processor modal
      setShowGmailProcessor(true);
    }
  }, []); // Run once on component mount

  // Update the useEffect for initial reports loading
  useEffect(() => {
    if (
      selectedMenu === "expenseReports" &&
      !reportsFetched &&
      !isFetchingRef.current
    ) {
      console.log("Initial reports fetch triggered");

      // Reset loading state to true before fetch
      setLoading((prev) => ({ ...prev, reports: true }));

      fetchReports(page)
        .then(() => {
          console.log(
            "Initial reports fetch completed, reportsFetched set to true"
          );
        })
        .catch((err) => {
          console.error("Error in initial reports fetch:", err);
          // Still mark as fetched to prevent infinite loading
          setReportsFetched(true);
          // Reset loading state
          setLoading((prev) => ({ ...prev, reports: false }));
        });
    }
  }, [selectedMenu, page, reportsFetched, fetchReports]);

  const resetReportState = useCallback(() => {
    console.log("Resetting report state");
    // Reset all report-related state
    setReportsFetched(false);
    setPage(1);
    setTotalPages(1);
    // Clear the loading state
    setLoading((prev) => ({ ...prev, reports: false }));
    // Set isFetchingRef to false
    isFetchingRef.current = false;
  }, []);

  const handleMenuClick = useCallback(
    (menu) => {
      if (menu === "adminView" && !user?.roles?.accountant) return;

      // If switching FROM expenseReports, reset report state
      if (selectedMenu === "expenseReports" && menu !== "expenseReports") {
        resetReportState();
      }

      // If switching TO expenseReports from another view, ensure proper initialization
      if (menu === "expenseReports" && selectedMenu !== "expenseReports") {
        // Clear any lingering loading state first
        setLoading((prev) => ({ ...prev, reports: false }));
        // Set reportsFetched to false to trigger a fresh fetch
        setReportsFetched(false);
      }

      setSelectedMenu(menu);

      // Only store if not adminview
      if (menu !== "adminView") {
        localStorage.setItem("selectedMenu", menu);
      }

      if (menu === "adminView") {
        navigate(`/process-info?${location.search}`, {
          state: { returnPage: "adminView" },
        });
      }
    },
    [
      selectedMenu,
      user?.roles?.accountant,
      location.search,
      navigate,
      resetReportState,
    ]
  );

  const [isPhotoSelectorOpen, setIsPhotoSelectorOpen] = useState(false);

  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);

  const [cancelAt, setCancelAt] = useState(null);

  const handleNoteUpdate = async (reportId, newNote) => {
    try {
      // Save to database
      const response = await axios.post(
        "/api/update-session",
        {
          reportId,
          notes: newNote,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Session updated successfully") {
        // Update local state
        setProcessInfo((prev) => ({
          ...prev,
          reports: prev.reports.map((r) =>
            r.reportId === reportId
              ? {
                  ...r,
                  notes: newNote,
                }
              : r
          ),
        }));
        setEditingReportId(null);
      }
    } catch (error) {
      console.error("Failed to save note:", error);
      alert("Failed to save note. Please try again.");
    }
  };

  // Handle report deletion
  const handleDeleteReport = (reportId) => {
    // Open the confirmation modal instead of using window.confirm
    setDeleteConfirmation({
      isOpen: true,
      reportId,
    });
  };

  const confirmDelete = async (revertExpenses = false) => {
    const reportId = deleteConfirmation.reportId;

    try {
      // Add loading state for the specific report being deleted
      setLoading((prev) => ({ ...prev, deleteReport: reportId }));

      // Close the modal
      setDeleteConfirmation({ isOpen: false, reportId: null });

      // Call API to delete the report with the user's preference
      const response = await axios.post(
        "/api/delete-report",
        {
          reportId,
          revertExpenses,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setProcessInfo((prev) => {
          const newReports = prev.reports.filter(
            (r) => r.reportId !== reportId
          );
          return {
            ...prev,
            reports: newReports,
            processingCompleted:
              newReports.length === 0 ? true : prev.processingCompleted,
            unsubmittedReceiptCount:
              response.data.unsubmittedReceiptCount ||
              prev.unsubmittedReceiptCount,
          };
        });
      } else {
        throw new Error(response.data.message || "Failed to delete report");
      }
    } catch (error) {
      console.error("Failed to delete report:", error);
      alert(
        "Failed to delete report: " +
          (error.response?.data?.message || error.message || "Unknown error")
      );
    } finally {
      setLoading((prev) => ({ ...prev, deleteReport: null }));
    }
  };

  // Handle status updates
  const handleStatusUpdate = async (reportId, newStatus) => {
    try {
      // Save the updated status to the database
      const response = await axios.post(
        "/api/update-session",
        {
          reportId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Session updated successfully") {
        // Update local state
        setProcessInfo((prev) => ({
          ...prev,
          reports: prev.reports.map((r) =>
            r.reportId === reportId
              ? {
                  ...r,
                  status: newStatus,
                }
              : r
          ),
        }));
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  // Collapsible states
  const [isAutoPilotOpen, setIsAutoPilotOpen] = useState(false);
  const [isManualSyncOpen, setIsManualSyncOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSection = (section) => {
    if (section === "autoPilot") setIsAutoPilotOpen(!isAutoPilotOpen);
    if (section === "manualSync") setIsManualSyncOpen(!isManualSyncOpen);
    if (section === "settings") setIsSettingsOpen(!isSettingsOpen);
  };

  const triggerGlobalLoadingState = useCallback((updates = {}) => {
    setProcessing(true);
    setLoading((prevLoading) => ({
      ...prevLoading,
      photoSelector: true,
      dateRangeSelector: true,
      updateSync: true,
      ...updates,
    }));
  }, []);

  const updateSubscriptionStateFromToken = (decoded) => {
    console.log("Decoded token:", decoded); // Log decoded token to ensure it's correct

    setIsSubscribed(decoded.isSubscribed);
    setIsTrial(decoded.isTrial);
    setSubscriptionEndDate(decoded.currentPeriodEnd);
    setCancelAt(decoded.cancelAt);

    if (decoded.isTrial && decoded.trialEndDate) {
      const endDate = new Date(decoded.trialEndDate);
      const currentDate = new Date();

      // Reset the hours of both dates to avoid partial day discrepancies
      endDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      const diffTime = endDate.getTime() - currentDate.getTime();
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

      // Log trialEndDate and the calculated remaining trial days
      console.log(`Trial End Date: ${endDate}`);
      console.log(`Current Date: ${currentDate}`);
      console.log(`Days Remaining in Trial: ${diffDays}`);

      setTrialDaysRemaining(diffDays);
    }
  };

  const resetGlobalLoadingState = useCallback(() => {
    setProcessing(false);
    setLoading((prevLoading) => ({
      ...prevLoading,
      photoSelector: false,
      dateRangeSelector: false,
      updateSync: false,
    }));
  }, []);

  const handlePhotoSelector = (source) => {
    if (source === "google") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("google");
    } else if (source === "camera") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("camera");
    } else if (source === "upload") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("upload");
    } else if (source === "pdf") {
      setIsPhotoSelectorOpen(true);
      setPhotoSource("pdf");
    } else if (source === "gmail") {
      // Add this case
      setLoading((prev) => ({ ...prev, photoSelector: true }));
      checkGmailPermissions();
    }
  };

  const handleDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(true);
  };

  const handlePhotosSelected = useCallback(
    async (mediaItems) => {
      const requestId = mediaItems.map((item) => item.id).join("-");
      if (isProcessingRef.current || requestId === lastRequestIdRef.current) {
        console.log(
          "Request already in progress or duplicate request detected"
        );
        return;
      }

      console.log("Media items length:", mediaItems.length);
      triggerGlobalLoadingState({ photoCount: mediaItems.length });

      setProcessInfo((prevInfo) => ({
        ...prevInfo,
        error: null,
        processingCompleted: false,
        uploadProgress: 0, // Initialize upload progress
        status:
          mediaItems[0]?.type === "pdf"
            ? "Preparing to upload PDF files..."
            : "Processing photos...",
        totalPhotos: mediaItems.length,
      }));

      try {
        isProcessingRef.current = true;
        lastRequestIdRef.current = requestId;

        // ********* NEW: Check if items are from Gmail first *********
        if (mediaItems[0]?.fromGmail) {
          console.log("Processing Gmail items:", mediaItems);
          const emailIds = [...new Set(mediaItems.map((item) => item.emailId))];
          const processedLabelIdSet = new Set(
            mediaItems
              .filter((item) => item.processedLabelId)
              .map((item) => item.processedLabelId)
          );
          const response = await axios.post(
            "/api/gmail/process-emails",
            {
              userSelectedPhotos: mediaItems,
              emailIds: emailIds,
              processedLabelId: processedLabelIdSet.size
                ? [...processedLabelIdSet][0]
                : undefined,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProcessInfo((prevInfo) => ({
                  ...prevInfo,
                  uploadProgress: percentCompleted,
                  status: `Processing Gmail items: ${percentCompleted}%`,
                }));
              },
            }
          );

          console.log("Gmail payload sent to server:", {
            userSelectedPhotos: mediaItems,
            emailIds: emailIds,
          });

          const data = response.data;

          if (data.success) {
            toast.success(
              `Successfully queued ${mediaItems.length} Gmail items for processing`
            );

            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              startDate: data.processInfo.startDate,
              endDate: data.processInfo.endDate,
              receiptsCount: data.processInfo.receiptsCount,
              spreadsheetId: data.processInfo.spreadsheetId,
              lastProcessedDate: data.processInfo.lastProcessedDate,
              processingCompleted: data.processInfo.processingCompleted,
              totalPhotos: data.processInfo.totalPhotos,
              status: data.processInfo.status,
              uploadProgress: 100,
            }));
            setLastSynced(data.processInfo.endDate);
          } else {
            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              uploadProgress: 0,
              status: "Error processing Gmail items",
            }));
            console.error("Error processing Gmail items:", data.message);
            toast.error("Error processing Gmail items. Please try again.");
          }
        } else if (mediaItems[0]?.type === "pdf") {
          console.log("Processing PDF items:", mediaItems);

          // Check if these are pre-uploaded PDFs with gcsPath (direct GCS upload from PdfUploader)
          const hasGcsPath = mediaItems[0]?.gcsPath !== undefined;

          if (hasGcsPath) {
            console.log("Processing pre-uploaded PDFs:", mediaItems);

            // For pre-uploaded PDFs, send JSON to the pdf-upload endpoint
            const response = await axios.post(
              "/api/pdf-upload",
              { userSelectedPhotos: mediaItems },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json", // Important: Set content type to JSON
                },
              }
            );

            console.log("PDF processing response:", response.data);
            const data = response.data;

            if (data.success) {
              toast.success(
                `Successfully queued ${mediaItems.length} PDF file(s) for processing`
              );

              setProcessInfo((prevInfo) => ({
                ...prevInfo,
                // Update with PDF-specific response data
                startDate: data.processInfo.startDate,
                endDate: data.processInfo.endDate,
                receiptsCount: data.processInfo.receiptsCount,
                duplicatesDeleted: data.processInfo.duplicatesDeleted,
                spreadsheetId: data.processInfo.spreadsheetId,
                lastProcessedDate: data.processInfo.lastProcessedDate,
                processingCompleted: data.processInfo.processingCompleted,
                totalPhotos: data.processInfo.totalPhotos,
                status: data.processInfo.status,
                nextSyncDate: data.processInfo.nextSyncDate,
                totalReceiptCount: data.processInfo.totalReceiptCount,
                unsubmittedReceiptCount:
                  data.processInfo.unsubmittedReceiptCount,
                lastSubmittedDate: data.processInfo.lastSubmittedDate,
                uploadProgress: 100, // Reset upload progress to 100% when complete
              }));
              setLastSynced(data.processInfo.endDate);
            } else {
              // Reset upload progress on failure
              setProcessInfo((prevInfo) => ({
                ...prevInfo,
                uploadProgress: 0,
              }));
              throw new Error(data.message || "Error processing PDF");
            }
          } else if (mediaItems[0]?.file instanceof File) {
            // This is your existing code for file uploads, keep it as is
            // Create FormData for PDF upload
            const formData = new FormData();

            // Loop through all selected PDF files and append each one
            mediaItems.forEach((item) => {
              if (item.type === "pdf" && item.file instanceof File) {
                formData.append("pdf", item.file);
              }
            });

            const response = await axios.post("/api/pdf-upload", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              // Add upload progress tracking
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                setProcessInfo((prevInfo) => ({
                  ...prevInfo,
                  uploadProgress: percentCompleted,
                  status: `Uploading PDFs: ${percentCompleted}%`,
                }));
              },
            });

            console.log("PDF upload response:", response.data);
            // Handle PDF response
            const data = response.data;

            if (data.success) {
              toast.success(
                `Successfully queued ${mediaItems.length} PDF file(s) for processing`
              );

              setProcessInfo((prevInfo) => ({
                ...prevInfo,
                // Update with PDF-specific response data
                startDate: data.processInfo.startDate,
                endDate: data.processInfo.endDate,
                receiptsCount: data.processInfo.receiptsCount,
                duplicatesDeleted: data.processInfo.duplicatesDeleted,
                spreadsheetId: data.processInfo.spreadsheetId,
                lastProcessedDate: data.processInfo.lastProcessedDate,
                processingCompleted: data.processInfo.processingCompleted,
                totalPhotos: data.processInfo.totalPhotos,
                status: data.processInfo.status,
                nextSyncDate: data.processInfo.nextSyncDate,
                totalReceiptCount: data.processInfo.totalReceiptCount,
                unsubmittedReceiptCount:
                  data.processInfo.unsubmittedReceiptCount,
                lastSubmittedDate: data.processInfo.lastSubmittedDate,
                uploadProgress: 100, // Reset upload progress to 100% when complete
              }));
              setLastSynced(data.processInfo.endDate);
            } else {
              // Reset upload progress on failure
              setProcessInfo((prevInfo) => ({
                ...prevInfo,
                uploadProgress: 0,
              }));
              throw new Error(data.message || "Error processing PDF");
            }
          } else {
            throw new Error("Invalid PDF format");
          }
        } else if (mediaItems[0]?.type === "receipt") {
          // Process receipt items using the dedicated receipt endpoint
          console.log("Processing receipt items:", mediaItems);

          const response = await axios.post(
            "/api/process-receipt",
            { userSelectedPhotos: mediaItems },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProcessInfo((prevInfo) => ({
                  ...prevInfo,
                  uploadProgress: percentCompleted,
                  status: `Processing receipts: ${percentCompleted}%`,
                }));
              },
            }
          );

          console.log("Receipt payload sent to server:", {
            userSelectedPhotos: mediaItems,
          });

          const data = response.data;

          if (data.success) {
            toast.success(
              `Successfully queued ${mediaItems.length} receipt(s) for processing`
            );

            // Initialize with basic information
            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              status: "Receipt processing initiated",
              processingCompleted: false,
              uploadProgress: 100,
            }));

            // If the API returns processInfo data, update with it
            if (data.processInfo) {
              setProcessInfo((prevInfo) => ({
                ...prevInfo,
                sessionId: data.processInfo.sessionId,
                status: data.processInfo.status || prevInfo.status,
                totalPhotos: data.processInfo.totalPhotos || mediaItems.length,
                ...(data.processInfo.startDate && {
                  startDate: data.processInfo.startDate,
                }),
                ...(data.processInfo.endDate && {
                  endDate: data.processInfo.endDate,
                  lastProcessedDate: data.processInfo.endDate,
                }),
                ...(data.processInfo.receiptsCount && {
                  receiptsCount: data.processInfo.receiptsCount,
                }),
                ...(data.processInfo.spreadsheetId && {
                  spreadsheetId: data.processInfo.spreadsheetId,
                }),
              }));

              if (data.processInfo.endDate) {
                setLastSynced(data.processInfo.endDate);
              }
            }
          } else {
            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              uploadProgress: 0,
              status: "Error processing receipts",
            }));
            console.error("Error processing receipts:", data.message);
            toast.error("Error processing receipts. Please try again.");
          }
        } else if (!mediaItems[0]?.baseUrl) {
          throw new Error("Invalid media item format");
        } else {
          // Existing photo processing logic
          const response = await axios.post(
            "/api/listPhotos",
            { userSelectedPhotos: mediaItems },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProcessInfo((prevInfo) => ({
                  ...prevInfo,
                  uploadProgress: percentCompleted,
                  status: `Uploading photos: ${percentCompleted}%`,
                }));
              },
            }
          );

          console.log("Payload sent to server:", {
            userSelectedPhotos: mediaItems,
          });

          const data = response.data;

          if (data.success) {
            toast.success(
              `Successfully queued ${mediaItems.length} photo(s) for processing`
            );

            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              startDate: data.processInfo.startDate,
              endDate: data.processInfo.endDate,
              receiptsCount: data.processInfo.receiptsCount,
              duplicatesDeleted: data.processInfo.duplicatesDeleted,
              spreadsheetId: data.processInfo.spreadsheetId,
              lastProcessedDate: data.processInfo.lastProcessedDate,
              processingCompleted: data.processInfo.processingCompleted,
              totalPhotos: data.processInfo.totalPhotos,
              status: data.processInfo.status,
              nextSyncDate: data.processInfo.nextSyncDate,
              totalReceiptCount: data.processInfo.totalReceiptCount,
              unsubmittedReceiptCount: data.processInfo.unsubmittedReceiptCount,
              lastSubmittedDate: data.processInfo.lastSubmittedDate,
              uploadProgress: 100,
            }));
            setLastSynced(data.processInfo.endDate);

            for (const item of mediaItems) {
              if (item.isOfflinePending) {
                await offlineStorage.markAsUploaded(item.id);
              }
            }
          } else {
            console.error("Error updating sync:", data.message);
            toast.error("Error updating sync. Please try again.");
          }
        }
      } catch (error) {
        console.error("Error processing items:", error);

        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          uploadProgress: 0,
          status: "Error uploading files",
        }));

        const mediaType = mediaItems[0]?.type === "pdf" ? "PDF" : "photo";

        if (error.response) {
          switch (error.response.status) {
            case 413:
              toast.error(
                `File size too large. Please use smaller ${mediaType} files (maximum 8MB per file).`,
                {
                  position: "top-center",
                  autoClose: 5000,
                }
              );
              break;
            case 403:
              toast.warning(
                "Your trial has expired. Please subscribe to continue using processing features.",
                {
                  position: "top-center",
                  autoClose: 5000,
                }
              );
              break;
            case 401:
              toast.error("Authentication failed. Please sign in again.", {
                position: "top-center",
                autoClose: 5000,
              });
              break;
            default:
              toast.error(
                `Error processing ${mediaType}(s). Please try again later.`,
                {
                  position: "top-center",
                  autoClose: 5000,
                }
              );
          }
        } else if (error.request) {
          toast.error(
            `Network error. Please check your internet connection and try again.`,
            {
              position: "top-center",
              autoClose: 5000,
            }
          );
        } else {
          toast.error(`Error processing ${mediaType}(s). Please try again.`, {
            position: "top-center",
            autoClose: 5000,
          });
        }

        lastRequestIdRef.current = null;
      } finally {
        isProcessingRef.current = false;
        resetGlobalLoadingState();
      }
    },
    [
      triggerGlobalLoadingState,
      setProcessInfo,
      resetGlobalLoadingState,
      setLastSynced,
    ]
  );

  // Add this function to check Gmail permissions
  const checkGmailPermissions = () => {
    // Show the Gmail processor modal immediately
    setShowGmailProcessor(true);

    // Then check permissions in the background
    axios
      .get("/api/gmail/check-permissions", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (!response.data.hasPermissions) {
          // If we don't have permissions, close the modal and redirect
          setShowGmailProcessor(false);
          // Set a flag in localStorage before redirecting
          localStorage.setItem("pendingGmailAuth", "true");
          // Redirect to get Gmail permissions
          window.location.href = "/api/auth/gmail";
        }
      })
      .catch((error) => {
        console.error("Error checking Gmail permissions:", error);
        toast.error("Unable to check Gmail permissions. Please try again.");
        setShowGmailProcessor(false);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, photoSelector: false }));
      });
  };

  const processOfflineQueue = useCallback(async () => {
    if (!isOnline) {
      console.log(
        "[ProcessInfo] processOfflineQueue called but we are offline; abort"
      );
      return;
    }

    // If already processing, exit early.
    if (queueProcessingRef.current) {
      console.log(
        "[ProcessInfo] Offline queue already processing, skipping..."
      );
      return;
    }

    queueProcessingRef.current = true;
    console.log("[ProcessInfo] Starting processOfflineQueue...");

    try {
      // 1) Retrieve stored photos from IndexedDB.
      const pendingUploads = await offlineStorage.getPendingUploads();
      console.log("[ProcessInfo] pendingUploads:", pendingUploads);

      if (pendingUploads.length === 0) {
        console.log("[ProcessInfo] No pending uploads found. Done.");
        return;
      }

      let successfulUploads = [];

      // 2) Attempt to upload each photo.
      for (const upload of pendingUploads) {
        try {
          console.log(
            "[ProcessInfo] Attempting to upload ID =",
            upload.id,
            "..."
          );
          const mediaItem = await uploadToGooglePhotos(upload.blob);
          console.log(
            "[ProcessInfo] Upload success, got mediaItem =",
            mediaItem
          );

          successfulUploads.push({
            id: upload.id,
            mediaItem,
          });
        } catch (error) {
          console.error(
            "[ProcessInfo] Failed to upload photo ID =",
            upload.id,
            error
          );
          // Optionally break if rate-limited:
          if (error.message && error.message.includes("Quota exceeded")) {
            break;
          }
        }
      }

      // 3) If any succeeded, process them on the server.
      if (successfulUploads.length > 0) {
        const itemsToProcess = successfulUploads.map(({ mediaItem }) => ({
          id: mediaItem.id,
          baseUrl: mediaItem.baseUrl,
          mimeType: "image/jpeg",
          filename: `Receipt-${Date.now()}.jpg`,
          mediaMetadata: {
            creationTime: new Date().toISOString(),
          },
        }));

        console.log(
          "[ProcessInfo] Calling handlePhotosSelected with:",
          itemsToProcess
        );
        await handlePhotosSelected(itemsToProcess);

        // 4) Mark successfully processed items as uploaded.
        for (const { id } of successfulUploads) {
          await offlineStorage.markAsUploaded(id);
        }
      } else {
        console.log("[ProcessInfo] No successful uploads to process. Done.");
      }
    } catch (error) {
      console.error("[ProcessInfo] processOfflineQueue error:", error);
    } finally {
      queueProcessingRef.current = false;
    }
  }, [isOnline, handlePhotosSelected]);

  // Listen for online/offline events.
  useEffect(() => {
    let timeoutId;

    const handleOnline = () => {
      console.log("[ProcessInfo] We are ONLINE, setting isOnline = true");
      setIsOnline(true);
      // Debounce processing by waiting a bit.
      timeoutId = setTimeout(() => {
        processOfflineQueue();
      }, 2000);
    };

    const handleOffline = () => {
      console.log("[ProcessInfo] We are OFFLINE, setting isOnline = false");
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [processOfflineQueue]); // Now we can safely add processOfflineQueue

  // Optionally, process the offline queue once on mount if online.
  useEffect(() => {
    if (isOnline) {
      processOfflineQueue();
    }
  }, [isOnline, processOfflineQueue]);

  // (Optionally remove cleanupStorage if not needed)
  useEffect(() => {
    const cleanupStorage = async () => {
      if (navigator.onLine) {
        try {
          const pendingUploads = await offlineStorage.getPendingUploads();
          if (pendingUploads.length === 0) {
            // This might be unnecessary unless you want to force a DB reinit.
            await offlineStorage.initDB();
          }
        } catch (error) {
          console.error("Storage cleanup error:", error);
        }
      }
    };

    cleanupStorage();
  }, []);

  useEffect(() => {
    // Reset to page 1 whenever filter changes
    setPage(1);

    // If reports have already been fetched at least once, trigger a new fetch with the filter
    if (reportsFetched) {
      fetchReports(1);
    }
  }, [reportFilter, fetchReports, reportsFetched]);

  const handlePhotoSelectorError = (errorMessage) => {
    // Replace alert with toast
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
    });
    resetGlobalLoadingState();
  };

  const handleDateRangeSelected = async (startDate, endDate) => {
    setIsDateRangeSelectorOpen(false);
    triggerGlobalLoadingState();

    const token = localStorage.getItem("token");
    if (!token) {
      resetGlobalLoadingState(); // Reset if there's no token
      return;
    }

    // Manually set processingCompleted to false to trigger the processing message
    setProcessInfo((prevInfo) => ({
      ...prevInfo,
      processingCompleted: false,
    }));

    try {
      const response = await axios.get(`/api/listPhotos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate,
          endDate,
        },
      });

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,

          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          unsubmittedReceiptCount: data.processInfo.unsubmittedReceiptCount,

          lastSubmittedDate: data.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
      }
    } catch (error) {
      console.error(
        "Error updating sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // Do not reset the loading state here. Let the effect hook handle it.
    }
  };

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(0);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);

  // Single QuickBooks initialization effect
  useEffect(() => {
    if (
      !isAccountantView ||
      !processInfo?.reports?.length ||
      quickBooksInitialized
    ) {
      return;
    }

    const initializeQuickBooks = async () => {
      try {
        const response = await fetch("/api/quickbooks/status", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to check status");
        }

        const status = await response.json();
        setQuickbooksConnectionStatus(status?.connected || false);
        setQuickBooksInitialized(true);
      } catch (err) {
        console.error("Error checking QB status:", err);
        setQuickbooksConnectionStatus(false);
        setQuickBooksInitialized(true);
      }
    };

    initializeQuickBooks();
  }, [isAccountantView, processInfo?.reports, quickBooksInitialized]); // Include quickBooksInitialized

  // Reset QuickBooks initialization when switching views
  useEffect(() => {
    setQuickBooksInitialized(false);
  }, [isAccountantView]);

  useEffect(() => {
    // Skip token processing for accountant view
    if (isAccountantView) {
      return;
    }

    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      updateSubscriptionStateFromToken(decoded);
    }

    // Storage event listener
    const handleStorageChange = () => {
      const updatedToken = localStorage.getItem("token");
      if (updatedToken) {
        const decoded = jwtDecode(updatedToken);
        updateSubscriptionStateFromToken(decoded);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [isAccountantView]);

  /*
  const handlePreviewSubmission = async () => {
    setSubmitResultMessage("");
    setShowPreview(false);
    setDataLoading(true);

    try {
      const response = await axios.get("/api/getReportData", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      const { reportData, functionalTotal, homeCurrency, hasEmptyColumnR } =
        response.data;

      setFormattedReportData(reportData);

      if (reportData.length > 0) {
        setIsModalOpen(true);
        setNoOutstandingExpenses(false);
      } else {
        setNoOutstandingExpenses(true);
      }

      // Store functional total and home currency and hasEmptyColumnR
      setHasEmptyColumnR(hasEmptyColumnR);
      setReportFunctionalTotal(functionalTotal);
      setReportHomeCurrency(homeCurrency);
    } catch (error) {
      console.error("Error processing preview submission:", error.message);
    } finally {
      setDataLoading(false);
    }
  };
*/

  /*
  const handleSubmit = async () => {
    setSubmitLoading(true);
    setSubmitResultMessage("");

    try {
      const response = await axios.post(
        `/api/getReportData`,
        { action: "submit" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      // Extract all variables including homeCurrency
      const {
        message,
        submissionDate,
        title,
        link,
        reportId,
        receiptCount,
        functionalTotal,
        homeCurrency, // Add this
      } = response.data;

      if (response.status === 200) {
        // Create new report with the proper structure, including homeCurrency
        const batchNumber = `batch-${Date.now()}`;
        const newReport = {
          batchNumber,
          link,
          reportId,
          submissionDate,
          title: title || `Expense Report - ${batchNumber}`,
          quickbooks: {
            error: null,
            status: "not_submitted",
            submittedAt: null,
            transactionId: null,
          },
          receiptCount,
          functionalTotal,
          homeCurrency, // Add this
        };

        // Update processInfo by adding the new report at the beginning of the array
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          lastSubmittedDate: submissionDate,
          reports: [newReport, ...(prevInfo.reports || [])],
        }));
        // Refresh reports from first page
        setPage(1);
        await fetchReports(1);

        setSubmitResultMessage(message || "Report submitted successfully!");
      } else {
        setSubmitResultMessage(
          message || "An error occurred while submitting the report."
        );
      }
    } catch (error) {
      const serverMessage =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      setSubmitResultMessage(serverMessage);
    } finally {
      setFormattedReportData([]);
      setShowPreview(false);
      setSubmitLoading(false);
      setIsModalOpen(false);
    }
  };

  */

  useEffect(() => {
    const hideWelcomeModal = localStorage.getItem("hideWelcomeModal");
    if (!hideWelcomeModal) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    // When processingCompleted becomes true, reset loading states for the buttons
    if (processInfo.processingCompleted) {
      resetGlobalLoadingState();
    }
  }, [processInfo?.processingCompleted, resetGlobalLoadingState]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // At the top level of ProcessInfo.js, just under your other functions:

  const handleManagePolicies = () => {
    setShowComplianceDocs(true);
  };

  const confirmCancelSubscription = async () => {
    setLoading((prev) => ({ ...prev, cancelSubscription: true }));

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        resetGlobalLoadingState();
        return;
      }

      console.log("Sending cancel subscription request...");

      // Send the request to cancel the subscription
      const response = await axios.post(
        "/api/cancel-subscription",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log("Cancel subscription response:", response.data);

      const { newToken } = response.data;

      localStorage.setItem("token", newToken); // Save the new token
      const decoded = jwtDecode(newToken);

      // Update the state with the new subscription info
      updateSubscriptionStateFromToken(decoded);
      setUser(decoded); // Update the user state
    } catch (error) {
      console.error("Error canceling subscription:", error.message);
    } finally {
      setLoading((prev) => ({ ...prev, cancelSubscription: false }));
    }
  };

  useEffect(() => {
    if (processInfo?.automaticSyncEnabled) {
      setAutoSyncEnabled(processInfo.automaticSyncEnabled);
    }
  }, [processInfo?.automaticSyncEnabled]);

  useEffect(() => {
    if (isAccountantView) {
      return;
    }

    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }

    if (!processInfo?.processingCompleted) {
      const pollProcessingStatus = async () => {
        try {
          const response = await axios.get(`/api/getProcessState`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.data.success) {
            setProcessInfo((prevInfo) => ({
              ...prevInfo,
              ...response.data.processInfo,
            }));
            setLastSynced(response.data.processInfo.endDate);

            if (response.data.processInfo.processingCompleted) {
              clearInterval(pollIntervalRef.current);
              pollIntervalRef.current = null;
            }
          }
        } catch (error) {
          console.error("Error polling process state:", error);
          clearInterval(pollIntervalRef.current);
          pollIntervalRef.current = null;
        }
      };

      const timeoutId = setTimeout(() => {
        pollProcessingStatus();
        pollIntervalRef.current = setInterval(pollProcessingStatus, 30000);
      }, 30000);

      return () => {
        clearTimeout(timeoutId);
        if (pollIntervalRef.current) {
          clearInterval(pollIntervalRef.current);
          pollIntervalRef.current = null;
        }
      };
    }
  }, [
    isAccountantView,
    processInfo?.processingCompleted,
    setProcessInfo,
    setLastSynced,
  ]);

  /*
  useEffect(() => {
    if (!processInfo?.processingCompleted) {
      triggerGlobalLoadingState();
    }
  }, [processInfo, triggerGlobalLoadingState]);
*/
  const handleUpdateSync = async () => {
    triggerGlobalLoadingState();

    const token = localStorage.getItem("token");
    if (!token) {
      resetGlobalLoadingState();
      return;
    }

    if (!processInfo || !processInfo.endDate) {
      resetGlobalLoadingState();
      return;
    }

    try {
      // Manually set processingCompleted to false to trigger the processing message
      setProcessInfo((prevInfo) => ({
        ...prevInfo,
        processingCompleted: false,
      }));

      const response = await axios.get(
        `/api/listPhotos?startDate=${processInfo.endDate}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;

      if (data.success) {
        setProcessInfo((prevInfo) => ({
          ...prevInfo,
          startDate: data.processInfo.startDate,
          endDate: data.processInfo.endDate,
          receiptsCount: data.processInfo.receiptsCount,
          duplicatesDeleted: data.processInfo.duplicatesDeleted,
          spreadsheetId: data.processInfo.spreadsheetId,

          lastProcessedDate: data.processInfo.lastProcessedDate,
          processingCompleted: data.processInfo.processingCompleted,
          totalPhotos: data.processInfo.totalPhotos,
          status: data.processInfo.status,
          nextSyncDate: data.processInfo.nextSyncDate,
          totalReceiptCount: data.processInfo.totalReceiptCount,
          unsubmittedReceiptCount: data.processInfo.unsubmittedReceiptCount,

          lastSubmittedDate: data.processInfo.lastSubmittedDate,
        }));
        setLastSynced(data.processInfo.endDate);
      } else {
        console.error("Error updating sync:", data.message);
      }
    } catch (error) {
      console.error(
        "Error updating sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      // Do not reset the loading state here. Let the effect hook handle it.
    }
  };

  const handleToggleAutoSync = async () => {
    setLoading({ ...loading, autoSync: true });
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "/api/toggle-auto-sync",
        { autoSyncEnabled: !autoSyncEnabled },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setAutoSyncEnabled(!autoSyncEnabled);
      } else {
        console.error("Error toggling auto-sync:", response.data.message);
      }
    } catch (error) {
      console.error(
        "Error toggling auto-sync:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading({ ...loading, autoSync: false });
    }
  };

  // In ProcessInfo.js, modify the handleReset function
  const handleReset = async () => {
    setResetError(null); // Clear any previous errors

    if (
      !window.confirm(
        "Are you sure you want to delete your account, your Google Drive with your receipts, and your spreadsheet with your expenses? This action cannot be undone."
      )
    ) {
      return;
    }

    setLoading((prev) => ({ ...prev, reset: true }));

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.post(
        "/api/reset-account",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle unsuccessful response even if no error thrown
      if (response.data?.success === false) {
        throw new Error(response.data.message || "Reset failed");
      }

      // Optionally show a success toast here
      // toast.success("Account successfully reset!");

      // Successfully reset - clear storage and redirect
      console.log(
        "Account successfully reset, clearing all local storage data"
      );

      // Clear ALL localStorage items
      localStorage.clear();

      // Clear any IndexedDB storage if being used
      if (window.indexedDB) {
        // List of known DBs to clear
        const dbsToDelete = ["offlineStorage", "expensebot-cache"];

        dbsToDelete.forEach((dbName) => {
          try {
            indexedDB.deleteDatabase(dbName);
            console.log(`IndexedDB ${dbName} deleted`);
          } catch (e) {
            console.log(`Error deleting IndexedDB ${dbName}:`, e);
          }
        });
      }

      // Force reload to clear any in-memory state
      window.location.href = "/";
    } catch (error) {
      console.error("Error resetting account:", error);
      let errorMessage = "Failed to reset account. ";

      if (error.response) {
        // Handle specific HTTP error responses
        switch (error.response.status) {
          case 401:
            errorMessage += "Please log in again.";
            localStorage.clear();
            setTimeout(() => (window.location.href = "/login"), 2000);
            break;

          case 403:
            // Extract file ID from Google Drive permission error
            const childFileMatch = error.response?.data?.error?.message?.match(
              /child file ([a-zA-Z0-9_-]+)/
            );
            const childFileId = childFileMatch ? childFileMatch[1] : null;

            if (childFileId) {
              const driveFileLink = `https://drive.google.com/file/d/${childFileId}/view`;
              errorMessage += `Permission denied. There's a file that needs additional permissions. `;
              errorMessage += `Please check this file in your Google Drive: ${driveFileLink}`;
            } else {
              errorMessage +=
                "Permission denied. Some files couldn't be deleted due to permission issues.";
            }
            break;

          case 404:
            errorMessage += "Account not found.";
            break;

          case 429:
            errorMessage +=
              "Too many requests. Please try again in a few minutes.";
            break;

          case 500:
            errorMessage += "Server error. Please try again later.";
            break;

          default:
            errorMessage +=
              error.response.data?.message ||
              "An unexpected error occurred. Please try again.";
        }
      } else if (error.request) {
        // Handle network errors (no response received)
        errorMessage +=
          "Network error. Please check your connection and try again.";
      } else {
        // Handle all other errors
        errorMessage += error.message || "An unknown error occurred.";
      }

      setResetError(errorMessage);

      // Replace mileage notification state update with a toast notification
      toast.error(errorMessage, { autoClose: 5000 });
    } finally {
      setLoading((prev) => ({ ...prev, reset: false }));
    }
  };

  //Diagnostic logging
  useEffect(() => {
    console.log(`Loading state changed: ${loading.reports ? "TRUE" : "FALSE"}`);
  }, [loading.reports]);

  const handleLogout = () => {
    setLoading({ ...loading, logout: true });
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  /* 
  const openSpreadsheet = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gId}`,
      "_blank"
    );
  };

  const openSpreadsheetBilling = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidBilling}`,
      "_blank"
    );
  };

  const openSpreadsheetExpenses = () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit`,
      "_blank"
    );
  };
*/
  /*
  const renderAllExpenses = () => (
    <div className="info-container">
      {processInfo.error && (
        <div
          className="error-message"
          style={{
            color: "#dc3545",
            marginTop: "10px",
            padding: "10px",
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#fff8f8",
            borderRadius: "4px",
            borderLeft: "4px solid #dc3545",
          }}
        >
          <i className="fas fa-exclamation-circle"></i>
          {processInfo.error}
        </div>
      )}
      <PhotoSelectionContainer
        handlePhotoSelector={handlePhotoSelector}
        processing={processing}
        loading={loading}
        user={user}
        setMileageModalOpen={setMileageModalOpen}
        mileageNotification={mileageNotification}
        SHOW_ICLOUD_BUTTON={SHOW_ICLOUD_BUTTON}
      />

      <div className="section-separator"></div>

      <div className="summary-header-container">
        <h3 className="summary-title">View Expenses</h3>
        <button
          onClick={openSpreadsheetExpenses}
          className="spreadsheet-view-button"
        >
          <i className="fas fa-table"></i>
          View Expense Spreadsheet
        </button>
      </div>
      <div className="summary-section">
        <div className="summary-item">
          <p className="label">Unsubmitted Receipts</p>
          <p className="value">{processInfo.unsubmittedReceiptCount ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">Last Sync</p>
          <p className="value">
            {processInfo.lastProcessedDate
              ? new Date(processInfo.lastProcessedDate).toLocaleDateString()
              : ""}
          </p>
        </div>

        <div className="summary-item">
          <p className="label">Recent Receipts Found</p>
          <p className="value">{processInfo.receiptsCount ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">Recent Duplicates Deleted</p>
          <p className="value">{processInfo.duplicatesDeleted ?? 0}</p>
        </div>
        <div className="summary-item">
          <p className="label">
            Expense Accounts
            <div
              className="info-tooltip-container"
              style={{ marginLeft: "4px" }}
            >
              <i className="fas fa-info-circle info-icon"></i>
              <div className="info-tooltip">
                <p>Auto-classify your expenses into categories.</p>
                <br />
                <div className="info-steps">
                  <p>
                    <i className="fas fa-edit"></i>
                    Edit them directly in the spreadsheet.
                  </p>
                </div>
              </div>
            </div>
          </p>
          <p className="value">{processInfo.expenseCategoryCount}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheet}>
              Edit
            </button>
          )}
        </div>

        <div className="summary-item">
          <p className="label">
            Tags
            <div
              className="info-tooltip-container"
              style={{ marginLeft: "4px" }}
            >
              <i className="fas fa-info-circle info-icon"></i>
              <div className="info-tooltip">
                <p>Tags help you group expenses by business trip or client.</p>
                <br />
                <div className="info-steps">
                  <p>
                    <i className="fas fa-list"></i>
                    Edit Tags directly in the spreadsheet.
                  </p>
                  <br />
                </div>
                <p className="info-note">
                  <i className="fas fa-lightbulb"></i> Tip: Before you take a
                  photo, write the tag name on your receipt.
                </p>
              </div>
            </div>
          </p>
          <p className="value">{processInfo.reportCategoryCount}</p>
          {processInfo.spreadsheetId && (
            <button className="text-button" onClick={openSpreadsheetBilling}>
              Edit
            </button>
          )}
        </div>
      </div>
    </div>
  ); */

  const renderAddExpenses = () => (
    <div className="info-container">
      {processInfo.error && (
        <div
          className="error-message"
          style={{
            color: "#dc3545",
            marginTop: "10px",
            padding: "10px",
            fontSize: "0.9rem",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            backgroundColor: "#fff8f8",
            borderRadius: "4px",
            borderLeft: "4px solid #dc3545",
          }}
        >
          <i className="fas fa-exclamation-circle"></i>
          {processInfo.error}
        </div>
      )}
      {!isOnline && (
        <div className="bg-yellow-50 text-yellow-800 px-4 py-2 text-sm">
          You're offline - Photos will sync when back online
        </div>
      )}
      <PhotoSelectionContainer
        handlePhotoSelector={handlePhotoSelector}
        processing={processing}
        loading={loading}
        user={user}
        setMileageModalOpen={setMileageModalOpen}
        SHOW_ICLOUD_BUTTON={SHOW_ICLOUD_BUTTON}
        uploadProgress={processInfo.uploadProgress}
        onManageTags={() => setShowTagManagementModal(true)}
      />
    </div>
  );

  const renderAlwaysVisibleSection = () => {
    return (
      <div className="master-data-section">
        {/* View Spreadsheet Button - Secondary action */}
        <a
          href={`https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit`}
          target="_blank"
          rel="noopener noreferrer"
          className="view-spreadsheet-button"
        >
          <span className="button-text">View Spreadsheet</span>
          <i className="fas fa-table button-icon"></i>
        </a>
        <p className="action-description">
          Open your complete expense spreadsheet
        </p>

        {/* Unified combined button approach with responsive label */}
        <div className="report-action-container">
          <button
            className="create-report-button combined"
            onClick={handlePreviewClick}
          >
            <div className="button-left-content">
              <span className="button-text">Create a Report</span>
              <i className="fas fa-file-alt button-icon"></i>
            </div>
            {processInfo.unsubmittedReceiptCount > 0 && (
              <div className="receipt-counter">
                {showLabel && (
                  <span className="receipt-label">Unsubmitted:</span>
                )}
                <span className="counter-badge">
                  {processInfo.unsubmittedReceiptCount ?? 0}
                </span>
              </div>
            )}
          </button>
        </div>

        {/* Full-width description */}
        <p className="full-width-description">
          Group unsubmitted expenses into a new report.
        </p>
      </div>
    );
  };

  // This code should replace the settings menu section in your ProcessInfo.js file
  /*
  const ComplianceDocumentsModal = ({ onClose }) => {
    // Keep track of if we've done the initial load
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    // After first render, mark initial load as complete
    useEffect(() => {
      setInitialLoadComplete(true);
    }, []);

    return (
      <div className="modal" onClick={onClose}>
        <div
          className="modal-content document-management-section"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <h3>Compliance Documents</h3>
            <button onClick={onClose} className="close-button">
              &times;
            </button>
          </div>

          <ComplianceProvider>
            <ComplianceDocuments
              isActive={true}
              userEmail={user.email}
              skipRefreshes={initialLoadComplete}
            />
          </ComplianceProvider>
        </div>
      </div>
    );
  };
  */

  const renderSettings = () => {
    return (
      <div className="settings-container">
        <h3 className="settings-title">Settings</h3>

        {/* Highlighted primary settings cards */}
        <div className="settings-cards-grid">
          <div className="settings-card">
            <div className="settings-card-header">
              <i className="fas fa-list-ul settings-card-icon"></i>
              <h4 className="settings-card-title">Expense Categories</h4>
            </div>
            <p className="settings-card-description">
              Add, edit, or delete expense categories that ExpenseBot uses to
              automatically classify your expenses from receipts.
            </p>
            <button
              onClick={() => setShowExpenseCategoryModal(true)}
              className="settings-card-link"
            >
              Manage Expense Categories
              <i className="fas fa-arrow-right settings-link-icon"></i>
            </button>
          </div>

          <div className="settings-card">
            <div className="settings-card-header">
              <i className="fas fa-tags settings-card-icon"></i>
              <h4 className="settings-card-title">Tags</h4>
            </div>
            <p className="settings-card-description">
              Manage tags that help you group expenses by project, client,
              business trip, or any other custom category for reporting and
              reimbursement.
            </p>
            <button
              className="settings-card-link"
              onClick={() => setShowTagManagementModal(true)}
            >
              Manage Tags
              <i className="fas fa-arrow-right settings-link-icon"></i>
            </button>
          </div>

          <div className="settings-card">
            <div className="settings-card-header">
              <i className="fas fa-cog settings-card-icon"></i>
              <h4 className="settings-card-title">General Settings</h4>
            </div>
            <p className="settings-card-description">
              Configure app-wide settings including accounting package
              integrations, mileage rates, currency preferences, and more.
            </p>
            <a
              href={`https://docs.google.com/spreadsheets/d/${processInfo.spreadsheetId}/edit#gid=${processInfo.gidSettings}`}
              target="_blank"
              rel="noopener noreferrer"
              className="settings-card-link"
            >
              Edit Settings
              <i className="fas fa-external-link-alt settings-link-icon"></i>
            </a>
          </div>

          {/* New Compliance Documents Card */}
          <div className="settings-card">
            <div className="settings-card-header">
              <i className="fas fa-file-alt settings-card-icon"></i>
              <h4 className="settings-card-title">Compliance Documents</h4>
            </div>
            <p className="settings-card-description">
              Add your company's expense policies so AI can check expense
              reports against them.
            </p>
            <button
              onClick={() => setShowComplianceDocs(true)}
              className="settings-card-link"
            >
              View Compliance Documents
              <i className="fas fa-arrow-right settings-link-icon"></i>
            </button>
          </div>
        </div>

        {/*
        <div className="settings-section">
          <h4 className="settings-section-title">App Options</h4>
          <div className="settings-options-grid">
            <div className="settings-option-item">
              <span className="settings-option-label">Review & Approve</span>
              <div className="settings-option-control">
                {loading.reviewerToggle ? (
                  <TailSpin color="#0033a0" height={24} width={24} />
                ) : (
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={user?.roles?.accountant || false}
                      onChange={handleReviewerToggle}
                      disabled={loading.reviewerToggle}
                    />
                    <span className="slider round"></span>
                  </label>
                )}
              </div>
              <p className="settings-option-description">
                Enable to review and approve expenses for your organization
              </p>
            </div>

          </div>
        </div>
        */}

        {/* Danger zone section */}
        <div className="settings-section settings-section-danger">
          <h4 className="settings-section-title">Account Management</h4>
          <div className="settings-danger-grid">
            <div className="settings-danger-item">
              <div className="settings-danger-content">
                <span className="settings-danger-label">Reset Account</span>
                <p className="settings-danger-description">
                  This will delete your account, Google Drive with receipts, and
                  spreadsheet with all expenses.
                  <strong> This action cannot be undone.</strong>
                </p>
              </div>
              <div className="settings-danger-control">
                <button
                  onClick={handleReset}
                  className="settings-danger-button"
                  disabled={loading.reset}
                >
                  {loading.reset ? (
                    <TailSpin color="#ffffff" height={20} width={20} />
                  ) : (
                    "Reset Account"
                  )}
                </button>
              </div>
            </div>
            {resetError && (
              <div className="settings-error-message">
                {resetError.includes("drive.google.com") ? (
                  <span>
                    {resetError.split("https://")[0]}
                    <a
                      href={`https://${resetError.split("https://")[1]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="settings-error-link"
                    >
                      View file in Google Drive
                    </a>
                  </span>
                ) : (
                  resetError
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // For the renderPreviouslySubmittedReports function:
  const renderPreviouslySubmittedReports = () => {
    // More robust checking for reports
    const reportsArray = Array.isArray(processInfo?.reports)
      ? processInfo.reports
      : [];
    const hasReports = reportsArray.length > 0;

    console.log(
      `Rendering reports. Loading: ${loading.reports}, Reports array: ${
        Array.isArray(processInfo?.reports) ? "valid array" : "not an array"
      }, Count: ${reportsArray.length}, ReportsFetched: ${reportsFetched}`
    );

    return (
      <div className="reports-display-section">
        <div className="reports-header">
          <div className="reports-title-section">
            <h3 className="expense-report-title">Your Reports</h3>
          </div>

          <div className="filter-controls">
            {/* Conditionally render ExpensePolicyStatus without using hooks */}
            {user?.roles?.investmentManager && (
              <ExpensePolicyStatus
                clientEmail={user.email}
                investmentManager={user?.roles?.investmentManager}
                onManagePolicies={handleManagePolicies}
              />
            )}

            <select
              value={reportFilter}
              onChange={(e) => setReportFilter(e.target.value)}
              className="status-select"
            >
              <option value="All">All Reports</option>
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
            </select>
          </div>
        </div>

        {/* Reports Content */}
        <div className="reports-content">
          {loading.reports ? (
            <div className="loading-state">
              <TailSpin height="24" width="24" color="#0033a0" />
            </div>
          ) : !reportsFetched ? (
            // Add this case to check if reports have ever been fetched
            <div className="loading-state">
              <TailSpin height="24" width="24" color="#0033a0" />
            </div>
          ) : hasReports ? (
            <div>
              {reportsArray
                .filter((report) => {
                  if (reportFilter === "All") return true;
                  if (reportFilter === "Paid") return report.status === "Paid";
                  return report.status !== "Paid"; // Unpaid
                })
                .map((report, index) => {
                  if (!report || !report.submissionDate || !report.link) {
                    console.log("Skipping invalid report:", report);
                    return null;
                  }

                  return (
                    <div key={index} className="report-card">
                      {/* The rest of your report card implementation remains unchanged */}
                      {/* Row1: date alone on desktop, or date + title on mobile */}
                      <div className="report-header">
                        {/* Your existing implementation... */}
                        <div className="report-meta">
                          <div className="date-block">
                            {new Date(
                              report.submissionDate
                            ).toLocaleDateString()}
                          </div>

                          {/* Desktop title */}
                          <div className="title-left">
                            <a
                              href={report.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {report.title || "Untitled Report"}
                            </a>
                          </div>

                          {/* Mobile title - shown only on mobile */}
                          <div className="mobile-title">
                            <a
                              href={report.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {report.title || "Untitled Report"}
                            </a>
                          </div>

                          {/* Report Type Badge - Only shown if reportMetadata exists */}
                          {report.reportMetadata?.reportType && (
                            <div
                              className={`report-type-badge ${report.reportMetadata.reportType}`}
                            >
                              <i
                                className={`fas ${
                                  report.reportMetadata.reportType ===
                                  "receipt_report"
                                    ? "fa-receipt"
                                    : "fa-file-invoice"
                                }`}
                              ></i>
                              <span>
                                {report.reportMetadata.reportType ===
                                "receipt_report"
                                  ? "Receipts"
                                  : "Invoices"}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="controls-right">
                          <select
                            value={report.status || "Submitted"}
                            onChange={(e) =>
                              handleStatusUpdate(
                                report.reportId,
                                e.target.value
                              )
                            }
                            className="status-select"
                            title={report.status || "Submitted"}
                          >
                            <option value="Submitted">Submitted</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Paid">Paid</option>
                          </select>

                          <button
                            className="action-button"
                            onClick={() => {
                              if (editingReportId === report.reportId) {
                                setEditingReportId(null);
                              } else {
                                setEditingReportId(report.reportId);
                                setEditedNote(report.notes || "");
                              }
                            }}
                            title={report.notes ? "Edit note" : "Add note"}
                          >
                            <i className="fas fa-sticky-note"></i>
                            {report.notes && report.notes.trim() !== "" && (
                              <span className="note-indicator"></span>
                            )}
                          </button>

                          <button
                            className="action-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEmailManagement(report);
                            }}
                            title="Manage report recipients"
                          >
                            <i className="fas fa-envelope"></i>
                            {report.sentTo?.length > 0 && (
                              <span className="recipient-count">
                                {report.sentTo.length}
                              </span>
                            )}
                          </button>

                          <button
                            className="action-button"
                            onClick={() => handleDeleteReport(report.reportId)}
                            title="Delete report"
                            disabled={loading.deleteReport === report.reportId}
                          >
                            {loading.deleteReport === report.reportId ? (
                              <TailSpin
                                color="#dc3545"
                                height={16}
                                width={16}
                              />
                            ) : (
                              <i className="fas fa-trash-alt"></i>
                            )}
                          </button>
                          {/* Add this inside the controls-right div, after the other buttons */}
                          <div
                            ref={(el) =>
                              (downloadRefs.current[report.reportId] = el)
                            }
                            className="download-dropdown"
                          >
                            <button
                              className="action-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDownloadingReportId(
                                  downloadingReportId === report.reportId
                                    ? null
                                    : report.reportId
                                );
                              }}
                              title="Download report"
                            >
                              <i className="fas fa-download"></i>
                            </button>

                            {downloadingReportId === report.reportId && (
                              <div className="download-menu">
                                <button
                                  onClick={() =>
                                    handleDownload(report.reportId, "csv")
                                  }
                                >
                                  CSV
                                </button>
                                <button
                                  onClick={() =>
                                    handleDownload(report.reportId, "xlsx")
                                  }
                                >
                                  Excel
                                </button>
                                <button
                                  onClick={() =>
                                    handleDownload(report.reportId, "pdf")
                                  }
                                >
                                  PDF
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Row3: Receipts & Func Total on the same line */}
                      <div className="row3">
                        <div className="metric-left">
                          <span className="label">Receipts:</span>
                          <span className="value">
                            {report.receiptCount || 0}
                          </span>
                        </div>

                        {report.functionalTotal > 0 && (
                          <div className="metric-right">
                            <span className="label">Total:</span>
                            <span className="value currency">
                              {" "}
                              {report.homeCurrency
                                ? formatCurrency(
                                    report.functionalTotal || 0,
                                    report.homeCurrency
                                  )
                                : (report.functionalTotal || 0).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </span>
                          </div>
                        )}
                      </div>

                      {/* Row4: QuickBooks, Wave, Compliance */}
                      {user?.roles?.type !== "I-Basic" && (
                        <div className="row4">
                          <div className="integration-container">
                            <ShareToQuickBooks
                              reportId={report.reportId}
                              spreadsheetId={processInfo.spreadsheetId}
                              quickbooks={report.quickbooks}
                              isConnected={quickbooksConnectionStatus}
                              reportMetadata={report.reportMetadata} // So we can make decisions what kind of integration
                              onStatusUpdate={(newStatus) => {
                                setProcessInfo((prev) => ({
                                  ...prev,
                                  reports: prev.reports.map((r) =>
                                    r.reportId === report.reportId
                                      ? {
                                          ...r,
                                          quickbooks: {
                                            ...r.quickbooks,
                                            ...newStatus,
                                          },
                                          functionalTotal:
                                            newStatus.functionalTotal,
                                        }
                                      : r
                                  ),
                                }));
                              }}
                              onConnectionStatusChange={
                                setQuickbooksConnectionStatus
                              }
                              isClientOfAccountant={isAccountantView}
                              clientEmail={
                                isAccountantView ? clientEmail : undefined
                              }
                            />
                          </div>
                          {SHOW_WAVE_BUTTON && (
                            <div className="integration-container">
                              <ShareToWave
                                reportId={report.reportId}
                                spreadsheetId={processInfo.spreadsheetId}
                                wave={report.wave}
                                isConnected={waveConnectionStatus}
                                onStatusUpdate={(newStatus) => {
                                  setProcessInfo((prev) => ({
                                    ...prev,
                                    reports: prev.reports.map((r) =>
                                      r.reportId === report.reportId
                                        ? {
                                            ...r,
                                            wave: {
                                              ...r.wave,
                                              ...newStatus,
                                            },
                                            functionalTotal:
                                              newStatus.functionalTotal,
                                          }
                                        : r
                                    ),
                                  }));
                                }}
                                onConnectionStatusChange={
                                  setWaveConnectionStatus
                                }
                                isClientOfAccountant={isAccountantView}
                                clientEmail={
                                  isAccountantView ? clientEmail : undefined
                                }
                              />
                            </div>
                          )}
                          <div className="compliance-check-wrapper">
                            <ComplianceCheck
                              report={{ ...report, id: report.reportId }}
                              spreadsheetId={processInfo.spreadsheetId}
                              clientEmail={user.email}
                              onStatusUpdate={(complianceData) => {
                                setProcessInfo((prev) => ({
                                  ...prev,
                                  reports: prev.reports.map((r) =>
                                    r.reportId === report.reportId
                                      ? { ...r, compliance: complianceData }
                                      : r
                                  ),
                                }));
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty-state">
              <p className="empty-state-message">
                No previously submitted reports available.
              </p>
            </div>
          )}

          {reportsArray.length > 0 && totalPages > 1 && (
            <div className="custom-pagination-controls">
              <button
                onClick={() => setPage((p) => Math.max(1, p - 1))}
                disabled={page === 1 || loading.reports}
                className="custom-pagination-button"
              >
                <i className="fas fa-chevron-left"></i>
              </button>
              <span className="custom-pagination-text">
                Page {page} of {totalPages}
              </span>
              <button
                onClick={() => setPage((p) => p + 1)}
                disabled={page === totalPages || loading.reports}
                className="custom-pagination-button"
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  /*
  const DisplayReport = ({ reportData, handleSubmit }) => (
    <div className="report-container">
      {reportData.map(({ currency, accounts, totalCurrency }) => (
        <div key={currency} className="currency-section">
          <h3>{currency}</h3>
          {accounts.map(({ account, total }) => (
            <div className="total-item" key={account}>
              <p className="total-label">{account}</p>
              <p className="total-value">${total.toFixed(2)}</p>
            </div>
          ))}
          <div className="total-item">
            <p className="total-label">
              <strong>Total {currency}:</strong>
            </p>
            <p className="total-value">${totalCurrency.toFixed(2)}</p>
          </div>
        </div>
      ))}

    
      <div className="submit-review-section">
        {submitLoading ? (
          <div className="loading-container">
            <TailSpin color="#0033a0" height={5} width={5} />
            <p>Submitting report, please wait...</p>
          </div>
        ) : (
          <button
            className="submit-review-button primary-button button-width"
            onClick={handleSubmit}
            disabled={submitLoading} // Disable the button while submitting
          >
            Submit
          </button>
        )}
      </div>
    </div>
  ); */

  const formatCurrency = (amount, currency) => {
    // Fallback if amount is undefined, null, or NaN
    if (isNaN(amount)) {
      amount = 0;
    }

    const localeOptions = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    // If no currency is provided, just return a decimal string with 2 decimals
    if (!currency) {
      return amount.toLocaleString(undefined, localeOptions);
    }

    // Map currency codes to symbols
    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
      // Add more currencies as needed
    };

    const symbol = currencyMap[currency] || currency;

    // Format with 2 decimals
    const formattedAmount = amount.toLocaleString(undefined, localeOptions);

    // If we have a known symbol, prepend it, else append
    if (currencyMap[currency]) {
      return `${symbol}${formattedAmount}`;
    } else {
      return `${formattedAmount} ${symbol}`;
    }
  };

  const formatRelativeTime = (date) => {
    const now = new Date();
    const diff = date - now;
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (diffHours < 0 || diffMinutes < 0) {
      return "<check auto-pilot settings>";
    }

    return `${diffHours} hours and ${diffMinutes} minutes`;
  };

  const formatUserFriendlyDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      weekday: "long",
    }).format(date);
  };

  useEffect(() => {
    const message = autoSyncEnabled ? (
      <div className="sync-info-secondary">
        <p>
          <i className="fas fa-sync-alt"></i> <strong>Next sync: </strong>
          {processInfo.nextSyncDate
            ? formatRelativeTime(new Date(processInfo.nextSyncDate))
            : "(calculating next sync...)"}
        </p>
        <p>
          <i className="fas fa-clock"></i> Last sync:
          <strong>
            {lastSynced
              ? formatUserFriendlyDate(new Date(lastSynced))
              : "(no sync data)"}
          </strong>
        </p>
      </div>
    ) : null;

    setNextSyncMessage(message);
  }, [autoSyncEnabled, processInfo.nextSyncDate, lastSynced]);

  useEffect(() => {
    if (processInfo?.endDate) {
      setLastSynced(processInfo.endDate);
    }
  }, [processInfo?.endDate]);

  // Then update your renderNotesModal function:
  const renderNotesModal = () => {
    if (!editingReportId) return null;

    return (
      <div
        className="eb-modal-overlay"
        onClick={() => setEditingReportId(null)}
      >
        <div className="eb-notes-modal" onClick={(e) => e.stopPropagation()}>
          <div className="eb-notes-content">
            <h4>Report Notes</h4>
            <textarea
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              placeholder="Add notes about this report..."
              className="eb-notes-textarea"
              autoFocus
            />
            <div className="eb-modal-actions">
              <button
                className="eb-modal-save"
                onClick={async () => {
                  setIsSavingNote(true);
                  await handleNoteUpdate(editingReportId, editedNote);
                  setIsSavingNote(false);
                }}
              >
                {isSavingNote ? "Saving..." : "Save"}
              </button>
              <button
                className="eb-modal-cancel"
                onClick={() => setEditingReportId(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ComplianceProvider>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={libraries}
      >
        <div className="container">
          <>
            {showModal && <WelcomeModal onClose={handleCloseModal} />}
            {user && (
              <div className="profile">
                <img
                  id="user-picture"
                  src={user.picture || `./eblogo_192.png`}
                  alt="User profile"
                />
                <div className="profile-info">
                  <button className="logout-link" onClick={handleLogout}>
                    Logout
                  </button>
                  <div className="subscription-status">
                    {user.roles.type === "Employee" ? (
                      <p className="checkout-text">Corporate license</p>
                    ) : (
                      <>
                        {cancelAt ? (
                          <>
                            <CheckoutButton
                              setUser={setUser}
                              updateUI={updateSubscriptionStateFromToken}
                              cancelAt={cancelAt}
                              isSubscribed={isSubscribed}
                              isTrial={isTrial}
                            />
                            {new Date(cancelAt) < new Date() ? (
                              <p className="checkout-text">
                                Your subscription has ended.
                              </p>
                            ) : (
                              <p>
                                Your subscription is set to cancel on{" "}
                                {new Date(cancelAt).toLocaleDateString()}.
                              </p>
                            )}
                          </>
                        ) : isSubscribed ? (
                          <>
                            <div className="subscription-buttons">
                              <CheckoutButton
                                setUser={setUser}
                                updateUI={updateSubscriptionStateFromToken}
                                cancelAt={null}
                                isSubscribed={isSubscribed}
                                isTrial={false}
                                action="upgrade"
                              />
                              <button
                                className="cancel-subscription-link"
                                onClick={confirmCancelSubscription}
                                disabled={loading.cancelSubscription}
                              >
                                {loading.cancelSubscription ? (
                                  <TailSpin
                                    color="#0033a0"
                                    height={24}
                                    width={24}
                                  />
                                ) : (
                                  "Cancel Subscription"
                                )}
                              </button>
                            </div>
                            <p>
                              Next billing date{" "}
                              {new Date(
                                subscriptionEndDate
                              ).toLocaleDateString()}
                              .
                            </p>
                          </>
                        ) : (
                          <>
                            <CheckoutButton
                              setUser={setUser}
                              updateUI={updateSubscriptionStateFromToken}
                              cancelAt={null}
                              isSubscribed={isSubscribed}
                              isTrial={isTrial}
                            />
                            {isTrial ? (
                              <p className="checkout-text">
                                {trialDaysRemaining > 0
                                  ? `Free trial ends in ${trialDaysRemaining} days`
                                  : trialDaysRemaining === 0
                                  ? "Your free trial ends today."
                                  : "Your free trial has ended."}
                              </p>
                            ) : (
                              <p className="checkout-text">
                                Your subscription has ended.
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Single submenu with both left and right menus */}
            <div className="submenu">
              <div className="left-menu">
                {/* Expenses - single top level menu item */}
                <button
                  className={`menu-link ${
                    selectedMenu === "addExpenses" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("addExpenses")} // Always use addExpenses
                >
                  Add Expense
                </button>

                {/* Other top level menu items */}
                {((user?.roles?.accountant &&
                  user?.roles?.type !== "I-Basic") ||
                  user?.roles?.type === "Employee" ||
                  user?.roles?.type === "I-Pro" ||
                  user?.roles?.type === "Employer" ||
                  user?.subscription?.isTrial) && (
                  <button
                    className={`menu-link ${
                      selectedMenu === "expenseReports" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("expenseReports")}
                  >
                    My Reports
                  </button>
                )}

                {/* Admin View - ONLY for Accountants */}
                {user?.roles?.accountant && (
                  <button
                    className={`menu-link ${
                      selectedMenu === "adminView" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("adminView")}
                  >
                    Approve
                  </button>
                )}
                {/* NEW: Add Admin Portal button */}
                {user?.isAdmin && (
                  <button
                    className={`menu-link ${
                      selectedMenu === "adminPortal" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("adminPortal")}
                  >
                    Admin Portal
                  </button>
                )}

                {/* Manage Licenses - ONLY for Employers */}
                {user?.roles?.type === "Employer" && (
                  <button
                    className={`menu-link ${
                      selectedMenu === "licenses" ? "active" : ""
                    }`}
                    onClick={() => handleMenuClick("licenses")}
                  >
                    Manage Licenses
                  </button>
                )}
              </div>

              <div className="right-menu">
                {/* FAQ Icon */}
                <Link to="/faq" className="menu-link" title="FAQ">
                  <i className="fas fa-question-circle"></i>
                </Link>

                {/* Settings Icon */}
                <button
                  className={`menu-link ${
                    selectedMenu === "settings" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("settings")}
                >
                  <i className="fas fa-cog"></i>
                </button>
              </div>
            </div>

            {/* Content container */}
            <div className="menu-content-container">
              {selectedMenu === "addExpenses" ? (
                <div className="admin-view-container">
                  <div className="main-content-section">
                    {renderAddExpenses()}
                  </div>
                </div>
              ) : selectedMenu === "adminView" ? (
                <AdminView user={user} />
              ) : selectedMenu === "adminPortal" ? (
                <AdminPortal />
              ) : selectedMenu === "licenses" ? (
                <LicenseManager user={user} />
              ) : selectedMenu === "settings" ? (
                renderSettings() // This is the updated part
              ) : (
                <div className="reports-container">
                  {renderAlwaysVisibleSection()}
                  {renderPreviouslySubmittedReports()}
                  {renderNotesModal()}
                </div>
              )}
            </div>

            <div className="container">
              {false && (
                <div className="tuning-section">
                  <h2 onClick={() => toggleSection("manualSync")}>
                    Manual Syncing {isManualSyncOpen ? "▲" : "▼"}
                  </h2>
                  {isManualSyncOpen && (
                    <div className="collapsible-content">
                      <div className="grid-container">
                        {loading.photoSelector || loading.dateRangeSelector ? (
                          <div className="loading-row">
                            <span className="loading-text">
                              <i
                                className="fas fa-info-circle info-icon"
                                aria-hidden="true"
                              ></i>
                              <span className="text-content">{`Processing ${
                                loading.photoCount > 0
                                  ? loading.photoCount
                                  : "your"
                              } photos.`}</span>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div className="grid-item">
                              <div>
                                <span>Photos</span>
                              </div>
                              <button
                                className="link-button"
                                onClick={() => setIsPhotoSelectorOpen(true)}
                                disabled={
                                  processing ||
                                  loading.photoSelector ||
                                  !processInfo.processingCompleted
                                }
                              >
                                Select Photos
                              </button>

                              {isPhotoSelectorOpen && (
                                <div className="modal-backdrop">
                                  <PhotoSelector
                                    onClose={() =>
                                      setIsPhotoSelectorOpen(false)
                                    }
                                    onPhotosSelected={handlePhotosSelected}
                                    onError={handlePhotoSelectorError}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="grid-item">
                              <span>Dates</span>
                              <button
                                className="link-button"
                                onClick={handleDateRangeSelector}
                                disabled={
                                  processing ||
                                  loading.dateRangeSelector ||
                                  !processInfo.processingCompleted
                                }
                              >
                                Select Date Range
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {false && (
                <>
                  {nextSyncMessage}
                  <div className="tuning-section">
                    <h2 onClick={() => toggleSection("autoPilot")}>
                      Auto Syncing {isAutoPilotOpen ? "▲" : "▼"}
                    </h2>
                    {isAutoPilotOpen && (
                      <div className="collapsible-content">
                        <div className="grid-container">
                          <div className="grid-item">
                            <span>
                              Autosync: {autoSyncEnabled ? "ON" : "OFF"}
                            </span>
                            <button
                              className="link-button"
                              onClick={handleToggleAutoSync}
                              disabled={loading.autoSync}
                            >
                              {loading.autoSync ? (
                                <TailSpin
                                  color="#0033a0"
                                  height={24}
                                  width={24}
                                />
                              ) : autoSyncEnabled ? (
                                "Turn off"
                              ) : (
                                "Turn on"
                              )}
                            </button>
                          </div>

                          <div className="grid-item">
                            <span>Force Sync</span>
                            <button
                              className="link-button"
                              onClick={handleUpdateSync}
                              disabled={
                                processing ||
                                loading.updateSync ||
                                !processInfo.processingCompleted
                              }
                            >
                              {loading.updateSync ? (
                                <TailSpin
                                  color="#0033a0"
                                  height={24}
                                  width={24}
                                />
                              ) : (
                                "Force Update"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {/*
            {selectedMenu === "allExpenses" && (
              <div className="tuning-section">
                <h2 onClick={() => toggleSection("settings")}>
                  Settings {isSettingsOpen ? "▲" : "▼"}
                </h2>
                {isSettingsOpen && (
                  <div className="collapsible-content">
                    <div className="grid-container">
                     

                      <div className="grid-item">
                        <span>Use as Reviewer</span>
                        {loading.reviewerToggle ? (
                          <TailSpin color="#0033a0" height={24} width={24} />
                        ) : (
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={user?.roles?.accountant || false}
                              onChange={handleReviewerToggle}
                              disabled={loading.reviewerToggle}
                            />
                            <span className="slider round"></span>
                          </label>
                        )}
                      </div>

                      <div className="grid-item">
                        <span>Reset Account</span>
                        <button
                          onClick={handleReset}
                          className="link-button reset-button"
                          disabled={loading.reset}
                        >
                          {loading.reset ? "Resetting..." : "Reset Account"}
                        </button>

                        {resetError && (
                          <div className="error-message">
                            {resetError.includes("drive.google.com") ? (
                              <span>
                                {resetError.split("https://")[0]}
                                <a
                                  href={`https://${
                                    resetError.split("https://")[1]
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-600 hover:text-blue-800 underline"
                                >
                                  View file in Google Drive
                                </a>
                              </span>
                            ) : (
                              resetError
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )} */}
              {isPhotoSelectorOpen &&
                (photoSource === "google" ? (
                  <PhotoSelector
                    onClose={() => {
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                    onPhotosSelected={handlePhotosSelected}
                    onError={(error) => {
                      toast.error(error, {
                        position: "top-center",
                        autoClose: 5000,
                      });
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                  />
                ) : photoSource === "pdf" ? (
                  <PdfUploader
                    onClose={() => {
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                    onPdfSelected={handlePhotosSelected}
                    onUploadProgress={(progress) => {
                      setProcessInfo((prevInfo) => ({
                        ...prevInfo,
                        uploadProgress: progress,
                      }));
                    }}
                  />
                ) : photoSource === "camera" ? (
                  <CameraSelector
                    onClose={() => {
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                    onPhotosSelected={handlePhotosSelected}
                    onError={(error) => {
                      alert(error);
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                  />
                ) : photoSource === "upload" ? (
                  <FileUploader
                    onClose={() => {
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                    onPhotosSelected={handlePhotosSelected}
                    onError={(error) => {
                      alert(error);
                      setIsPhotoSelectorOpen(false);
                      setPhotoSource(null);
                    }}
                    onUploadProgress={(progress) => {
                      setProcessInfo((prevInfo) => ({
                        ...prevInfo,
                        uploadProgress: progress,
                      }));
                    }}
                  />
                ) : null)}
              {isDateRangeSelectorOpen && (
                <div className="modal-backdrop">
                  <DateRangeSelector
                    onClose={() => setIsDateRangeSelectorOpen(false)}
                    onDateRangeSelected={handleDateRangeSelected}
                  />
                </div>
              )}

              <MileageTracker
                isOpen={isMileageModalOpen}
                onClose={() => {
                  setMileageModalOpen(false);
                }}
                onSuccess={() => {
                  toast.success("Mileage expense added successfully!");
                }}
                processInfo={processInfo}
              />
              {/* Right before your component's closing tags, near where you render MileageTracker */}
              {showGmailProcessor && (
                <GmailProcessor
                  onClose={() => setShowGmailProcessor(false)}
                  onProcessingStarted={(data) => {
                    if (
                      data.userSelectedPhotos &&
                      data.userSelectedPhotos.length > 0
                    ) {
                      // Pass the processed items directly to handlePhotosSelected
                      handlePhotosSelected(data.userSelectedPhotos);
                    } else {
                      // Update processing state if no items were found
                      setProcessInfo((prevInfo) => ({
                        ...prevInfo,
                        status: `Processed emails from Gmail, but no attachments found.`,
                        processingCompleted: true,
                      }));
                    }
                  }}
                />
              )}
              {/* Add this right before your component's closing tags */}
              <DeleteConfirmationModal
                isOpen={deleteConfirmation.isOpen}
                onClose={() =>
                  setDeleteConfirmation({ isOpen: false, reportId: null })
                }
                onConfirm={() => confirmDelete(false)}
                onConfirmWithRevert={() => confirmDelete(true)}
                reportId={deleteConfirmation.reportId}
              />
            </div>
          </>

          <ReportWizardModal
            isOpen={showWizard}
            onClose={() => setShowWizard(false)}
            onReportSubmitted={handleReportSubmitted}
          />
          {showEmailModal && activeReportForEmail && (
            <EmailManagement
              report={activeReportForEmail}
              onClose={handleCloseEmailModal}
              onUpdateEmails={handleUpdateEmails}
            />
          )}

          {showComplianceDocs && (
            <ComplianceDocumentsModal
              onClose={() => setShowComplianceDocs(false)}
              userEmail={user?.email}
            />
          )}
          {showTagManagementModal && (
            <TagManagementModal
              isOpen={showTagManagementModal}
              onClose={() => setShowTagManagementModal(false)}
              onTagsUpdated={() => {
                // Optionally refresh tags in your ProcessInfo or update state if needed
              }}
            />
          )}
          {showExpenseCategoryModal && (
            <ExpenseCategoryManagementModal
              isOpen={showExpenseCategoryModal}
              onClose={() => setShowExpenseCategoryModal(false)}
              onCategoriesUpdated={() => {
                // Optionally refresh categories in your ProcessInfo or update state if needed
              }}
            />
          )}
        </div>
      </LoadScript>
    </ComplianceProvider>
  );
};

export default ProcessInfo;
