import React, { useState } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import "../styles/BusinessPurposeModal.css";

const BusinessPurposeModal = ({
  isOpen,
  onClose,
  expenseId,
  reportId,
  spreadsheetId,
  clientEmail,
  rowIndex,
  currentPurpose,
  onSuccess,
}) => {
  const [purpose, setPurpose] = useState(currentPurpose || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        "/api/update-spreadsheet-cell",
        {
          spreadsheetId: reportId, // Use reportId as the spreadsheetId
          reportId,
          rowIndex,
          columnId: "L", // Notes column
          value: purpose,
          clientEmail: clientEmail, // Only needed for accountant view
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        if (onSuccess) {
          onSuccess(purpose);
        }
        onClose();
      } else {
        setError(response.data.message || "Failed to update business purpose");
      }
    } catch (error) {
      console.error("Error updating business purpose:", error);
      setError(
        error.response?.data?.message || "Failed to update business purpose"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="business-purpose-modal-backdrop">
      <div className="business-purpose-modal-wrapper">
        <div className="business-purpose-modal-header">
          <h2 className="business-purpose-modal-title">
            <i className="fas fa-comment-alt"></i>
            Add Business Purpose
          </h2>
          <button className="business-purpose-modal-close" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="business-purpose-modal-content">
          {error && (
            <div className="business-purpose-alert">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="business-purpose-form">
            <div className="business-purpose-form-group">
              <label
                htmlFor="businessPurpose"
                className="business-purpose-label"
              >
                Business Purpose *
              </label>
              <textarea
                id="businessPurpose"
                className="business-purpose-textarea"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                placeholder="Explain the business purpose of this expense (e.g., client meeting, business trip purpose, attendees, discussion topics)"
                rows={4}
                required
              />
              <div className="business-purpose-hint">
                Include specific details like attendees, business topics
                discussed, and how this expense relates to business activities.
              </div>
            </div>

            <div className="business-purpose-actions">
              <button
                type="button"
                onClick={onClose}
                className="business-purpose-button business-purpose-button-secondary"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="business-purpose-button business-purpose-button-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="business-purpose-button-loading">
                    <TailSpin color="#ffffff" height={16} width={16} />
                    <span>Saving...</span>
                  </span>
                ) : (
                  "Save Business Purpose"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessPurposeModal;
