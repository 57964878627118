import React, { useState, useEffect, useCallback } from "react";
import { TailSpin } from "react-loader-spinner";
import { Upload, Trash2, ChevronDown, ChevronRight } from "lucide-react";
import { useCompliance } from "./ComplianceContext";

const ComplianceDocuments = ({ isActive, skipRefreshes }) => {
  console.log("ComplianceDocuments rendered with isActive:", isActive);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({
    policy: { status: "idle" },
    formAdv: { status: "idle" },
    ppm: { status: "idle" },
  });
  const [expandedSummaries, setExpandedSummaries] = useState({});
  // Add state to track which document is being deleted
  const [deletingDocId, setDeletingDocId] = useState(null);

  // Get the context functions - add this line
  const { setDocumentsExist } = useCompliance();

  const truncateFilename = (filename, maxLength = 20) => {
    // Rest of your code...
    if (!filename || filename.length <= maxLength) return filename;
    const extension = filename.split(".").pop();
    const name = filename.substring(0, filename.length - extension.length - 1);
    const truncatedName =
      name.substring(0, maxLength - extension.length - 3) + "...";
    return `${truncatedName}.${extension}`;
  };
  // Update upload statuses based on existing documents
  const updateUploadStatuses = useCallback((docs) => {
    console.log("Updating upload statuses with docs:", docs);
    setUploadStatus((prevUploadStatus) => {
      const newUploadStatus = { ...prevUploadStatus };

      ["policy", "formAdv", "ppm"].forEach((type) => {
        // Get the current document ID we're displaying
        const currentDocId = prevUploadStatus[type]?.documentId;
        console.log(`Checking type ${type}, current ID: ${currentDocId}`);

        // If we have a current document ID, only look for that specific document
        if (currentDocId) {
          const doc = docs.find(
            (d) => d.id === currentDocId && d.type === type
          );
          if (doc) {
            newUploadStatus[type] = {
              status: "success",
              // Here we use sections for one summary...
              summary: doc.metadata?.sections || [],
              documentId: doc.id,
              showSummary: true,
            };
          } else {
            // If our current document was not found (was deleted), reset to idle
            newUploadStatus[type] = { status: "idle", summary: [] };
            console.log(
              `Resetting ${type} to idle state - document ${currentDocId} not found`
            );
          }
        } else {
          // If we don't have a current document, we can show the most recent one
          const doc = docs.find((d) => d.type === type);
          if (doc) {
            newUploadStatus[type] = {
              status: "success",
              summary: doc.metadata?.sections || [],
              documentId: doc.id,
              showSummary: true,
            };
          } else {
            newUploadStatus[type] = { status: "idle", summary: [] };
          }
        }
      });

      console.log("New upload status:", newUploadStatus);
      return newUploadStatus;
    });
  }, []);

  // Update the fetchDocuments function
  const fetchDocuments = useCallback(async () => {
    console.log("fetchDocuments started");
    setIsLoading(true);
    try {
      const response = await fetch("/api/documents", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      console.log("Documents fetched:", data);

      if (data.success) {
        if (Array.isArray(data.documents)) {
          setDocuments(data.documents);
          updateUploadStatuses(data.documents);

          // Check for any compliance documents and update the context
          const hasPolicyDocs = data.documents.some(
            (doc) => doc.type === "policy"
          );
          const hasFormAdvDocs = data.documents.some(
            (doc) => doc.type === "formAdv"
          );

          // Set the context based on the documents we found
          if (hasPolicyDocs || hasFormAdvDocs) {
            setDocumentsExist(true);
          } else {
            setDocumentsExist(false);
          }
        } else {
          console.error("Invalid documents data received:", data);
          setDocuments([]);
          updateUploadStatuses([]);
          setDocumentsExist(false);
        }
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setDocuments([]);
      updateUploadStatuses([]);
      setDocumentsExist(false);
    } finally {
      setIsLoading(false);
    }
  }, [updateUploadStatuses, setDocumentsExist]);

  useEffect(() => {
    console.log("ComplianceDocuments useEffect triggered, isActive:", isActive);
    // Only fetch if active and we're not skipping refreshes
    if (isActive && !skipRefreshes) {
      fetchDocuments();
    }
  }, [isActive, fetchDocuments, skipRefreshes]);

  const handleDeleteDocument = async (documentId) => {
    // Set the deleting document ID to show the spinner
    setDeletingDocId(documentId);

    try {
      const response = await fetch(`/api/documents/${documentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        // First, determine if the document being deleted is a policy document
        const deletedDocType = documents.find(
          (doc) => doc.id === documentId
        )?.type;
        const isPolicyDoc = deletedDocType === "policy";

        // Check for remaining policy documents if we're deleting a policy
        let remainingPolicies = [];
        if (isPolicyDoc) {
          remainingPolicies = documents.filter(
            (doc) => doc.id !== documentId && doc.type === "policy"
          );
        }

        setUploadStatus((prev) => {
          const newStatus = { ...prev };
          Object.entries(newStatus).forEach(([type, status]) => {
            if (status.documentId === documentId) {
              newStatus[type] = { status: "idle", summary: [] };
            }
          });
          return newStatus;
        });

        // Dispatch a global event
        window.dispatchEvent(new Event("policy-document-changed"));

        // If this was the last policy document, update the context
        if (isPolicyDoc && remainingPolicies.length === 0) {
          setDocumentsExist(false);
        }

        await fetchDocuments();
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      // Clear the deleting document ID when done
      setDeletingDocId(null);
    }
  };

  const handleFileSelect = (e, type) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileUpload(e, type);
    }
    e.target.value = "";
  };

  const handleFileUpload = async (event, type) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploadStatus((prev) => ({
      ...prev,
      [type]: { status: "uploading" },
    }));

    try {
      const formData = new FormData();
      formData.append("type", type);
      formData.append("documents", file);

      const response = await fetch("/api/documents/upload", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();

      if (data.success) {
        const result = data.results[0];

        // Check if the document was rejected by validation
        if (result.status === "rejected") {
          setUploadStatus((prev) => ({
            ...prev,
            [type]: {
              status: "error",
              error: `Invalid document: ${
                result.message ||
                "This doesn't appear to be a valid document of this type."
              }`,
            },
          }));
          return;
        }

        setUploadStatus((prev) => ({
          ...prev,
          [type]: {
            status: "success",
            summary: result.sections || [],
            documentId: result.documentId,
          },
        }));

        // In handleFileUpload after successful upload:
        if (type === "policy" || type === "formAdv") {
          console.log(`${type} document uploaded, updating context`);
          setDocumentsExist(true);
          // Dispatch a global event
          window.dispatchEvent(new Event("policy-document-changed"));
        }

        await fetchDocuments();
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      setUploadStatus((prev) => ({
        ...prev,
        [type]: { status: "error", error: error.message },
      }));
    }
  };

  const DocumentUploader = ({ type, title, description }) => {
    const thisTypeStatus = uploadStatus[type];
    const isUploaded = thisTypeStatus?.status === "success";
    const isUploading = thisTypeStatus?.status === "uploading";
    const currentDocument = documents.find(
      (doc) => doc.id === thisTypeStatus?.documentId && doc.type === type
    );
    const isDeleting = deletingDocId === thisTypeStatus?.documentId;

    const handleDelete = async () => {
      if (!thisTypeStatus?.documentId) return;
      try {
        // Set the deleting document ID to show the spinner
        setDeletingDocId(thisTypeStatus.documentId);

        const response = await fetch(
          `/api/documents/${thisTypeStatus.documentId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          setUploadStatus((prev) => ({
            ...prev,
            [type]: {
              status: "idle",
              documentId: null,
              summary: [],
              showSummary: false,
            },
          }));

          // Dispatch a global event
          window.dispatchEvent(new Event("policy-document-changed"));

          // After deleting a document, check if any policy documents remain
          const remainingPolicyDocuments = documents.filter(
            (doc) =>
              doc.id !== thisTypeStatus.documentId && doc.type === "policy"
          );

          // If no policy documents remain, update the context
          if (type === "policy" && remainingPolicyDocuments.length === 0) {
            setDocumentsExist(false);
          }

          // After deleting a document, we need to verify if any compliance documents remain
          await fetchDocuments();
        } else {
          throw new Error("Failed to delete document");
        }
      } catch (error) {
        console.error("Error deleting document:", error);
      } finally {
        // Clear the deleting document ID when done
        setDeletingDocId(null);
      }
    };

    return (
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-lg font-medium mb-2">{title}</h3>
        <p className="text-sm text-gray-600 mb-4">{description}</p>

        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-4 space-y-2 sm:space-y-0">
          <label
            className={`relative rounded-lg px-4 py-2 transition-colors ${
              isUploaded
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-50 hover:bg-blue-100 text-blue-900 cursor-pointer"
            }`}
          >
            <input
              type="file"
              className="hidden"
              accept=".pdf,.doc,.docx,.txt"
              onChange={(e) => handleFileSelect(e, type)}
              disabled={isUploaded || isUploading}
            />
            <div className="flex items-center space-x-2">
              <Upload className="w-4 h-4" />
              <span className="max-w-[150px] sm:max-w-full truncate">
                {isUploaded
                  ? truncateFilename(currentDocument?.name, 25) ||
                    "File Uploaded"
                  : "Choose File"}
              </span>
            </div>
          </label>

          {isUploading && (
            <div className="flex items-center space-x-2 text-blue-600">
              <TailSpin color="#2563eb" height={16} width={16} />
              <span>Uploading...</span>
            </div>
          )}

          {isUploaded && currentDocument && (
            <div className="flex flex-wrap items-center gap-2 mt-2 sm:mt-0">
              {currentDocument.driveFileId && (
                <button
                  onClick={() =>
                    window.open(
                      `https://drive.google.com/file/d/${currentDocument.driveFileId}/view`
                    )
                  }
                  className="flex items-center space-x-1 px-2 py-1 rounded-md bg-blue-50 text-blue-600 hover:text-blue-800"
                >
                  <i className="fas fa-download"></i>
                  <span>Download</span>
                </button>
              )}
              <button
                onClick={handleDelete}
                disabled={isDeleting}
                className="flex items-center space-x-1 px-2 py-1 rounded-md bg-red-50 text-red-600 hover:text-red-800 disabled:opacity-50"
              >
                {isDeleting ? (
                  <>
                    <TailSpin color="#dc2626" height={14} width={14} />
                    <span>Deleting...</span>
                  </>
                ) : (
                  <>
                    <Trash2 className="w-4 h-4" />
                    <span>Delete</span>
                  </>
                )}
              </button>
            </div>
          )}
        </div>

        {thisTypeStatus.status === "error" && (
          <div className="mt-2 bg-red-50 text-red-900 px-4 py-2 rounded-lg">
            <span>{thisTypeStatus.error || "Upload failed"}</span>
          </div>
        )}

        {/* Existing Summary for Sections */}
        {/* Existing Summary for Sections (hidden for Form ADV) */}
        {isUploaded &&
          type !== "formAdv" &&
          currentDocument?.metadata?.sections && (
            <div className="mt-4">
              <button
                onClick={() =>
                  setExpandedSummaries((prev) => ({
                    ...prev,
                    [type]: !prev[type],
                  }))
                }
                className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
              >
                {expandedSummaries[type] ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )}
                <span>View Summary</span>
              </button>
              {expandedSummaries[type] && (
                <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                  {currentDocument.metadata.sections.map((section, idx) => (
                    <div key={idx} className="mb-4">
                      <h4 className="font-medium text-gray-900">
                        {section.title}
                      </h4>
                      {section.rules?.length > 0 && (
                        <div className="mt-2">
                          <h5 className="text-sm font-medium text-gray-700">
                            Rules:
                          </h5>
                          <ul className="ml-4 list-disc text-sm text-gray-600">
                            {section.rules.map((rule, ruleIdx) => (
                              <li key={ruleIdx}>{rule}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {section.limits?.length > 0 && (
                        <div className="mt-2">
                          <h5 className="text-sm font-medium text-gray-700">
                            Limits:
                          </h5>
                          <ul className="ml-4 list-disc text-sm text-gray-600">
                            {section.limits.map((limit, limitIdx) => (
                              <li key={limitIdx}>{limit}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

        {/* New Summary for Expense Categories */}
        {isUploaded && currentDocument?.metadata?.expenseCategories && (
          <div className="mt-4">
            <button
              onClick={() =>
                setExpandedSummaries((prev) => ({
                  ...prev,
                  [`${type}_expenseCategories`]:
                    !prev[`${type}_expenseCategories`],
                }))
              }
              className="flex items-center space-x-2 text-blue-600 hover:text-blue-800"
            >
              {expandedSummaries[`${type}_expenseCategories`] ? (
                <ChevronDown className="w-4 h-4" />
              ) : (
                <ChevronRight className="w-4 h-4" />
              )}
              <span>View Approved Expense Categories</span>
            </button>
            {expandedSummaries[`${type}_expenseCategories`] && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                {currentDocument.metadata.expenseCategories.map((cat, idx) => (
                  <div key={idx} className="mb-4 border-b pb-2">
                    <h4 className="font-medium text-gray-900">
                      {cat.name}{" "}
                      <span className="text-sm text-gray-600">
                        ({cat.entityType.replace("_", " ").toUpperCase()})
                      </span>
                    </h4>
                    {cat.description && (
                      <p className="text-sm text-gray-700">
                        <strong>Description:</strong> {cat.description}
                      </p>
                    )}
                    {cat.restrictions && cat.restrictions.length > 0 && (
                      <div className="text-sm text-gray-700">
                        <strong>Restrictions:</strong>
                        <ul className="ml-4 list-disc">
                          {cat.restrictions.map((restriction, ridx) => (
                            <li key={ridx}>{restriction}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {cat.citation && (
                      <p className="text-xs text-gray-500">
                        <strong>Citation:</strong> {cat.citation}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {isLoading && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ pointerEvents: "all" }}
        >
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <TailSpin color="#0033a0" height={40} width={40} />
            <p className="mt-3 text-gray-700 font-medium">
              Loading documents...
            </p>
          </div>
        </div>
      )}
      <DocumentUploader
        type="policy"
        title="Expense Policy"
        description="Upload your organization's expense policy. We'll extract rules, limits, and requirements, and you'll be able to check your expense reports—and those of people you can view—against it."
      />
      <DocumentUploader
        type="formAdv"
        title="Form ADV Part 2 Brochures (SEC Investment Adviser Public Disclosure)"
        description="Upload your Form ADV Part 2 Brochures. We'll extract expense categories and compliance requirements, and you'll be able to check your own invoices—and expense reports of people you can view—against it."
      />

      {/* Document List */}
      {documents.length > 0 && (
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4">Uploaded Documents</h3>
          <div className="space-y-4">
            {documents.map((doc) => (
              <div
                key={doc.id}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
              >
                <div>
                  <h4 className="font-medium text-gray-900">{doc.name}</h4>
                  <p className="text-sm text-gray-600">
                    Uploaded on {new Date(doc.uploadedAt).toLocaleDateString()}
                  </p>
                </div>
                <button
                  onClick={() => handleDeleteDocument(doc.id)}
                  disabled={deletingDocId === doc.id}
                  className="flex items-center space-x-1 px-2 py-1 rounded-md text-red-600 hover:text-red-800 disabled:opacity-50"
                >
                  {deletingDocId === doc.id ? (
                    <>
                      <TailSpin color="#dc2626" height={14} width={14} />
                      <span>Deleting...</span>
                    </>
                  ) : (
                    <>
                      <Trash2 className="w-4 h-4" />
                      <span>Delete</span>
                    </>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplianceDocuments;
