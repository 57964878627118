import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../styles/EmailManagement.css";

const EmailManagement = ({ report, onClose, onUpdateEmails }) => {
  const [currentEmails, setCurrentEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailsToAdd, setEmailsToAdd] = useState([]);
  const [emailsToRemove, setEmailsToRemove] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // Add loading state

  // Initialize the component with current emails
  useEffect(() => {
    if (report && report.sentTo) {
      setCurrentEmails([...report.sentTo]);
    }
  }, [report]);

  // Validate email format
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handle adding a new email
  const handleAddEmail = () => {
    const trimmedEmail = emailInput.trim();

    // Validate email format
    if (!isValidEmail(trimmedEmail)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Check if email is already in the list
    if (
      currentEmails.includes(trimmedEmail) ||
      emailsToAdd.includes(trimmedEmail)
    ) {
      setEmailError("This email is already in the list.");
      return;
    }

    // Add to emailsToAdd list
    setEmailsToAdd([...emailsToAdd, trimmedEmail]);

    // Remove from emailsToRemove if it was there
    setEmailsToRemove(emailsToRemove.filter((email) => email !== trimmedEmail));

    // Update the current display
    setCurrentEmails([...currentEmails, trimmedEmail]);

    // Clear input and error
    setEmailInput("");
    setEmailError("");
  };

  // Handle removing an email
  const handleRemoveEmail = (emailToRemove) => {
    // Update currentEmails for UI
    setCurrentEmails(currentEmails.filter((email) => email !== emailToRemove));

    // If this was an email we were going to add, just remove it from that list
    if (emailsToAdd.includes(emailToRemove)) {
      setEmailsToAdd(emailsToAdd.filter((email) => email !== emailToRemove));
    }
    // Otherwise, add to the remove list if it's an original email
    else if (report.sentTo && report.sentTo.includes(emailToRemove)) {
      setEmailsToRemove([...emailsToRemove, emailToRemove]);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await onUpdateEmails(report.reportId, emailsToAdd, emailsToRemove);
    } finally {
      setIsSaving(false);
    }
  };

  // Handle key press for email input
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && emailInput) {
      e.preventDefault();
      handleAddEmail();
    }
  };

  return (
    <div className="email-modal-overlay" onClick={onClose}>
      <div className="email-modal" onClick={(e) => e.stopPropagation()}>
        <div className="email-modal-header">
          <h3>Manage Report Recipients</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="email-modal-content">
          <p className="email-description">
            Add or remove people who should have access to this report. Each
            recipient will receive an email with a link to view the report.
          </p>

          <div className="email-input-section">
            <div className="email-input-container">
              <input
                type="email"
                value={emailInput}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setEmailError("");
                }}
                onKeyPress={handleKeyPress}
                placeholder="Enter email address"
                className="email-input"
              />
              <button
                className="add-email-button"
                onClick={handleAddEmail}
                disabled={!emailInput.trim()}
              >
                Add
              </button>
            </div>
            {emailError && <div className="email-error">{emailError}</div>}
          </div>

          <div className="email-list-container">
            <h4>Current Recipients</h4>
            {currentEmails.length > 0 ? (
              <div className="email-list">
                {currentEmails.map((email, index) => (
                  <div className="email-item" key={index}>
                    <span className="email-text">{email}</span>
                    <button
                      className="remove-email-button"
                      onClick={() => handleRemoveEmail(email)}
                      title="Remove recipient"
                      disabled={isSaving}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-emails-message">
                No recipients for this report.
              </p>
            )}
          </div>
        </div>

        <div className="email-modal-footer">
          <div className="changes-summary">
            {(emailsToAdd.length > 0 || emailsToRemove.length > 0) && (
              <p>
                {emailsToAdd.length > 0 &&
                  `Adding ${emailsToAdd.length} recipient(s). `}
                {emailsToRemove.length > 0 &&
                  `Removing ${emailsToRemove.length} recipient(s).`}
              </p>
            )}
          </div>
          <div className="button-group">
            <button
              className="cancel-button"
              onClick={onClose}
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              className="save-button"
              onClick={handleSubmit}
              disabled={
                (emailsToAdd.length === 0 && emailsToRemove.length === 0) ||
                isSaving
              }
            >
              {isSaving ? (
                <div className="loading-button-content">
                  <TailSpin color="#ffffff" height={16} width={16} />
                  <span>
                    {emailsToAdd.length > 0 ? "Sending emails..." : "Saving..."}
                  </span>
                </div>
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailManagement;
