// src/components/PhotoSelectionContainer.jsx
import React from "react";
import {
  Camera,
  Upload,
  Car,
  Search,
  Cloud,
  FileText,
  Mail,
  Forward,
} from "lucide-react";
import "../styles/PhotoSelectionContainer.css";
import UploadProgressBar from "./UploadProgressBar";

const PhotoSelectionContainer = ({
  handlePhotoSelector,
  processing,
  loading,
  user,
  setMileageModalOpen,
  mileageNotification,
  SHOW_ICLOUD_BUTTON,
  uploadProgress,
  onManageTags, // prop for managing tags
}) => {
  return (
    <div className="photo-selection-container">
      {uploadProgress > 0 && uploadProgress < 100 && (
        <UploadProgressBar progress={uploadProgress} />
      )}
      <div className="photo-source-buttons">
        {/* Primary Camera Button */}
        <button
          className="camera-button-featured"
          onClick={() => handlePhotoSelector("camera")}
          disabled={processing || loading.photoSelector}
          aria-label="Take a photo of a receipt"
        >
          <div className="camera-button-content">
            <Camera size={48} strokeWidth={1.8} className="camera-icon" />
            <div className="camera-button-text">
              <span className="primary-text">Take Photo of Receipt</span>
              <span className="secondary-text">
                Instantly capture and process a receipt
              </span>
            </div>
          </div>
        </button>

        {/* Section title with enhanced semantics and Manage Tags link */}
        <div className="options-header-container">
          <h3 className="options-section-title">Import Options</h3>
          {onManageTags && (
            <button
              className="manage-tags-link"
              onClick={onManageTags}
              aria-label="Manage receipt tags"
            >
              Manage Tags
            </button>
          )}
        </div>

        {/* Secondary Options Container */}
        <div className="secondary-options">
          {/* Google Photos Option */}
          <div className="google-services-group">
            <button
              className="google-photos-button"
              onClick={() => handlePhotoSelector("google")}
              disabled={processing || loading.photoSelector}
            >
              <div className="button-icon-container">
                <Search size={24} />
              </div>
              <div className="button-text-container">
                <span className="button-title">
                  Find Receipts in Google Photos
                </span>
                <span className="button-description">
                  Find receipts in your photos
                </span>
              </div>
            </button>

            {/* Gmail Button - Moved here to group with Google Photos */}
            <button
              className="google-service-button gmail-button"
              onClick={() => handlePhotoSelector("gmail")}
              disabled={processing || loading.photoSelector}
            >
              <div className="button-icon-container">
                <Mail size={24} />
              </div>
              <div className="button-text-container">
                <span className="button-title">Process from Gmail</span>
                <span className="button-description">
                  Process receipts from your Gmail inbox
                </span>
              </div>
            </button>
          </div>

          {/* Container for smaller buttons */}
          <div className="small-buttons-container">
            <button
              className="secondary-button upload-button"
              onClick={() => handlePhotoSelector("upload")}
              disabled={processing || loading.photoSelector}
            >
              <div className="button-icon-container">
                <Upload size={24} />
              </div>
              <div className="button-text-container">
                <span className="button-title">Upload Photo from Device</span>
                <span className="button-description">
                  Process screenshots and images of receipts
                </span>
              </div>
            </button>

            <button
              className="secondary-button pdf-upload-button"
              onClick={() => handlePhotoSelector("pdf")}
              disabled={processing || loading.photoSelector}
            >
              <div className="button-icon-container">
                <FileText size={24} />
              </div>
              <div className="button-text-container">
                <span className="button-title">Upload PDF Receipt</span>
                <span className="button-description">
                  Process PDF documents
                </span>
              </div>
            </button>

            {(user?.roles?.type === "I-Pro" ||
              user?.roles?.type === "Employee" ||
              user?.roles?.type === "Employer") && (
              <button
                className="secondary-button mileage-selection-button"
                onClick={() => {
                  console.log("Mileage button clicked");
                  setMileageModalOpen(true);
                }}
                disabled={processing || loading.photoSelector}
              >
                <div className="button-icon-container mileage-icon-container">
                  <Car size={24} aria-hidden="true" />
                </div>
                <div className="button-text-container">
                  <span className="button-title">Add Mileage Expense</span>
                  <span className="button-description">
                    Track mileage expenses
                  </span>
                </div>
              </button>
            )}

            {SHOW_ICLOUD_BUTTON && (
              <button
                className="secondary-button icloud-button"
                onClick={() => handlePhotoSelector("icloud")}
                disabled={processing || loading.photoSelector}
              >
                <div className="button-icon-container">
                  <Cloud size={24} />
                </div>
                <div className="button-text-container">
                  <span className="button-title">Get Photos from iCloud</span>
                  <span className="button-description">
                    Access photos from your iCloud account
                  </span>
                </div>
              </button>
            )}
          </div>
        </div>

        {/* New Email Forward Feature Notification */}
        <div
          className="email-forward-feature"
          role="region"
          aria-label="Email forwarding information"
        >
          <div className="email-forward-content">
            <div className="email-forward-icon">
              <Forward size={24} aria-hidden="true" />
            </div>
            <div className="email-forward-text">
              <div className="new-feature-badge">NEW</div>
              <h4>Forward receipts by email</h4>
              <p>
                Send receipts to{" "}
                <span className="email-highlight">receipts@expensebot.ai</span>{" "}
                and they'll automatically appear in your spreadsheet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoSelectionContainer;
