export const uploadToGooglePhotos = async (photoBlob) => {
  // Convert Blob -> base64 so we can send it in JSON
  const base64Data = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(photoBlob);
  });

  const token = localStorage.getItem("token");

  async function uploadWithRetry(currentToken) {
    const response = await fetch("/api/upload-camera-photo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentToken}`,
      },
      body: JSON.stringify({ photoData: base64Data }),
    });

    // If token expired:
    if (response.status === 401) {
      console.log("Token expired, refreshing...");
      const tokenResponse = await fetch("/api/get-new-token", {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });
      if (!tokenResponse.ok) {
        throw new Error("Failed to refresh token");
      }
      const { token: newToken } = await tokenResponse.json();
      localStorage.setItem("token", newToken);
      console.log("Got new token, retrying upload...");
      return uploadWithRetry(newToken);
    }

    if (!response.ok) {
      const errorData = await response.json();
      // If your server sets status=403 or 404 for special messages:
      if (response.status === 403) {
        throw new Error(
          "To use this feature, please ensure Google Photos is properly set up, etc..."
        );
      } else if (response.status === 404) {
        throw new Error(
          "No photos found. Please install Google Photos, enable 'Backup & Sync', etc..."
        );
      }
      throw new Error(errorData.error || "Failed to upload photo");
    }

    const { mediaItem } = await response.json();
    return mediaItem;
  }

  return uploadWithRetry(token);
};
