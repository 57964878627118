const decodeJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Error decoding JWT:", e);
    return null;
  }
};

const refreshAccessToken = async () => {
  try {
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      throw new Error("No JWT token found in localStorage");
    }

    // Decode the stored token
    const initialDecoded = decodeJwt(storedToken);
    console.log("Initial token structure:", initialDecoded);

    // Check if the token has expired
    const expiryDate = initialDecoded?.tokens?.expiry_date;
    if (expiryDate && Date.now() >= expiryDate) {
      console.warn(
        "Access token has expired. Proceeding to fetch a new token..."
      );

      // Fetch a new token
      const response = await fetch("/api/get-new-token", {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch new token: ${response.status}`);
      }

      const data = await response.json();
      const newToken = data.token;
      if (!newToken) {
        throw new Error("No new token received from server");
      }

      // Save the new token
      localStorage.setItem("token", newToken);

      // Decode and return the new token
      const decodedToken = decodeJwt(newToken);
      console.log("Updated token structure:", decodedToken);

      if (!decodedToken?.tokens?.access_token) {
        throw new Error("New token is missing required fields");
      }

      return decodedToken.tokens.access_token;
    }

    // If the token is valid, use it
    if (initialDecoded?.tokens?.access_token) {
      return initialDecoded.tokens.access_token;
    }

    // If we have an unexpected structure, fetch a new token
    console.warn(
      "Unexpected token structure. Proceeding to fetch a new token..."
    );

    // Fetch a new token
    const response = await fetch("/api/get-new-token", {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch new token: ${response.status}`);
    }

    const data = await response.json();
    const newToken = data.token;
    if (!newToken) {
      throw new Error("No new token received from server");
    }

    // Save the new token
    localStorage.setItem("token", newToken);

    // Decode and return the new token
    const decodedToken = decodeJwt(newToken);
    console.log("Updated token structure:", decodedToken);

    if (!decodedToken?.tokens?.access_token) {
      throw new Error("New token is missing required fields");
    }

    return decodedToken.tokens.access_token;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error; // Let the caller handle the error
  }
};

// Update createPickerSession to use the improved token refresh
// Inside googlePhotosPicker.js, update this function
const createPickerSession = async () => {
  try {
    const accessToken = await refreshAccessToken();
    if (!accessToken) {
      throw new Error("Failed to get access token");
    }

    const response = await fetch(
      "https://photospicker.googleapis.com/v1/sessions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      const error = new Error(`Failed to create session: ${response.status}`);
      error.status = response.status;

      // Specifically mark 401 errors as subscription issues
      if (response.status === 401) {
        error.possibleSubscriptionIssue = true;
      }

      throw error;
    }

    const data = await response.json();
    console.log("Session creation response:", data);

    if (!data.id || !data.pickerUri) {
      throw new Error("Invalid session response");
    }

    return {
      sessionId: data.id,
      pickerUri: data.pickerUri,
    };
  } catch (error) {
    console.error("Failed to create picker session:", error);
    throw error;
  }
};

// Backoff configuration
const BACKOFF_CONFIG = {
  initialDelay: 1000, // Start with 1 second
  maxDelay: 30000, // Max 30 seconds between retries
  maxAttempts: 5, // Maximum number of retry attempts
  backoffFactor: 2, // Double the delay each time
};

// Helper function for exponential backoff
const calculateBackoff = (attempt) => {
  const delay = Math.min(
    BACKOFF_CONFIG.initialDelay *
      Math.pow(BACKOFF_CONFIG.backoffFactor, attempt),
    BACKOFF_CONFIG.maxDelay
  );
  // Add jitter to prevent thundering herd
  return delay + Math.random() * 1000;
};

// Helper to determine if we should retry based on error
const shouldRetry = (error) => {
  // Retry on network errors and 5xx server errors
  if (!error.status) return true; // Network error
  if (error.status >= 500 && error.status < 600) return true;

  // Don't retry on client errors (except 429 rate limit)
  if (error.status === 429) return true;
  if (error.status >= 400 && error.status < 500) return false;

  return true;
};

// Retrier with exponential backoff
const withBackoff = async (operation, context = "") => {
  let attempt = 0;

  while (attempt < BACKOFF_CONFIG.maxAttempts) {
    try {
      return await operation();
    } catch (error) {
      attempt++;

      // If it's our last attempt, throw the error
      if (attempt === BACKOFF_CONFIG.maxAttempts) {
        throw error;
      }

      // Check if we should retry based on error type
      if (!shouldRetry(error)) {
        throw error;
      }

      const delay = calculateBackoff(attempt);
      console.log(
        `${context} failed (attempt ${attempt}/${BACKOFF_CONFIG.maxAttempts}). ` +
          `Retrying in ${Math.round(delay / 1000)}s...`
      );

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

// Poll session status with backoff
const pollSession = async (sessionId) => {
  return withBackoff(async () => {
    const accessToken = await refreshAccessToken();
    if (!accessToken) {
      throw new Error("Failed to get access token");
    }

    const response = await fetch(
      `https://photospicker.googleapis.com/v1/sessions/${sessionId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to poll session: ${response.status}`);
    }

    const data = await response.json();
    console.log("Poll response:", data);

    return {
      state: data.state,
      mediaItemsSet: data.mediaItemsSet,
      pollIntervalMs: data.pollingConfig?.pollIntervalMs || 5000,
      timeoutMs: data.pollingConfig?.timeoutMs,
    };
  }, "Polling session");
};

const fetchSelectedMediaItems = async (sessionId) => {
  return withBackoff(async () => {
    const accessToken = await refreshAccessToken();
    if (!accessToken) {
      throw new Error("Failed to get access token");
    }

    let allMediaItems = [];
    let nextPageToken = null;

    do {
      const response = await fetch(
        `https://photospicker.googleapis.com/v1/mediaItems?sessionId=${sessionId}&pageSize=100${
          nextPageToken ? `&pageToken=${nextPageToken}` : ""
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch media items: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Media items response:", data);

      // Validate and map media items
      const formattedMediaItems = (data.mediaItems || []).map((item, index) => {
        // Validate that required fields exist
        if (!item.id || !item.mediaFile?.baseUrl) {
          console.error(
            `Invalid item at index ${index}:`,
            JSON.stringify(item, null, 2)
          );
          throw new Error(
            `Media item at index ${index} is missing required fields`
          );
        }

        // Map to the expected format
        return {
          id: item.id,
          baseUrl: item.mediaFile.baseUrl,
          mimeType: item.mediaFile.mimeType || "image/jpeg",
          filename: item.mediaFile.filename || `photo_${item.id}.jpg`,
          mediaMetadata: {
            creationTime: item.createTime || new Date().toISOString(),
          },
          type: item.type,
        };
      });

      allMediaItems = allMediaItems.concat(formattedMediaItems);
      console.log(
        `Fetched ${formattedMediaItems.length} items, Total so far: ${allMediaItems.length}`
      );

      nextPageToken = data.nextPageToken;
    } while (nextPageToken);

    console.log(
      `Formatted ${allMediaItems.length} media items successfully.`,
      "Sample item:",
      allMediaItems[0]
    );

    return allMediaItems;
  }, "Fetching media items");
};

// Delete session with backoff
const deleteSession = async (sessionId) => {
  return withBackoff(async () => {
    const accessToken = await refreshAccessToken();
    if (!accessToken) {
      throw new Error("Failed to get access token");
    }
    const response = await fetch(
      `https://photospicker.googleapis.com/v1/sessions/${sessionId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Don't throw on 404 as session might already be gone
    if (!response.ok && response.status !== 404) {
      throw new Error(`Failed to delete session: ${response.status}`);
    }
  }, "Deleting session");
};

export {
  createPickerSession,
  pollSession,
  fetchSelectedMediaItems,
  deleteSession,
  refreshAccessToken,
};
