import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import "../styles/ExpensePolicyStatus.css";

const ExpensePolicyStatus = ({
  clientEmail,
  investmentManager,
  onManagePolicies,
}) => {
  // Use a local loading state inside the component
  const [localLoading, setLocalLoading] = useState(true);
  const [policyStatus, setPolicyStatus] = useState({
    hasPolicy: false,
    policyName: "",
    policyUrl: "",
    policyId: "",
  });

  // Add a local refresh trigger
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Listen for the custom event
  useEffect(() => {
    const handlePolicyChange = () => {
      console.log("Policy change event detected in ExpensePolicyStatus");
      setRefreshTrigger((prev) => prev + 1);
    };

    window.addEventListener("policy-document-changed", handlePolicyChange);

    return () => {
      window.removeEventListener("policy-document-changed", handlePolicyChange);
    };
  }, []);

  useEffect(() => {
    console.log(
      "ExpensePolicyStatus effect running, refreshTrigger:",
      refreshTrigger
    );

    let isMounted = true; // For handling async operations when component unmounts

    const fetchPolicyStatus = async () => {
      if (!investmentManager) {
        if (isMounted) {
          setLocalLoading(false);
        }
        return;
      }

      try {
        console.log("Fetching policy status...");
        setLocalLoading(true);

        // Use your existing /api/documents endpoint
        const response = await axios.get("/api/documents", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          // For accountant view, we may need to pass clientEmail as a parameter
          ...(clientEmail &&
            clientEmail !== "undefined" && {
              params: { clientEmail },
            }),
        });

        if (isMounted) {
          if (response.data.success) {
            // Find policy document from the list of documents
            const documents = response.data.documents;
            console.log("Documents fetched:", documents.length);

            const policyDoc = documents.find(
              (doc) =>
                doc.type === "policy" ||
                (doc.name && doc.name.toLowerCase().includes("policy"))
            );

            if (policyDoc) {
              console.log("Policy document found:", policyDoc.name);
              // Calculate URL based on driveFileId or other document properties
              const policyUrl = policyDoc.driveFileId
                ? `https://drive.google.com/file/d/${policyDoc.driveFileId}/view`
                : policyDoc.url || "";

              setPolicyStatus({
                hasPolicy: true,
                policyName: policyDoc.name || "Expense Policy",
                policyUrl: policyUrl,
                policyId: policyDoc.id,
              });
            } else {
              console.log("No policy document found");
              setPolicyStatus({
                hasPolicy: false,
                policyName: "",
                policyUrl: "",
                policyId: "",
              });
            }
          } else {
            throw new Error(response.data.error || "Failed to fetch documents");
          }

          // Set loading to false AFTER state updates
          setLocalLoading(false);
        }
      } catch (error) {
        console.error("Error fetching policy status:", error);
        if (isMounted) {
          setPolicyStatus({
            hasPolicy: false,
            policyName: "",
            policyUrl: "",
            policyId: "",
          });
          setLocalLoading(false);
        }
      }
    };

    // Use a timeout to defer the API call until after the reports have loaded
    const timeoutId = setTimeout(() => {
      if (investmentManager && isMounted) {
        fetchPolicyStatus();
      } else if (!investmentManager && isMounted) {
        setLocalLoading(false);
      }
    }, 1000); // Delay by 1 second

    return () => {
      isMounted = false; // Cleanup to prevent state updates if component unmounts
      clearTimeout(timeoutId); // Clean up the timeout
    };
  }, [clientEmail, investmentManager, refreshTrigger]); // Use refreshTrigger instead of documentVersion

  const handleManagePoliciesClick = () => {
    // Just call the parent prop if defined
    if (onManagePolicies) {
      onManagePolicies();
    }
  };

  if (!investmentManager) return null;

  return (
    <div className="expense-policy-status">
      {localLoading ? (
        <div className="policy-loading">
          <TailSpin color="#0033a0" height={20} width={20} />
          <span>Checking policy status...</span>
        </div>
      ) : policyStatus.hasPolicy ? (
        <div className="policy-available">
          <a
            href={policyStatus.policyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="policy-link"
          >
            <i className="fas fa-file-alt"></i>
            <span>{policyStatus.policyName}</span>
          </a>
          <button
            className="policy-manage-button"
            onClick={handleManagePoliciesClick}
          >
            <i className="fas fa-cog"></i>
            <span>Manage Policies</span>
          </button>
        </div>
      ) : (
        <button
          className="policy-upload-button"
          onClick={handleManagePoliciesClick}
        >
          <i className="fas fa-file-upload"></i>
          <span>Upload Expense Policy</span>
        </button>
      )}
    </div>
  );
};

export default ExpensePolicyStatus;
