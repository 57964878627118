// src/components/PdfUploader.jsx
import React from "react";
import BaseUploader from "./BaseUploader";
import axios from "axios";

const PdfUploader = ({ onClose, onPdfSelected, onUploadProgress, onError }) => {
  // PDF-specific configurations
  const config = {
    acceptTypes: "application/pdf",
    maxFileSize: 20 * 1024 * 1024, // 20MB
    maxFiles: 25,
    fileTypeName: "PDF",
    buttonText: {
      selectInitial: "Select PDF Receipt(s)",
      selectMore: "Add More PDFs",
    },
    fileTypeValidationFn: (file) => file.type === "application/pdf",
    processingText: "Uploading",
  };

  // Process PDFs - updated to handle tags
  const processPdfFiles = async (
    filesWithTags,
    progressCallback,
    errorCallback
  ) => {
    // Calculate progress increment per file
    const validFiles = filesWithTags.map((item) => item.file);
    const progressIncrement = 100 / validFiles.length;
    let currentProgress = 0;

    // Log the files to process with their tags
    console.log(
      "Processing PDFs with tags:",
      filesWithTags.map((item) => ({
        filename: item.file.name,
        tag: item.tag,
      }))
    );

    // Array to hold processed items
    const pdfItems = [];

    // Process files sequentially for better progress tracking
    for (const fileItem of filesWithTags) {
      const file = fileItem.file;
      const tag = fileItem.tag;

      try {
        // Generate a unique file ID
        const fileId = `pdf-${Date.now()}-${Math.random()
          .toString(36)
          .substring(7)}`;

        // Initial progress update
        progressCallback(Math.round(currentProgress));

        // Get a signed URL for this file
        const response = await axios.post(
          "/api/get-upload-url",
          {
            filename: file.name,
            contentType: "application/pdf",
            fileType: "pdf", // Specify PDF type
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        const { signedUrl, gcsFileName } = response.data;
        const baseProgress = currentProgress;

        // Upload directly to GCS
        await axios.put(signedUrl, file, {
          headers: {
            "Content-Type": "application/pdf",
          },
          onUploadProgress: (progressEvent) => {
            const fileProgress = progressEvent.loaded / progressEvent.total;
            const additionalProgress = fileProgress * progressIncrement;
            progressCallback(Math.round(baseProgress + additionalProgress));
          },
        });

        // Return the PDF item with GCS URL, tag, and other metadata
        pdfItems.push({
          id: fileId,
          type: "pdf",
          filename: file.name,
          gcsPath: gcsFileName,
          baseUrl: signedUrl.split("?")[0], // Include the base URL for immediate use
          mediaMetadata: {
            creationTime: new Date().toISOString(),
          },
          mimeType: "application/pdf",
          contentType: "application/pdf",
          tag: tag, // Include the selected tag
        });

        // Update progress after successful upload
        currentProgress += progressIncrement;
      } catch (error) {
        console.error(`Error processing PDF file ${file.name}:`, error);
        errorCallback?.(`Failed to upload ${file.name}: ${error.message}`);

        // Move to next file
        currentProgress += progressIncrement;
      }
    }

    // Ensure progress is at 100% when done
    progressCallback(100);

    return pdfItems;
  };

  return (
    <BaseUploader
      onClose={onClose}
      onFilesSelected={onPdfSelected}
      onError={onError}
      onUploadProgress={onUploadProgress}
      processFiles={processPdfFiles}
      autoOpenFileDialog={false} // Add this line to skip first screen
      {...config}
    />
  );
};

export default PdfUploader;
