import React from "react";
import { Download } from "lucide-react";

const InstallPrompt = ({ deferredPrompt, onDismiss }) => {
  if (!deferredPrompt) return null;

  const handleInstall = async () => {
    try {
      const result = await deferredPrompt.prompt();
      if (result.outcome === "accepted") {
        localStorage.setItem("installAccepted", "true");
        onDismiss && onDismiss();
        if (window.gtag) {
          window.gtag("event", "pwa_install", {
            event_category: "PWA",
            event_label: "Install Accepted",
          });
        }
      }
    } catch (error) {
      console.error("Install prompt error:", error);
    }
  };

  const handleMaybeLater = () => {
    localStorage.setItem("lastMaybeLater", Date.now().toString());
    onDismiss && onDismiss();
  };

  const handleNeverShow = () => {
    localStorage.setItem("neverShowInstall", "true");
    onDismiss && onDismiss();
  };

  return (
    <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-sm px-4">
      <div className="bg-white rounded-lg shadow-lg p-4 border border-gray-200">
        <div className="flex items-center gap-3 mb-4">
          <img
            src="/eblogo_192.png"
            alt="ExpenseBot"
            className="w-12 h-12 rounded-xl"
          />
          <div>
            <h4 className="font-medium text-gray-900">Add Shortcut to Home</h4>
            <p className="text-sm text-gray-600">
              For faster uploads and offline access
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={handleInstall}
            className="w-full px-4 py-3 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2"
          >
            <Download size={16} />
            Add Shortcut
          </button>
          <button
            onClick={handleMaybeLater}
            className="w-full px-4 py-3 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
          >
            Remind Me Later
          </button>
          <button
            onClick={handleNeverShow}
            className="text-sm text-gray-500 hover:text-gray-700 py-2"
          >
            Don't show again
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstallPrompt;
