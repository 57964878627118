// src/components/ReportWizardModal.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import "../styles/ReportWizardModal.css";

const ReportWizardModal = ({ isOpen, onClose, onReportSubmitted }) => {
  // WIZARD STEPS: 1 = Tag Selection, 2 = Preview, 3 = Success
  const [step, setStep] = useState(1);

  // STEP 1: Tag selection
  const [tags, setTags] = useState([]);
  // New state to track selected tags for this report only
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tagsSaving, setTagsSaving] = useState(false);
  const [tagError, setTagError] = useState(null);

  // STEP 2: Preview data
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [functionalTotal, setFunctionalTotal] = useState(0);
  const [homeCurrency, setHomeCurrency] = useState(null);
  const [hasEmptyColumnR, setHasEmptyColumnR] = useState(false);
  const [reportTitle, setReportTitle] = useState("");

  // Email recipients state
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [emailInputValue, setEmailInputValue] = useState("");
  const [emailsLoading, setEmailsLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [initialEmailsLoaded, setInitialEmailsLoaded] = useState(false);

  // STEP 3: Submission
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("");

  // New Styles
  // Add these near your existing state variables
  const [reportType, setReportType] = useState("receipt_report");
  const [receiptCount, setReceiptCount] = useState(0);
  const [billCount, setBillCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [hasMixedDocuments, setHasMixedDocuments] = useState(false);
  const [treatInvoicesAsReceipts, setTreatInvoicesAsReceipts] = useState(true);

  /**
   * Whenever the modal opens, reset state so each time
   * is a fresh wizard experience.
   */
  useEffect(() => {
    if (isOpen) {
      setStep(1);

      // Clear Step 1 state
      setTags([]);
      setSelectedTagIds([]); // Reset selected tags
      setTagsLoading(false);
      setTagsSaving(false);
      setTagError(null);
      setReportTitle(""); // Reset the title

      // Clear Step 2 state
      setPreviewLoading(false);
      setPreviewError(null);
      setReportData([]);
      setFunctionalTotal(0);
      setHomeCurrency(null);
      setHasEmptyColumnR(false);

      // Clear email state
      setEmailRecipients([]);
      setEmailInputValue("");
      setEmailsLoading(false);
      setEmailError(null);
      setInitialEmailsLoaded(false);

      // Clear Step 3 state
      setSubmitLoading(false);
      setSubmitError(null);
      setSubmitSuccessMessage("");
    }
  }, [isOpen]);

  // Add this component inside the main function
  const CollapsibleSection = ({
    title,
    icon,
    children,
    defaultOpen = false,
  }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    return (
      <div className="collapsible-section">
        <button
          className="collapsible-header"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
        >
          {icon && <span className="collapsible-icon-prefix">{icon}</span>}
          <span className="collapsible-title">{title}</span>
          <span className={`collapsible-arrow ${isOpen ? "open" : ""}`}>▼</span>
        </button>
        {isOpen && <div className="collapsible-content">{children}</div>}
      </div>
    );
  };

  /********************************
   * Step Indicator
   ********************************/
  const renderStepIndicator = () => {
    return (
      <div className="report-steps-progress">
        <div className="steps-container">
          <div
            className={`step-item ${step >= 1 ? "active" : ""} ${
              step > 1 ? "completed" : ""
            }`}
          >
            <div className="step-circle">
              {step > 1 ? <span className="step-check">✓</span> : "1"}
            </div>
            <div className="step-label">Select Tags</div>
          </div>

          <div className="step-connector"></div>

          <div
            className={`step-item ${step >= 2 ? "active" : ""} ${
              step > 2 ? "completed" : ""
            }`}
          >
            <div className="step-circle">
              {step > 2 ? <span className="step-check">✓</span> : "2"}
            </div>
            <div className="step-label">Preview Report</div>
          </div>

          <div className="step-connector"></div>

          <div className={`step-item ${step === 3 ? "active" : ""}`}>
            <div className="step-circle">3</div>
            <div className="step-label">Complete</div>
          </div>
        </div>
      </div>
    );
  };

  /********************************
   * Step 1: Tag Selection
   ********************************/
  useEffect(() => {
    // When user enters Step 1 (and modal is open), fetch tags
    if (isOpen && step === 1) {
      fetchTags();
    }
  }, [isOpen, step]);

  const fetchTags = async () => {
    try {
      setTagsLoading(true);
      setTagError(null);

      const res = await axios.get("/api/tags", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (res.data.success) {
        setTags(res.data.tags);
        // Initialize selectedTagIds with tags that are currently active
        setSelectedTagIds(
          res.data.tags.filter((tag) => tag.isActive).map((tag) => tag.tag)
        );
      } else {
        setTagError("Failed to fetch tags.");
      }
    } catch (err) {
      console.error("Error fetching tags:", err);
      setTagError("An error occurred while loading tags.");
    } finally {
      setTagsLoading(false);
    }
  };

  const handleToggleTag = (index) => {
    const tag = tags[index];

    setSelectedTagIds((prev) => {
      // If tag is already selected, remove it, otherwise add it
      if (prev.includes(tag.tag)) {
        return prev.filter((id) => id !== tag.tag);
      } else {
        return [...prev, tag.tag];
      }
    });
  };

  const handleSaveTagsAndNext = async () => {
    try {
      setTagsSaving(true);
      setTagError(null);

      // No need to update tags on the server, just proceed to the next step
      // and pass the selected tags for filtering in fetchPreviewData
      await fetchPreviewData();
      setStep(2);
    } catch (err) {
      console.error("Error preparing preview:", err);
      setTagError("An error occurred while preparing your report preview.");
    } finally {
      setTagsSaving(false);
    }
  };

  /********************************
   * Step 2: Preview
   ********************************/
  const fetchPreviewData = async () => {
    try {
      setPreviewLoading(true);
      setPreviewError(null);

      // Pass the selected tags as a query parameter
      const response = await axios.get("/api/getReportData", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          selectedTags: selectedTagIds,
        },
      });

      const {
        reportData,
        functionalTotal,
        homeCurrency,
        hasEmptyColumnR,
        message,
      } = response.data;

      if (reportData?.length > 0) {
        // Update document analysis states from server response
        if (response.data.documentAnalysis) {
          const analysis = response.data.documentAnalysis;
          setReportType(analysis.reportType);
          setReceiptCount(analysis.receiptCount);
          setBillCount(analysis.billCount);
          setInvoiceCount(analysis.invoiceCount);
          setHasMixedDocuments(analysis.hasMixedDocuments);
        }

        // Only set default title if no title has been set yet
        if (!reportTitle) {
          const today = new Date().toLocaleDateString();
          const typeLabel =
            reportType === "receipt_report" ? "Receipts" : "Invoices";
          const defaultTitle = `Expense Report - ${typeLabel} - ${today}`;
          setReportTitle(defaultTitle);
        }

        // Rest of your existing code...
        setReportData(reportData);
        setFunctionalTotal(functionalTotal);
        setHomeCurrency(homeCurrency);
        setHasEmptyColumnR(hasEmptyColumnR);

        // Fetch email recipients if we haven't loaded them yet
        if (!initialEmailsLoaded) {
          fetchEmailRecipients();
        }
      } else {
        // Your existing error handling...
        setPreviewError(
          message || "No outstanding expenses. Check your tags or receipts."
        );
      }
    } catch (err) {
      console.error("Error fetching preview data:", err);
      setPreviewError(
        err.response?.data?.message ||
          "An error occurred while generating the preview."
      );
    } finally {
      setPreviewLoading(false);
    }
  };

  /********************************
   * Email Recipients Management
   ********************************/
  const fetchEmailRecipients = async () => {
    try {
      setEmailsLoading(true);
      setEmailError(null);

      // Try to get emails from most recent report first
      const recentReportResponse = await axios.get("/api/report-latest", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (
        recentReportResponse.data.success &&
        recentReportResponse.data.report &&
        (recentReportResponse.data.report.shared_with?.length > 0 ||
          recentReportResponse.data.report.sentTo?.length > 0)
      ) {
        // Use recipients from most recent report
        const recipients =
          recentReportResponse.data.report.shared_with ||
          recentReportResponse.data.report.sentTo ||
          [];
        console.log("Using recipients from previous report:", recipients);
        setEmailRecipients(recipients);
      } else {
        // Fall back to spreadsheet settings if no previous reports with recipients
        const settingsResponse = await axios.get("/api/email-settings", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        if (
          settingsResponse.data.success &&
          settingsResponse.data.emailRecipients
        ) {
          // Parse comma-separated emails into an array
          const emails = settingsResponse.data.emailRecipients
            .split(",")
            .map((email) => email.trim())
            .filter((email) => email !== "");

          console.log("Using recipients from spreadsheet settings:", emails);
          setEmailRecipients(emails);
        } else {
          // Start with empty array if no previous sources have data
          console.log("No previous recipients found, starting with empty list");
          setEmailRecipients([]);
        }
      }

      setInitialEmailsLoaded(true);
    } catch (err) {
      console.error("Error fetching email recipients:", err);
      setEmailError("Failed to load previous recipients.");
      // If there's an error, start with an empty array
      setEmailRecipients([]);
      setInitialEmailsLoaded(true);
    } finally {
      setEmailsLoading(false);
    }
  };

  const handleAddEmail = () => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInputValue)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Check if email already exists
    if (emailRecipients.includes(emailInputValue)) {
      setEmailError("This email is already in the list.");
      return;
    }

    // Add email to the list
    setEmailRecipients([...emailRecipients, emailInputValue]);
    setEmailInputValue(""); // Clear input
    setEmailError(null);
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmailRecipients(
      emailRecipients.filter((email) => email !== emailToRemove)
    );
  };

  const handleClearAllEmails = () => {
    setEmailRecipients([]);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && emailInputValue) {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return "0";
    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
    };
    const symbol = currencyMap[currency] || currency || "";
    return currencyMap[currency]
      ? `${symbol}${Number(amount).toLocaleString()}`
      : `${Number(amount).toLocaleString()} ${symbol}`;
  };

  // "Back" button from Step 2 -> Step 1
  const handlePreviewGoBack = () => {
    setStep(1);
  };

  const handleSubmitReport = async () => {
    try {
      setSubmitLoading(true);
      setSubmitError(null);
      setSubmitSuccessMessage("");

      // Create report metadata
      const reportMetadata = {
        reportType: reportType,
        documentCounts: {
          receipts: receiptCount,
          bills: billCount,
          invoices: invoiceCount,
        },
        mixedDocumentHandling: hasMixedDocuments
          ? reportType === "receipt_report"
            ? treatInvoicesAsReceipts
              ? "treated_as_receipts"
              : "excluded_invoices"
            : "excluded_receipts"
          : null,
      };

      // Send the report with metadata
      const res = await axios.post(
        "/api/getReportData",
        {
          action: "submit",
          reportTitle: reportTitle.trim() || undefined,
          emailRecipients: emailRecipients,
          reportMetadata: reportMetadata,
          selectedTags: selectedTagIds,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (res.data.success) {
        setSubmitSuccessMessage(res.data.message);
        setStep(3);

        // Call the callback to update the parent component
        if (onReportSubmitted && typeof onReportSubmitted === "function") {
          onReportSubmitted(res.data);
        }
      } else {
        setSubmitError(
          res.data.message || "An error occurred while creating the report."
        );
      }
    } catch (err) {
      console.error("Error creating report:", err);
      const serverMessage =
        err.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      setSubmitError(serverMessage);
    } finally {
      setSubmitLoading(false);
    }
  };

  /********************************
   * Step 3: Success
   ********************************/
  const handleCloseWizard = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="report-modal-backdrop" onClick={handleCloseWizard}>
      <div
        className="report-modal-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button className="report-modal-close" onClick={handleCloseWizard}>
          &times;
        </button>

        {/* Header */}
        <div className="report-modal-header">
          <h2 className="report-modal-title">Report Wizard</h2>
          {/* Step Indicator */}
          {renderStepIndicator()}
        </div>

        {/* Body - We use a single container and conditionally render content based on step */}
        <div className="report-modal-content">
          {/* Step 1: Tag Selection */}
          {step === 1 && (
            <div className="tag-selection-container">
              <h3>Select Tags</h3>
              <p>
                Only expenses where the 'Tags' column matches one of the
                selected tags below will be included in the generated report.
              </p>

              {/* Add the "What are Tags?" info box */}
              <div className="tags-info-box">
                <span className="tags-info-icon">i</span>
                <div className="tags-info-content">
                  <div className="tags-info-title">What are Tags?</div>
                  <p>
                    Tags organize your expenses by client, project, or purpose.
                    Select one or more tags below to group related expenses into
                    a single report. Only unprocessed expenses (those not
                    included in previous reports) will appear in your final
                    report. You can manage your tags in the Settings section if
                    needed.
                  </p>
                </div>
              </div>

              {tagsLoading ? (
                <div className="loading-state" style={{ marginTop: "20px" }}>
                  <TailSpin color="#0033a0" height={20} width={20} />
                  <span>Loading tags...</span>
                </div>
              ) : (
                <>
                  {tags.length === 0 && !tagError && (
                    <p>No tags found in your spreadsheet.</p>
                  )}

                  <div className="tags-list">
                    {tags.map((tagItem, index) => (
                      <label className="tag-row" key={tagItem.tag}>
                        <input
                          type="checkbox"
                          checked={selectedTagIds.includes(tagItem.tag)}
                          onChange={() => handleToggleTag(index)}
                        />
                        <span>{tagItem.tag}</span>
                      </label>
                    ))}
                  </div>

                  {tagError && <div className="error-message">{tagError}</div>}
                </>
              )}
            </div>
          )}

          {/* Step 2: Preview */}
          {step === 2 && (
            <div className="preview-container">
              {previewLoading ? (
                <>
                  <h3>Preview Report</h3>
                  <div className="loading-state">
                    <TailSpin color="#0033a0" height={20} width={20} />
                    <span>Calculating preview...</span>
                  </div>
                </>
              ) : previewError ? (
                /* Keep the error state as is */
                <div className="no-expenses-message">
                  <h3>No Expenses Available</h3>
                  <div className="message-box">
                    <i className="fas fa-info-circle"></i>
                    <p>
                      {previewError ||
                        "There are no outstanding expenses that match your selected tags."}
                    </p>
                  </div>
                  <p className="suggestion-text">
                    Try selecting different tags or check if you have
                    unsubmitted receipts.
                  </p>
                  <button
                    className="report-secondary-button"
                    onClick={handlePreviewGoBack}
                  >
                    Back to Tags
                  </button>
                </div>
              ) : reportData.length === 0 ? (
                /* Keep the no data state as is */
                <div className="no-expenses-message">
                  <h3>No Expenses Available</h3>
                  <div className="message-box">
                    <i className="fas fa-info-circle"></i>
                    <p>
                      There are no unsubmitted expenses with the selected tags.
                    </p>
                  </div>
                  <p className="suggestion-text">
                    Try selecting different tags or check if you have
                    unsubmitted receipts.
                  </p>
                  <button
                    className="report-secondary-button"
                    onClick={handlePreviewGoBack}
                  >
                    Back to Tags
                  </button>
                </div>
              ) : (
                <>
                  {/* Report Title - Keep outside collapsible sections */}
                  <div className="report-title-input-container">
                    <label htmlFor="report-title">Report Title:</label>
                    <input
                      type="text"
                      id="report-title"
                      className="report-title-input"
                      value={reportTitle}
                      onChange={(e) => setReportTitle(e.target.value)}
                      placeholder="Enter report title"
                    />
                  </div>

                  <div
                    className={`report-type-banner ${
                      reportType === "receipt_report" ? "receipt" : "invoice"
                    }`}
                  >
                    <span className="report-type-icon">
                      {reportType === "receipt_report" ? "🧾" : "📄"}
                    </span>
                    <div className="report-banner-content">
                      <span className="report-type-label">
                        {reportType === "receipt_report"
                          ? "Receipt Report"
                          : "Invoice Report"}
                        <span className="inline-metrics">
                          <span className="inline-metrics-value">
                            {receiptCount + billCount + invoiceCount}
                          </span>
                          Items
                          <span style={{ margin: "0 6px" }}>•</span>
                          <span className="inline-metrics-value">
                            {
                              [
                                ...new Set(
                                  reportData.map((item) => item.currency)
                                ),
                              ].length
                            }
                          </span>
                          {[...new Set(reportData.map((item) => item.currency))]
                            .length === 1
                            ? "Currency"
                            : "Currencies"}
                          <span style={{ margin: "0 6px" }}>•</span>
                          <span className="inline-metrics-value">
                            {
                              new Set(
                                reportData.flatMap((item) =>
                                  item.accounts.map(
                                    (account) => account.account
                                  )
                                )
                              ).size
                            }
                          </span>
                          {new Set(
                            reportData.flatMap((item) =>
                              item.accounts.map((account) => account.account)
                            )
                          ).size === 1
                            ? "Tag"
                            : "Tags"}
                        </span>
                      </span>
                    </div>
                  </div>

                  {/* Mixed Document Alert - Keep visible if present */}
                  {hasMixedDocuments && (
                    <div className="mixed-document-alert">
                      <div className="alert-content">
                        {reportType === "receipt_report" ? (
                          <>
                            <p>
                              <i className="fas fa-info-circle"></i>
                              We found {invoiceCount} invoice
                              {invoiceCount !== 1 ? "s" : ""} in your receipt
                              report.
                            </p>
                            <div className="alert-action">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={treatInvoicesAsReceipts}
                                  onChange={() =>
                                    setTreatInvoicesAsReceipts(
                                      !treatInvoicesAsReceipts
                                    )
                                  }
                                />
                                <span>
                                  Treat{" "}
                                  {invoiceCount !== 1
                                    ? "these invoices"
                                    : "this invoice"}{" "}
                                  as paid receipt{invoiceCount !== 1 ? "s" : ""}
                                </span>
                              </label>
                            </div>
                          </>
                        ) : (
                          <p>
                            <i className="fas fa-info-circle"></i>
                            We found {receiptCount + billCount} receipt
                            {receiptCount + billCount !== 1 ? "s" : ""} in your
                            invoice report. These will be excluded.
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Show functional total prominently outside collapsible sections */}
                  <div className="report-functional-total report-total-row">
                    <span className="report-total-label">
                      Functional Total:
                    </span>
                    <span className="report-total-value">
                      {formatCurrency(functionalTotal, homeCurrency)}
                    </span>
                  </div>

                  {/* Collapsible sections for details */}
                  <CollapsibleSection
                    title="Currency & Tag Breakdown"
                    icon="💰"
                    defaultOpen={false}
                  >
                    {reportData.map(({ currency, accounts, totalCurrency }) => (
                      <div key={currency} className="report-currency-group">
                        <h4 className="report-currency-title">{currency}</h4>
                        {accounts.map(({ account, total }) => (
                          <div className="report-total-row" key={account}>
                            <span className="report-total-label">
                              Tag: {account}
                            </span>
                            <span className="report-total-value">
                              {formatCurrency(total, currency)}
                            </span>
                          </div>
                        ))}
                        <div className="report-total-row">
                          <span className="report-total-label">
                            Total {currency}:
                          </span>
                          <span className="report-total-value">
                            {formatCurrency(totalCurrency, currency)}
                          </span>
                        </div>
                      </div>
                    ))}

                    {/* If empty functional total cells found */}
                    {hasEmptyColumnR && (
                      <div className="report-alert">
                        <i className="fas fa-exclamation-triangle" />
                        Some receipts have an empty "Func. Total." Fill in the
                        converted amount in your spreadsheet if needed.
                      </div>
                    )}
                  </CollapsibleSection>

                  {/* Share Settings Collapsible Section */}
                  <CollapsibleSection
                    title="Share Report"
                    icon="👥"
                    defaultOpen={true}
                  >
                    <div className="email-recipients-section">
                      {emailsLoading ? (
                        <div className="loading-state">
                          <TailSpin color="#0033a0" height={20} width={20} />
                          <span>Loading recipient settings...</span>
                        </div>
                      ) : (
                        <>
                          <p className="email-description">
                            The following users will have access to view and
                            manage this expense report:
                          </p>

                          <div className="email-management">
                            <div className="email-input-container">
                              <input
                                type="email"
                                value={emailInputValue}
                                onChange={(e) => {
                                  setEmailInputValue(e.target.value);
                                  if (emailError) setEmailError(null);
                                }}
                                onKeyPress={handleKeyPress}
                                placeholder="Enter email address"
                                className="email-input"
                              />
                              <button
                                className="add-email-button"
                                onClick={handleAddEmail}
                                disabled={!emailInputValue}
                              >
                                Add
                              </button>
                            </div>

                            {emailError && (
                              <div className="email-error-message">
                                {emailError}
                              </div>
                            )}

                            <div className="email-recipients-list">
                              {emailRecipients.length > 0 ? (
                                <>
                                  {emailRecipients.map((email, index) => (
                                    <div
                                      key={index}
                                      className="email-recipient"
                                    >
                                      <span className="email-text">
                                        {email}
                                      </span>
                                      <button
                                        className="remove-email-button"
                                        onClick={() => handleRemoveEmail(email)}
                                        title="Remove this email"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  ))}
                                  <button
                                    className="clear-emails-button"
                                    onClick={handleClearAllEmails}
                                  >
                                    Clear All
                                  </button>
                                </>
                              ) : (
                                <p className="no-recipients-message">
                                  No recipients have been added yet. The report
                                  will still be created but no notifications
                                  will be sent.
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </CollapsibleSection>
                </>
              )}
            </div>
          )}

          {/* Step 3: Success */}
          {step === 3 && (
            <div className="report-success-section">
              <h3>Report Created</h3>
              {submitSuccessMessage && (
                <div className="success-message">{submitSuccessMessage}</div>
              )}
              {submitError && (
                <div className="error-message">{submitError}</div>
              )}
              <p>
                Find this report at the top of Your Reports section on this
                page. Tap the email icon to share with someone.
              </p>
            </div>
          )}
        </div>

        {/* Footer / Buttons */}
        <div className="wizard-footer">
          {/* Step 1 Footer */}
          {step === 1 && (
            <button
              className="report-submit-button"
              onClick={handleSaveTagsAndNext}
              disabled={tagsLoading || tagsSaving}
            >
              {tagsSaving ? "Saving..." : "Next"}
            </button>
          )}

          {/* Step 2 Footer */}
          {step === 2 &&
            !previewLoading &&
            !previewError &&
            reportData.length > 0 && (
              <div style={{ display: "flex", gap: "10px" }}>
                {/* "Back" button to return to step 1 */}
                <button
                  className="report-secondary-button"
                  onClick={handlePreviewGoBack}
                >
                  Back
                </button>
                <button
                  className="report-submit-button"
                  onClick={handleSubmitReport}
                  disabled={submitLoading}
                >
                  <div className="button-content">
                    {submitLoading ? (
                      <>
                        <span className="spinner">⟳</span>
                        <span>Creating...</span>
                      </>
                    ) : (
                      "Create Report"
                    )}
                  </div>
                </button>
              </div>
            )}

          {/* Step 3 Footer */}
          {step === 3 && (
            <button
              className="report-submit-button"
              onClick={handleCloseWizard}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportWizardModal;
