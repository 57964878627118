import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import ShareToQuickBooks from "./ShareToQuickBooks";
import ComplianceCheck from "./ComplianceCheck";
import ShareToWave from "./ShareToWave";
import ExpensePolicyStatus from "./ExpensePolicyStatus";
import ComplianceDocumentsModal from "./ComplianceDocumentsModal";
import { useCompliance } from "./ComplianceContext";
import { toast } from "react-toastify";

import "../styles/ProcessInfo.css";

const AccountantView = ({ clientEmail }) => {
  const { hasComplianceDocuments } = useCompliance();
  const [downloadingReportId, setDownloadingReportId] = useState(null);
  const [showComplianceDocs, setShowComplianceDocs] = useState(false);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const highlightedReportId = searchParams.get("reportId");
  const highlightedReportRef = useRef(null);
  const [investmentManager, setInvestmentManager] = useState(
    location.state?.investmentManager || true
  );
  const currentUserEmail = location.state?.currentUserEmail;

  // Grab the roles or just the boolean

  const [accountantData, setAccountantData] = useState(null);
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingAccountantView, setIsLoadingAccountantView] = useState(false);
  const [quickbooksConnectionStatus, setQuickbooksConnectionStatus] =
    useState(false);
  const [reportFilter, setReportFilter] = useState("All");
  const [editingReportId, setEditingReportId] = useState(null);
  const [editedNote, setEditedNote] = useState("");
  const navigate = useNavigate();

  const [waveConnectionStatus, setWaveConnectionStatus] = useState(false);
  const SHOW_WAVE_BUTTON = false; // Set to `true` if you want it visible

  // Add this effect to scroll to the highlighted report
  useEffect(() => {
    if (highlightedReportId && highlightedReportRef.current) {
      highlightedReportRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      // Add a highlight effect
      highlightedReportRef.current.classList.add("highlighted-report");

      // Remove the highlight effect after a delay
      const timer = setTimeout(() => {
        if (highlightedReportRef.current) {
          highlightedReportRef.current.classList.remove("highlighted-report");
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [highlightedReportId, reports]);

  useEffect(() => {
    const checkWaveConnection = async () => {
      if (reports.length > 0) {
        try {
          const waveResponse = await fetch("/api/wave/status", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (waveResponse.ok) {
            const status = await waveResponse.json();
            setWaveConnectionStatus(status?.connected || false);
          }
        } catch (error) {
          console.error("Error checking Wave connection:", error);
          setWaveConnectionStatus(false);
        }
      }
    };

    checkWaveConnection();
  }, [reports]);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") {
        setEditingReportId(null);
      }
    };

    if (editingReportId) {
      window.addEventListener("keydown", handleEsc);
      return () => window.removeEventListener("keydown", handleEsc);
    }
  }, [editingReportId]);

  // Updated data fetching for new structure
  useEffect(() => {
    let isMounted = true;
    const fetchAccountantData = async () => {
      if (!clientEmail) {
        console.warn("⚠️ No client email provided. Skipping data fetch.");
        setReports([]);
        setAccountantData(null);
        return;
      }

      try {
        setIsLoadingAccountantView(true);

        console.log("📌 Fetching data for client:", clientEmail);
        const response = await axios.get(
          `/api/client-reports/${clientEmail}?page=${currentPage}&limit=10&filter=${reportFilter}${
            highlightedReportId ? `&reportId=${highlightedReportId}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        console.log("Response received:", response.data);

        if (!isMounted) return;

        if (response.data.success) {
          // Set basic client info
          setAccountantData(response.data.processInfo);

          // Extract investmentManager flag from the response
          // This assumes your API returns this information, you may need to adjust
          // the property path based on your actual API response structure
          const hasInvestmentManager =
            response.data.investmentManager ||
            response.data.processInfo?.investmentManager ||
            false;

          console.log(
            "Setting investmentManager flag to:",
            hasInvestmentManager
          );
          setInvestmentManager(hasInvestmentManager);

          // Set reports from the response
          console.log("Setting reports:", response.data.reports);
          setReports(response.data.reports || []);
          setInvestmentManager(response.data.investmentManager || false);

          // Set pagination data if available
          if (response.data.pagination) {
            setTotalPages(response.data.pagination.totalPages);
          }

          // Check QuickBooks connection if there are reports
          if (response.data.reports?.length > 0) {
            const qbResponse = await fetch("/api/quickbooks/status", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });

            if (qbResponse.ok && isMounted) {
              const status = await qbResponse.json();
              setQuickbooksConnectionStatus(status?.connected || false);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching accountant data:", error);
      } finally {
        if (isMounted) {
          setIsLoadingAccountantView(false);
        }
      }
    };

    fetchAccountantData();

    return () => {
      isMounted = false;
    };
  }, [
    clientEmail,
    currentPage,
    reportFilter,
    highlightedReportId,
    hasComplianceDocuments,
  ]); // Add dependencies for pagination and filtering

  const handleDownload = async (reportId, format) => {
    try {
      setDownloadingReportId(null); // Close the dropdown

      // Get the download using Axios with responseType blob
      // Include the clientEmail in the URL for accountant view
      const response = await axios({
        url: `/api/downloadReport/${reportId}/${format}?clientEmail=${encodeURIComponent(
          clientEmail
        )}`,
        method: "GET",
        responseType: "blob", // Important for handling file downloads
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Extract filename from Content-Disposition header if present
      let filename = `expense-report.${format}`;
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      // Create a temporary link and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // Show success toast if toast is available
      if (typeof toast !== "undefined") {
        toast.success(
          `Report downloaded successfully as ${format.toUpperCase()}`
        );
      } else {
        console.log(
          `Report downloaded successfully as ${format.toUpperCase()}`
        );
      }
    } catch (error) {
      console.error("Error downloading report:", error);

      // Display error message
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An error occurred while downloading the report";

      // Use toast if available, otherwise use alert
      if (typeof toast !== "undefined") {
        toast.error(`Download failed: ${errorMessage}`);
      } else {
        alert(`Download failed: ${errorMessage}`);
      }
    }
  };

  // Updated status update handler
  const handleStatusUpdate = async (reportId, newStatus) => {
    try {
      console.log("Attempting update with:", {
        clientEmail,
        reportId,
        newStatus,
      });

      const response = await axios.post(
        "/api/accountant/update-client-report",
        {
          reportId,
          status: newStatus,
          clientEmail: clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Report updated successfully") {
        setReports(
          reports.map((report) =>
            report.id === reportId ? { ...report, status: newStatus } : report
          )
        );
      }
    } catch (error) {
      console.error("Failed to update status:", error);
      alert("Failed to update status. Please try again.");
    }
  };
  const handleBack = () => {
    // If state was passed from AdminView, navigate there.
    if (location.state && location.state.from) {
      navigate(location.state.from);
    }
    // Alternatively, if a different flag (e.g. returnPage) indicates admin view
    else if (location.state && location.state.returnPage === "adminView") {
      navigate("/admin");
    }
    // Check browser history as a fallback
    else if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    }
    // Default fallback route
    else {
      navigate("/process-info");
    }
  };

  const handleManagePolicies = () => {
    // Instead of navigate(...), just open the modal
    setShowComplianceDocs(true);
  };

  const handleNoteUpdate = async (reportId, newNote) => {
    try {
      const response = await axios.post(
        "/api/accountant/update-client-report",
        {
          reportId,
          notes: newNote,
          clientEmail: clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "Report updated successfully") {
        setReports(
          reports.map((report) =>
            report.id === reportId ? { ...report, notes: newNote } : report
          )
        );
        setEditingReportId(null);
      }
    } catch (error) {
      console.error("Failed to save note:", error);
      alert("Failed to save note. Please try again.");
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!currency) return amount.toLocaleString();

    const currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      JPY: "¥",
      AUD: "A$",
      CAD: "C$",
      CHF: "Fr",
      CNY: "¥",
    };

    const symbol = currencyMap[currency] || currency;
    const formattedAmount = amount.toLocaleString();
    return currencyMap[currency]
      ? `${symbol}${formattedAmount}`
      : `${formattedAmount} ${symbol}`;
  };

  const renderNotesModal = () => {
    if (!editingReportId) return null;

    return (
      <div
        className="eb-modal-overlay"
        onClick={() => setEditingReportId(null)}
      >
        <div className="eb-notes-modal" onClick={(e) => e.stopPropagation()}>
          <div className="eb-notes-content">
            <h4>Report Notes</h4>
            <textarea
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              placeholder="Add notes about this report..."
              className="eb-notes-textarea"
              autoFocus
            />
            <div className="eb-modal-actions">
              <button
                className="eb-modal-save"
                onClick={() => handleNoteUpdate(editingReportId, editedNote)}
              >
                Save
              </button>
              <button
                className="eb-modal-cancel"
                onClick={() => setEditingReportId(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReports = () => (
    <div className="reports-display-section">
      <div className="reports-header">
        <div className="reports-title-section">
          <h3 className="expense-report-title">Your Reports</h3>
        </div>

        <div className="filter-controls">
          <ExpensePolicyStatus
            clientEmail={currentUserEmail}
            investmentManager={investmentManager}
            onManagePolicies={handleManagePolicies}
          />

          <select
            value={reportFilter}
            onChange={(e) => {
              setReportFilter(e.target.value);
              setCurrentPage(1); // Reset to first page when changing filter
            }}
            className="status-select"
          >
            <option value="All">All Reports</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
          </select>
        </div>
      </div>

      <div className="reports-content">
        {reports.length > 0 ? (
          <div>
            {reports.map((report, index) => {
              if (!report.submissionDate || !report.link) return null;

              return (
                <div
                  key={report.id || index}
                  className={`report-card ${
                    highlightedReportId === report.id
                      ? "highlighted-report"
                      : ""
                  }`}
                  ref={
                    highlightedReportId === report.id
                      ? highlightedReportRef
                      : null
                  }
                >
                  {/* Header with date, title, and controls */}
                  <div className="report-header">
                    <div className="report-meta">
                      <div className="date-block">
                        {new Date(report.submissionDate).toLocaleDateString()}
                      </div>

                      {/* Desktop title */}
                      <div className="title-left">
                        <a
                          href={report.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {report.title || "Untitled Report"}
                        </a>
                      </div>

                      {/* Mobile title - shown only on mobile */}
                      <div className="mobile-title">
                        <a
                          href={report.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {report.title || "Untitled Report"}
                        </a>
                      </div>
                    </div>

                    <div className="controls-right">
                      <select
                        value={report.status || "Submitted"}
                        onChange={(e) =>
                          handleStatusUpdate(report.id, e.target.value)
                        }
                        className="status-select"
                        title={report.status || "Submitted"}
                      >
                        <option value="Submitted">Submitted</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Paid">Paid</option>
                      </select>
                      <button
                        className="action-button"
                        onClick={() => {
                          if (editingReportId === report.id) {
                            setEditingReportId(null);
                          } else {
                            setEditingReportId(report.id);
                            setEditedNote(report.notes || "");
                          }
                        }}
                        title={report.notes ? "Edit note" : "Add note"}
                      >
                        <i className="fas fa-sticky-note"></i>
                        {report.notes && report.notes.trim() !== "" && (
                          <span className="note-indicator"></span>
                        )}
                      </button>

                      {report.sentTo?.length > 0 && (
                        <button className="action-button" title="Recipients">
                          <i className="fas fa-envelope"></i>
                          <span className="recipient-count">
                            {report.sentTo.length}
                          </span>
                        </button>
                      )}

                      {/* Download dropdown button */}
                      <div className="download-dropdown">
                        <button
                          className="action-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDownloadingReportId(
                              downloadingReportId === report.id
                                ? null
                                : report.id
                            );
                          }}
                          title="Download report"
                        >
                          <i className="fas fa-download"></i>
                        </button>

                        {downloadingReportId === report.id && (
                          <div className="download-menu">
                            <button
                              onClick={() => handleDownload(report.id, "csv")}
                            >
                              CSV
                            </button>
                            <button
                              onClick={() => handleDownload(report.id, "xlsx")}
                            >
                              Excel
                            </button>
                            <button
                              onClick={() => handleDownload(report.id, "pdf")}
                            >
                              PDF
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Row3: Receipts & Func Total on the same line */}
                  <div className="row3">
                    <div className="metric-left">
                      <span className="label">Receipts:</span>
                      <span className="value">{report.receiptCount || 0}</span>
                    </div>

                    {report.functionalTotal > 0 && (
                      <div className="metric-right">
                        <span className="label">Total:</span>
                        <span className="value currency">
                          {" "}
                          {report.homeCurrency
                            ? formatCurrency(
                                report.functionalTotal || 0,
                                report.homeCurrency
                              )
                            : (report.functionalTotal || 0).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* Row4: QuickBooks, Wave, Compliance */}
                  <div className="row4">
                    <div className="integration-container">
                      <ShareToQuickBooks
                        reportId={report.id}
                        spreadsheetId={accountantData.spreadsheetId}
                        quickbooks={report.quickbooks}
                        isConnected={quickbooksConnectionStatus}
                        onStatusUpdate={(newStatus) => {
                          console.log(
                            "Updating report with new status:",
                            newStatus
                          );
                          setReports((prevReports) =>
                            prevReports.map((r) =>
                              r.id === report.id
                                ? {
                                    ...r,
                                    quickbooks: {
                                      ...r.quickbooks,
                                      ...newStatus,
                                    },
                                    functionalTotal: newStatus.functionalTotal,
                                  }
                                : r
                            )
                          );
                        }}
                        onConnectionStatusChange={setQuickbooksConnectionStatus}
                        isClientOfAccountant={true}
                        clientEmail={clientEmail}
                      />
                    </div>

                    {SHOW_WAVE_BUTTON && (
                      <div className="integration-container">
                        <ShareToWave
                          reportId={report.id}
                          spreadsheetId={accountantData.spreadsheetId}
                          wave={report.wave}
                          isConnected={waveConnectionStatus}
                          onStatusUpdate={(newStatus) => {
                            setReports((prevReports) =>
                              prevReports.map((r) =>
                                r.id === report.id
                                  ? {
                                      ...r,
                                      wave: { ...r.wave, ...newStatus },
                                      functionalTotal:
                                        newStatus.functionalTotal,
                                    }
                                  : r
                              )
                            );
                          }}
                          onConnectionStatusChange={setWaveConnectionStatus}
                          isClientOfAccountant={true}
                          clientEmail={clientEmail}
                        />
                      </div>
                    )}

                    {/* Compliance Check - on separate line for desktop */}
                    {/* Compliance Check - styled differently for desktop/mobile */}
                    {investmentManager && (
                      <>
                        <div className="compliance-separator desktop-only"></div>
                        <div className="integration-container compliance-wrapper">
                          <ComplianceCheck
                            report={report}
                            clientEmail={clientEmail}
                            spreadsheetId={accountantData.spreadsheetId}
                            onStatusUpdate={(complianceData) => {
                              setReports((prevReports) =>
                                prevReports.map((r) =>
                                  r.id === report.id
                                    ? { ...r, compliance: complianceData }
                                    : r
                                )
                              );
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty-state">
            <p className="empty-state-message">
              No previously submitted reports available.
            </p>
          </div>
        )}

        {reports.length > 0 && totalPages > 1 && (
          <div className="custom-pagination-controls">
            <button
              onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
              disabled={currentPage === 1 || isLoadingAccountantView}
              className="custom-pagination-button"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <span className="custom-pagination-text">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((p) => p + 1)}
              disabled={currentPage === totalPages || isLoadingAccountantView}
              className="custom-pagination-button"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="accountant-view-container">
      <button className="back-button" onClick={handleBack}>
        <i className="fas fa-arrow-left"></i>
        Back to Dashboard
      </button>

      {isLoadingAccountantView ? (
        <div className="loading-spinner">
          <TailSpin color="#0033a0" height={50} width={50} />
        </div>
      ) : (
        <>
          <div className="client-header">
            <h3 className="client-name">
              {accountantData?.clientName || ""}
              <span className="client-email">
                {accountantData?.clientEmail
                  ? `(${accountantData.clientEmail})`
                  : ""}
              </span>
            </h3>
            {/*      {accountantData?.spreadsheetId && (
              <a
                href={`https://docs.google.com/spreadsheets/d/${accountantData.spreadsheetId}/edit`}
                target="_blank"
                rel="noopener noreferrer"
                className="spreadsheet-link"
              >
                <i className="fas fa-exclamation-triangle spreadsheet-icon"></i>
                Live expenses spreadsheet and user settings
              </a>
            )} */}
          </div>
          {renderReports()}
          {renderNotesModal()}
        </>
      )}

      {showComplianceDocs && (
        <ComplianceDocumentsModal
          onClose={() => setShowComplianceDocs(false)}
          userEmail={currentUserEmail}
        />
      )}
    </div>
  );
};

export default AccountantView;
