import { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../styles/shareToWave.css";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";

const ValidationError = ({ issues }) => {
  return (
    <div className="mt-4 bg-red-50 border border-red-100 rounded-lg p-4">
      <h3 className="font-medium text-red-800 mb-2">Validation Issues</h3>
      {issues.map((issue, index) => (
        <div key={index} className="mb-2 last:mb-0">
          <p className="text-red-700">{issue.message}</p>
          <p className="text-sm text-red-600 mt-1">
            Row {issue.row} - {issue.field}
          </p>
        </div>
      ))}
    </div>
  );
};

const BusinessSelectModal = ({
  businesses,
  currentBusinessId,
  onSelect,
  onClose,
}) => {
  return ReactDOM.createPortal(
    <div className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="modal-content bg-white rounded-lg p-6 max-w-lg w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Select Wave Business</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>
        <p className="text-gray-600 mb-4">
          Select which Wave business to use for this expense report:
        </p>
        <div className="business-list space-y-2">
          {businesses.map((business) => (
            <button
              key={business.id}
              className={`w-full p-3 text-left border rounded-lg transition-colors
                ${
                  business.id === currentBusinessId
                    ? "bg-blue-50 border-blue-500"
                    : "hover:bg-gray-50"
                }`}
              onClick={() => onSelect(business.id)}
            >
              <div className="flex items-center justify-between">
                <span>
                  {business.name}{" "}
                  {business.currency?.code && `(${business.currency.code})`}
                </span>
                {business.id === currentBusinessId && (
                  <span className="text-blue-500 text-sm">Current</span>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

const ShareToWave = ({
  reportId,
  spreadsheetId,
  wave,
  isConnected: initialConnectionStatus,
  onStatusUpdate,
  onConnectionStatusChange,
  isClientOfAccountant,
  clientEmail,
}) => {
  const [isSharing, setIsSharing] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(initialConnectionStatus);
  const [mappingResults, setMappingResults] = useState(null);
  const [validationIssues, setValidationIssues] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [showBusinessSelect, setShowBusinessSelect] = useState(false);
  const [currentBusinessId, setCurrentBusinessId] = useState(null);

  useEffect(() => {
    setIsConnected(initialConnectionStatus);
  }, [initialConnectionStatus]);

  useEffect(() => {
    setIsSharing(false);
  }, []);

  const handleConnectionChange = (newStatus) => {
    setIsConnected(newStatus);
    onConnectionStatusChange?.(newStatus);
  };

  const constructSheetUrl = (sheetId, gid) => {
    return gid
      ? `https://docs.google.com/spreadsheets/d/${sheetId}/edit#gid=${gid}`
      : `https://docs.google.com/spreadsheets/d/${sheetId}/edit`;
  };

  const handleDisconnect = async () => {
    try {
      setIsConnecting(true); // Show some indication of the process
      const response = await fetch("/api/wave/disconnect", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to disconnect from Wave");
      }

      console.log("Disconnected successfully");
      // Reset state to simulate starting over
      setIsConnected(false);
      setBusinesses([]);
      setCurrentBusinessId(null);
      setError(null);
    } catch (error) {
      console.error("Error disconnecting from Wave:", error);
      setError("Failed to disconnect from Wave");
    } finally {
      setIsConnecting(false); // Reset connection state
    }
  };

  const submitToWave = async () => {
    setIsSharing(true);
    try {
      const response = await fetch("/api/wave/share-report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          reportId,
          clientEmail: isClientOfAccountant ? clientEmail : undefined,
        }),
      });

      const data = await response.json();
      console.log("Wave API Full Response Data:", data);

      if (response.status === 401 && data.reauthorizationUrl) {
        window.location.href = data.reauthorizationUrl;
        return;
      }

      if (response.ok) {
        onStatusUpdate({
          status: "submitted",
          submittedAt: new Date().toISOString(),
          transactionId: data.transactionId,
          amount: data.amount,
          date: data.date,
          functionalTotal: data.functionalTotal,
        });
        return;
      }

      if (data.error === "WAVE_VENDOR_MISSING") {
        const sheetUrl = constructSheetUrl(spreadsheetId, data.details?.gid);

        alert(`${data.message}\n\nClick OK to open the Settings sheet.`);
        window.open(sheetUrl, "_blank");

        onStatusUpdate({
          status: "failed",
          error: data.error,
          submittedAt: new Date().toISOString(),
          details: data.details,
        });

        setError(
          data.message ||
            "Wave username missing. Please add it to the Settings sheet."
        );
        return;
      }

      if (data.issues) {
        const sheetUrl = constructSheetUrl(reportId);
        alert(`${data.message}\nClick OK to open the spreadsheet.`);
        window.open(sheetUrl, "_blank");

        setValidationIssues(data.issues);
        onStatusUpdate({
          status: "failed",
          error: "Validation failed. Please check your Func. Total values.",
          submittedAt: new Date().toISOString(),
          issues: data.issues,
        });
        return;
      }

      setError(data.error || "Failed to share report");
      onStatusUpdate({
        status: "failed",
        error: data.error || "Failed to share report",
        submittedAt: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Wave share error:", error);
      setError(error.message);

      if (
        error.message?.includes("reconnect") ||
        error.message?.includes("expired") ||
        error.message?.includes("authorization")
      ) {
        setIsConnected(false);
      }
    } finally {
      setIsSharing(false);
    }
  };

  const handleShare = async () => {
    if (!isConnected) {
      toast.error("Wave is not connected. Please connect first.", {
        autoClose: 5000,
      });
      return;
    }

    // Immediately set isSharing to true and clear any previous errors
    setIsSharing(true);
    setError(null);
    setMappingResults(null);
    setValidationIssues(null);

    try {
      const response = await fetch("/api/wave/businesses", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Wave businesses response:", data);

        if (data.success && data.businesses) {
          setBusinesses(data.businesses);
          setCurrentBusinessId(data.currentBusinessId);

          if (data.businesses.length > 1) {
            setShowBusinessSelect(true);
          } else {
            await submitToWave();
          }
        } else {
          throw new Error("No businesses found in response");
        }
        return;
      } else {
        throw new Error("Failed to fetch Wave businesses");
      }
    } catch (error) {
      console.error("Error checking Wave businesses:", error);
      toast.error(error.message || "Failed to check Wave businesses", {
        autoClose: 5000,
      });
      // Optionally, still update the error state if you need to render it somewhere
      setError(error.message);
    } finally {
      setIsSharing(false);
    }
  };

  const handleBusinessSelect = async (businessId) => {
    try {
      console.log("Selecting business ID:", businessId);
      setIsSharing(true);

      const response = await fetch("/api/wave/select-business", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ businessId }),
      });

      if (response.ok) {
        console.log("Business selected successfully");
        setShowBusinessSelect(false);
        setCurrentBusinessId(businessId);
        await submitToWave();
      } else {
        const errorData = await response.json();
        console.error("Failed to select business:", errorData);
        setError(errorData.error || "Failed to select business");
      }
    } catch (error) {
      console.error("Error selecting business:", error);
      setError("Failed to select business");
    } finally {
      setIsSharing(false);
    }
  };

  const handleConnect = async () => {
    setIsConnecting(true);
    try {
      const currentPath = window.location.pathname + window.location.search;

      if (isClientOfAccountant && clientEmail) {
        localStorage.setItem(
          "waveContext",
          JSON.stringify({
            isAccountantView: true,
            clientEmail: clientEmail,
            returnPath: currentPath,
          })
        );
      } else {
        localStorage.setItem(
          "waveContext",
          JSON.stringify({
            isAccountantView: false,
            returnPath: currentPath,
          })
        );
      }

      localStorage.setItem("selectedMenu", "expenseReports");

      const url = isClientOfAccountant
        ? `/api/wave/connect?clientEmail=${encodeURIComponent(
            clientEmail
          )}&returnPath=${encodeURIComponent(currentPath)}`
        : `/api/wave/connect?returnPath=${encodeURIComponent(currentPath)}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) throw new Error("Failed to initiate connection");

      const data = await response.json();
      if (data.authUri) {
        window.location.href = data.authUri;
      } else {
        throw new Error("No authorization URI received");
      }
    } catch (err) {
      console.error("Connection error:", err);
      setError("Failed to initiate Wave connection");
      handleConnectionChange(false);
    } finally {
      setIsConnecting(false);
    }
  };

  if (wave?.status === "submitted") {
    return (
      <div className="submitted-status flex items-center space-x-2">
        <i className="fas fa-check-circle text-green-500 text-xl"></i>
        <span className="text-green-500 font-medium">Submitted to Wave</span>
      </div>
    );
  }

  if (!isConnected) {
    return (
      <div className="space-y-4">
        <button
          onClick={handleConnect}
          disabled={isConnecting}
          className={`share-to-wave-btn ${isConnecting ? "disabled" : ""}`}
        >
          {isConnecting ? (
            <div className="flex items-center justify-center">
              <TailSpin color="#4B5563" height={20} width={20} />
              <span className="ml-2">Connecting...</span>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <img src="/wave.png" alt="Wave Logo" className="wave-icon" />
              <span>Connect Wave</span>
            </div>
          )}
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    );
  }

  return (
    <>
      {showBusinessSelect && (
        <BusinessSelectModal
          businesses={businesses}
          currentBusinessId={currentBusinessId}
          onSelect={handleBusinessSelect}
          onClose={() => setShowBusinessSelect(false)}
        />
      )}
      <div className="space-y-4">
        <div className="wave-button-container">
          <button
            onClick={handleShare}
            disabled={isSharing || isConnecting}
            className={`share-to-wave-btn share-state ${
              isSharing || isConnecting ? "disabled" : ""
            }`}
          >
            {isSharing ? (
              <div className="flex items-center justify-center space-x-2">
                <i className="fas fa-circle-notch processing-icon"></i>
                <span>Processing...</span>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <img src="/wave.png" alt="Wave Logo" className="wave-icon" />
                <span>Share to Wave</span>
              </div>
            )}
          </button>

          <button
            onClick={handleDisconnect}
            disabled={isConnecting || isSharing}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
          >
            {isConnecting ? (
              <div className="flex items-center space-x-2">
                <TailSpin color="#4B5563" height={16} width={16} />
                <span>Disconnecting...</span>
              </div>
            ) : (
              <span>Disconnect</span>
            )}
          </button>
        </div>

        {validationIssues && <ValidationError issues={validationIssues} />}
        {mappingResults && (
          <div className="mt-4">
            <div className="mb-2 text-green-500 font-medium">
              ✓ Successfully shared to Wave
            </div>
            <div className="border rounded-lg p-4">
              <h3 className="font-medium mb-2">Category Mapping Details</h3>
              {mappingResults.map((result, index) => (
                <div key={index} className="mb-2 last:mb-0">
                  <div className="flex items-center">
                    <span className="text-gray-600">
                      {result.originalCategory}
                    </span>
                    <span className="mx-2">→</span>
                    <span
                      className={
                        result.mappingResult.status === "mapped"
                          ? "text-green-500"
                          : "text-red-500"
                      }
                    >
                      {result.mappingResult.mapping?.name || "Not mapped"}
                    </span>
                  </div>
                  {result.mappingResult.warning && (
                    <p className="text-sm text-yellow-600 ml-4 mt-1">
                      {result.mappingResult.warning.message}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </>
  );
};

export default ShareToWave;
