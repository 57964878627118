import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { Upload } from "lucide-react";

const VendorContracts = () => {
  const [contracts, setContracts] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");

  // Add this useEffect
  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await fetch("/api/documents?type=contract", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        if (data.success) {
          setContracts(data.documents);
        }
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    fetchContracts();
  }, []);

  const handleFileSelect = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFileUpload(e);
    }
    e.target.value = "";
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    setUploadError("");

    try {
      const formData = new FormData();
      formData.append("type", "contract");
      formData.append("documents", file);

      const response = await fetch("/api/documents/upload", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setContracts((prev) => [...prev, data.results[0].contractInfo]); // Access contractInfo from results
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      setUploadError(error.message || "Failed to upload contract");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6 mb-4">
        <h3 className="text-lg font-medium mb-2">Vendor Contracts</h3>
        <p className="text-sm text-gray-600 mb-4">
          Upload vendor contracts to track renewal dates and notice periods.
        </p>

        <div className="flex items-center space-x-4">
          <label className="relative cursor-pointer bg-blue-50 hover:bg-blue-100 text-blue-900 rounded-lg px-4 py-2 transition-colors">
            <input
              type="file"
              className="hidden"
              accept=".pdf,.doc,.docx,.txt"
              onChange={handleFileSelect}
              disabled={isUploading}
            />
            <div className="flex items-center space-x-2">
              <Upload className="w-4 h-4" />
              <span>Choose File</span>
            </div>
          </label>

          {isUploading && (
            <div className="flex items-center space-x-2 text-blue-600">
              <TailSpin color="#2563eb" height={16} width={16} />
              <span>Uploading...</span>
            </div>
          )}
        </div>

        {uploadError && (
          <div className="mt-4 bg-red-50 text-red-900 px-4 py-2 rounded-lg">
            {uploadError}
          </div>
        )}
      </div>

      {contracts.length > 0 && (
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium mb-4">Contract List</h3>
          <div className="space-y-4">
            {contracts.map((contract) => (
              <div key={contract.id} className="p-4 bg-gray-50 rounded-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-medium text-gray-900">
                      {contract.name}
                    </h4>
                    <p className="text-sm text-gray-600">
                      {contract.isRenewable ? "Renewable" : "Non-renewable"}
                    </p>
                  </div>
                  {contract.isRenewable && (
                    <div className="text-right text-sm">
                      <p className="text-gray-600">
                        Notice Period: {contract.noticePeriod} days
                      </p>
                      <p className="text-gray-600">
                        Days until notice: {contract.daysUntilNotice}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorContracts;
