import React, { useState, useRef, useEffect, useMemo } from "react";
import "../styles/EnhancedCameraSelector.css";
import { offlineStorage } from "../services/offlineStorage";
import axios from "axios";
import { toast } from "react-toastify";

const EnhancedCameraSelector = ({ onClose, onPhotosSelected, onError }) => {
  const [status, setStatus] = useState("initializing");
  const [capturedImage, setCapturedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // New state for tags and notes
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [newTagInput, setNewTagInput] = useState("");
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [note, setNote] = useState("");
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [internalError, setInternalError] = useState("");

  // Audio
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isTranscribing, setIsTranscribing] = useState(false);

  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const fileInputRef = useRef(null);
  const canvasRef = useRef(document.createElement("canvas"));
  const panelRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        await transcribeAudio(audioBlob);
      };

      mediaRecorder.start();
      setIsRecording(true);

      // Automatically stop recording after 15 seconds (15000 milliseconds)
      setTimeout(() => {
        if (mediaRecorder.state === "recording") {
          stopRecording();
        }
      }, 15000); // Changed from 5000 to 15000
    } catch (error) {
      console.error("Error starting voice recording:", error);
      toast.error("Couldn't access microphone. Please check permissions.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      // Stop all tracks in the stream
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  const transcribeAudio = async (audioBlob) => {
    try {
      setIsTranscribing(true);

      // Create FormData to send the audio file
      const formData = new FormData();
      formData.append("audio", audioBlob, "voice-note.webm");

      // Send to the transcription endpoint
      const response = await fetch("/api/transcribe-audio", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        // For 503 Service Unavailable, show a specific message
        if (response.status === 503) {
          toast.error(
            "Voice service temporarily unavailable. Please try again later."
          );
          return;
        }

        throw new Error(`Failed to transcribe audio: ${response.status}`);
      }

      // Check if the response indicates a failure
      if (!data.success) {
        toast.error(
          data.message || "Failed to process voice note. Please try again."
        );
        return;
      }

      // Add the transcribed text to the note if we got a transcription
      if (data.transcription && data.transcription.trim() !== "") {
        // If there's already some text in the note, add a line break
        const updatedNote = note
          ? `${note}\n${data.transcription}`
          : data.transcription;
        setNote(updatedNote);
      }
    } catch (error) {
      console.error("Error transcribing audio:", error);
      toast.error("Failed to process voice note. Please try again.");
    } finally {
      setIsTranscribing(false);
    }
  };

  const getDeviceSupport = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const iOSVersion = isIOS
      ? parseInt(navigator.userAgent.match(/ OS (\d+)_/)?.[1] || "0", 10)
      : 0;
    const hasGetUserMedia = !!navigator.mediaDevices?.getUserMedia;

    return {
      useCamera: hasGetUserMedia && (!isIOS || iOSVersion >= 14),
      useCapture: isIOS,
    };
  };

  const deviceSupport = useMemo(() => getDeviceSupport(), []);

  // Add this useEffect with your other useEffect declarations in EnhancedCameraSelector.jsx
  useEffect(() => {
    // Fix for mobile viewport height issues, especially on iOS
    const setViewportHeight = () => {
      // First we get the viewport height and multiply it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Set the height initially
    setViewportHeight();

    // Update the height on resize and orientation change
    window.addEventListener("resize", setViewportHeight);
    window.addEventListener("orientationchange", setViewportHeight);

    return () => {
      window.removeEventListener("resize", setViewportHeight);
      window.removeEventListener("orientationchange", setViewportHeight);
    };
  }, []);

  // Fetch available tags when a photo is captured
  useEffect(() => {
    if (capturedImage && status === "preview") {
      fetchAvailableTags();
    }
  }, [capturedImage, status]);

  // Function to fetch available tags from the server
  const fetchAvailableTags = async () => {
    try {
      setIsLoadingTags(true);
      const response = await fetch("/api/tags", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch tags");
      }

      const data = await response.json();

      if (data.success && data.tags) {
        // Filter out any empty tags at the data level
        const validTags = data.tags.filter(
          (tagObj) => tagObj.tag && tagObj.tag.trim() !== ""
        );
        setAvailableTags(validTags);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to load tags");
    } finally {
      setIsLoadingTags(false);
    }
  };

  const setupCamera = async () => {
    const resolutionSteps = [
      { width: 3840, height: 2160 }, // 4K
      { width: 2560, height: 1440 }, // QHD
      { width: 1920, height: 1080 }, // FHD
      { width: 1280, height: 720 }, // HD
    ];

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    for (const resolution of resolutionSteps) {
      try {
        const constraints = {
          video: {
            facingMode: "environment",
            width: { ideal: resolution.width, min: 1280 },
            height: { ideal: resolution.height, min: 720 },
            frameRate: { ideal: 30 },
            ...(isSafari && {
              autoFocusMode: "continuous",
            }),
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        return stream;
      } catch (error) {
        if (resolution.width === 1280) throw error;
        // Otherwise keep trying smaller resolutions...
      }
    }
  };

  useEffect(() => {
    if (internalError) {
      const timer = setTimeout(() => {
        setInternalError("");
      }, 3000); // Clear after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [internalError]);

  useEffect(() => {
    // Log device info once
    console.log("Device Memory:", navigator.deviceMemory);
    console.log("Hardware Concurrency:", navigator.hardwareConcurrency);
    console.log("User Agent:", navigator.userAgent);
  }, []);

  useEffect(() => {
    const initCamera = async () => {
      if (!deviceSupport.useCamera) {
        setStatus("fallback");
        return;
      }

      try {
        const stream = await setupCamera();
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => setStatus("ready");
        }
      } catch (error) {
        console.error("Camera initialization error:", error);
        toast.warning("Camera not available. Using file upload mode instead.", {
          position: "top-center",
        });
        setStatus("fallback");
      }
    };

    initCamera();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, [deviceSupport.useCamera]);

  // Fix for the createNewTag function - move it outside of useEffect

  // Add this function as a standalone function in the component (outside any useEffect)
  const createNewTag = async (tagName) => {
    if (!tagName.trim()) return;

    try {
      const response = await fetch("/api/tags", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tags: [{ tag: tagName, isActive: true }],
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create tag");
      }

      // Optionally refresh the tag list
      await fetchAvailableTags();

      return true;
    } catch (error) {
      console.error("Error creating tag:", error);
      setInternalError("Failed to create tag. Please try again.");
      return false;
    }
  };

  // Remove the createNewTag function from the useEffect for online/offline state
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      toast.success("You're back online! Photos will upload normally.", {
        position: "top-center",
        autoClose: 5000,
        toastId: "online-status",
      });
    };

    const handleOffline = () => {
      setIsOnline(false);
      toast.warning(
        "You're offline. Photos will be saved locally and uploaded when you're back online.",
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          toastId: "offline-status",
          style: {
            background: "#FEF9C3",
            color: "#854D0E",
            fontWeight: "bold",
          },
          icon: "⚠️",
        }
      );
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Show initial offline toast if offline on component mount
    if (!navigator.onLine) {
      setIsOnline(false); // Make sure state is initialized correctly
      handleOffline();
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      toast.dismiss("offline-status");
      toast.dismiss("online-status");
    };
  }, [onError]);

  const processImage = (canvas, ctx) => {
    if (!canvas.width || !canvas.height) return;

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    // Basic brightness check
    let totalBrightness = 0;
    for (let i = 0; i < data.length; i += 4) {
      totalBrightness +=
        0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2];
    }
    const avgBrightness = totalBrightness / (data.length / 4);
    const isLowLight = avgBrightness < 128;

    // Simple contrast + brightness
    for (let i = 0; i < data.length; i += 4) {
      for (let j = 0; j < 3; j++) {
        let value = data[i + j];
        const contrast = isLowLight ? 22 : 15;
        const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
        value = Math.round(factor * (value - 128) + 128);

        const brightness = isLowLight ? 12 : 6;
        value += brightness;

        data[i + j] = Math.min(255, Math.max(0, value));
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const handleCapture = async () => {
    try {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d", {
        alpha: false,
        willReadFrequently: true,
      });
      ctx.imageSmoothingEnabled = false;

      let width = videoRef.current.videoWidth;
      let height = videoRef.current.videoHeight;

      canvas.width = width;
      canvas.height = height;

      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(videoRef.current, 0, 0, width, height);

      setCapturedImage(canvas.toDataURL("image/jpeg", 0.9));
      setStatus("preview");

      await new Promise((r) => setTimeout(r, 50)); // tiny delay

      processImage(canvas, ctx);
      setCapturedImage(canvas.toDataURL("image/jpeg", 0.9));
    } catch (error) {
      console.error("Capture error:", error);
      toast.error(`Failed to capture: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRetake = async () => {
    setCapturedImage(null);
    setStatus("initializing");
    setSelectedTag("");
    setNewTagInput("");
    setIsCreatingTag(false);
    setNote("");
    setIsPanelExpanded(false);

    try {
      const stream = await setupCamera();
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => setStatus("ready");
      }
    } catch (error) {
      console.error("Camera reinitialization error:", error);
      toast.error(error.message);
      setStatus("fallback");
    }
  };

  const handleAcceptImage = async () => {
    try {
      setIsProcessing(true);

      const canvas = canvasRef.current;
      const img = new Image();

      // Make sure the final data is loaded
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = capturedImage;
      });

      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d", { alpha: false });
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(img, 0, 0);

      const blob = await new Promise((resolve, reject) =>
        canvas.toBlob(resolve, "image/jpeg", 0.9)
      );

      if (!blob) {
        throw new Error("Failed to create image blob");
      }

      console.log(
        "Offline/Online check. Blob size ~",
        (blob.size / 1024 / 1024).toFixed(2),
        "MB"
      );

      // Get final tag - either selected existing tag or new tag if created
      // Get final tag - either selected existing tag or new tag if created
      const finalTag =
        isCreatingTag && newTagInput.trim() ? newTagInput.trim() : selectedTag;

      // If creating a new tag, send it to the server
      if (isCreatingTag && newTagInput.trim()) {
        const tagCreated = await createNewTag(newTagInput.trim());
        if (!tagCreated) {
          // Tag creation failed
          setIsProcessing(false);
          return; // Exit function early
        }
      }

      if (!isOnline) {
        // === OFFLINE PATH ===
        try {
          await offlineStorage.storePhoto(blob, {
            timestamp: Date.now(),
            filename: `Receipt-${Date.now()}.jpg`,
            tag: finalTag,
            note: note,
          });
          toast.info("Photo saved for upload when back online!");
          onClose();
          return;
        } catch (err) {
          console.error("Failed to store offline photo:", err);
          toast.error("Failed to save photo for offline use");
          return;
        } finally {
          setIsProcessing(false);
        }
      }

      // === ONLINE PATH === UPDATED TO USE GCS DIRECT UPLOAD
      // Step 1: Get a signed URL from your server
      const filename = `Receipt-${Date.now()}.jpg`;
      const getUrlResponse = await axios.post(
        "/api/get-upload-url",
        {
          filename: filename,
          contentType: "image/jpeg",
          fileType: "receipt", // Specify receipt type
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { signedUrl, gcsFileName } = getUrlResponse.data;

      // Step 2: Upload directly to GCS
      await axios.put(signedUrl, blob, {
        headers: {
          "Content-Type": "image/jpeg",
        },
      });

      // Step 3: Create the mediaItem with GCS information
      const fileId = `receipt_${Date.now()}_${Math.random()
        .toString(36)
        .substring(2, 10)}`;

      // Send the same metadata structure as FileUploader
      onPhotosSelected([
        {
          id: fileId,
          gcsPath: gcsFileName,
          baseUrl: signedUrl.split("?")[0], // Store the base URL without query params
          mimeType: "image/jpeg",
          filename: filename,
          photoName: filename, // Add photoName property to avoid the issue we fixed
          mediaMetadata: {
            creationTime: new Date().toISOString(),
          },
          tag: finalTag,
          note: note,
          type: "receipt", // Important: Mark as receipt type
        },
      ]);

      toast.success("Receipt uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Accept/upload error:", error);
      toast.error("Failed to upload. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsProcessing(true);
      setStatus("processing");

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = await new Promise((resolve, reject) => {
        const i = new Image();
        i.onload = () => resolve(i);
        i.onerror = reject;
        i.src = URL.createObjectURL(file);
      });

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      processImage(canvas, ctx);

      setCapturedImage(canvas.toDataURL("image/jpeg", 0.9));
      setStatus("preview");
    } catch (error) {
      console.error("File processing error:", error);
      toast.error(`File processing error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const selectTag = (tag) => {
    // If the same tag is clicked again, deselect it
    if (selectedTag === tag) {
      setSelectedTag("");
    } else {
      setSelectedTag(tag);
    }

    // When selecting an existing tag, we're not creating a new one
    setIsCreatingTag(false);
    setNewTagInput("");
  };

  const handleNewTagChange = (e) => {
    setNewTagInput(e.target.value);
  };

  const toggleNewTagCreation = () => {
    // Check if not already in creation mode and the available tag count is 20 or more.
    if (
      !isCreatingTag &&
      availableTags.filter((t) => t.tag && t.tag.trim() !== "").length >= 20
    ) {
      // Show toast notification for tag limit
      toast.warning(
        "Maximum of 20 tags allowed. Delete some tags in your spreadsheet before creating a new one."
      );
      return;
    }

    // First, clear the selected state
    setSelectedTag("");
    setNewTagInput("");

    // Then toggle the mode with a slight delay to prevent UI flicker
    setTimeout(() => {
      setIsCreatingTag(!isCreatingTag);
    }, 10);
  };

  const togglePanel = () => {
    setIsPanelExpanded(!isPanelExpanded);
  };

  // Handle panel drag
  useEffect(() => {
    if (status !== "preview") return;

    // Get panel reference within the effect
    const panel = panelRef.current;
    if (!panel) return;

    let startY = 0;
    let startHeight = 0;

    const handleTouchStart = (e) => {
      const touch = e.touches[0];
      startY = touch.clientY;
      startHeight = panel.offsetHeight;
      document.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      document.addEventListener("touchend", handleTouchEnd);
    };

    const handleTouchMove = (e) => {
      e.preventDefault();
      const touch = e.touches[0];
      const deltaY = startY - touch.clientY;
      const newHeight = Math.min(
        Math.max(60, startHeight + deltaY),
        window.innerHeight * 0.7
      );

      if (newHeight > 100) {
        setIsPanelExpanded(true);
      } else if (newHeight < 80) {
        setIsPanelExpanded(false);
      }
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    const dragHandle = panel.querySelector(".panel-drag-handle");
    if (dragHandle) {
      dragHandle.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (dragHandle) {
        dragHandle.removeEventListener("touchstart", handleTouchStart);
      }
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [status]);

  return (
    <div className="modal-backdrop">
      <div className="modal-content enhanced-camera">
        <button onClick={onClose} className="close-xc">
          ×
        </button>

        {/* No duplicate offline indicator - parent container already handles this display */}

        {status === "fallback" ? (
          <div className="file-input-container">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              {...(deviceSupport.useCapture ? { capture: "environment" } : {})}
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            <button
              className="capture-button"
              onClick={() => fileInputRef.current?.click()}
            >
              <i className="fas fa-camera"></i>
            </button>
          </div>
        ) : (
          <div className="enhanced-camera-container">
            {capturedImage ? (
              <>
                <img
                  src={capturedImage}
                  alt="Captured"
                  className="enhanced-camera-preview"
                />
                <div className="preview-controls">
                  <button
                    onClick={handleRetake}
                    className="preview-button1 retake"
                  >
                    Retake
                  </button>
                  <button
                    onClick={handleAcceptImage}
                    className="preview-button1"
                  >
                    Accept
                  </button>
                </div>

                {/* New slide-up panel for tags and notes */}
                <div
                  ref={panelRef}
                  className={`tag-note-panel ${
                    isPanelExpanded ? "expanded" : ""
                  }`}
                >
                  <div className="panel-drag-handle" onClick={togglePanel}>
                    <div className="panel-drag-indicator"></div>
                    <div className="panel-title">
                      {isPanelExpanded
                        ? "Add Tags & Notes"
                        : "Add Tags & Notes"}
                    </div>
                  </div>

                  {isPanelExpanded && (
                    <div className="panel-content">
                      <div className="tags-section">
                        <div className="tags-header">
                          <h4>Tag</h4>
                          <button
                            className="create-tag-toggle"
                            onClick={toggleNewTagCreation}
                          >
                            {isCreatingTag ? "Select Existing" : "Create New"}
                          </button>
                        </div>
                        {internalError && (
                          <div className="internal-error">{internalError}</div>
                        )}
                        {/* Make sure both sections are conditionally rendered and don't overlap */}
                        {isCreatingTag ? (
                          <div className="create-tag-container">
                            <input
                              type="text"
                              value={newTagInput}
                              onChange={handleNewTagChange}
                              placeholder="Enter new tag name"
                              className="new-tag-input"
                              maxLength={30}
                            />
                            <div className="new-tag-hint">
                              Use tags to group expenses by client, project,
                              trip, etc.
                            </div>
                          </div>
                        ) : isLoadingTags ? (
                          <div className="loading-tags">Loading tags...</div>
                        ) : availableTags.length > 0 ? (
                          <div className="tags-container">
                            {availableTags
                              .filter(
                                (tagObj) =>
                                  tagObj.tag && tagObj.tag.trim() !== ""
                              )
                              .map((tagObj) => (
                                <button
                                  key={tagObj.tag}
                                  className={`tag-chip ${
                                    selectedTag === tagObj.tag ? "selected" : ""
                                  }`}
                                  onClick={() => selectTag(tagObj.tag)}
                                >
                                  {tagObj.tag}
                                </button>
                              ))}
                          </div>
                        ) : (
                          <div className="no-tags">
                            <p>No tags available</p>
                            <button
                              className="create-first-tag-btn"
                              onClick={() => setIsCreatingTag(true)}
                            >
                              Create your first tag
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="notes-section">
                        <h4>Notes</h4>
                        <textarea
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Add a note about this receipt..."
                          className="note-textarea"
                        />
                        <button
                          className={`voice-note-button ${
                            isRecording ? "recording" : ""
                          } ${isTranscribing ? "transcribing" : ""}`}
                          onClick={isRecording ? stopRecording : startRecording}
                          disabled={isTranscribing}
                        >
                          {isTranscribing ? (
                            <i className="fas fa-spinner fa-spin"></i>
                          ) : isRecording ? (
                            <i className="fas fa-stop"></i>
                          ) : (
                            <i className="fas fa-microphone"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  className="enhanced-camera-preview"
                  style={{ backgroundColor: "transparent" }}
                />
              </>
            )}

            {status === "ready" && !capturedImage && (
              <button
                onClick={handleCapture}
                className="capture-button"
                disabled={isProcessing}
              >
                <i className="fas fa-camera"></i>
              </button>
            )}
          </div>
        )}

        {isProcessing && (
          <div className="processing-status">
            <i className="fas fa-spinner fa-spin"></i>
            <span>Processing image...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnhancedCameraSelector;
