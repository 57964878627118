import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";

import "../styles/AdminView.css"; // Reuse the same CSS

const ViewerList = () => {
  const location = useLocation(); // Access location.state
  const navigate = useNavigate();

  const [viewers, setViewers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  // Construct the shareable URL with the user's email as a referrer
  const referrerEmail = location.state?.email || "unknown@example.com";
  const baseUrl = process.env.REACT_APP_BASE_URL || "https://www.expensebot.ai";
  const shareableUrl = `${baseUrl}/reviewer?referrer=${referrerEmail}`;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareableUrl.replace("%40", "@"));
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const fetchViewers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/accountant/viewers", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (response.data.success) {
        setViewers(response.data.clients);
      }
    } catch (error) {
      console.error("Error fetching viewers:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchViewers();
  }, [fetchViewers]);

  const handleRemoveViewer = async (viewerEmail) => {
    try {
      const response = await axios.post(
        "/api/accountant/remove-relationship",
        {
          email: viewerEmail,
          type: "viewer",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        await fetchViewers(); // Refresh the list
      }
    } catch (error) {
      console.error("Error removing viewer:", error);
    }
  };

  const renderViewerTable = () => {
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <TailSpin color="#0033a0" height={20} width={20} />
        </div>
      );
    }

    if (viewers.length === 0) {
      return (
        <div className="no-clients-message">
          No one currently has access to your reports
        </div>
      );
    }

    return (
      <div className="client-table-container">
        <table className="client-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {viewers.map((viewer) => (
              <tr key={viewer.email}>
                <td>{viewer.name}</td>
                <td>{viewer.email}</td>
                <td>
                  <div className="action-buttons">
                    <button
                      onClick={() => handleRemoveViewer(viewer.email)}
                      className="text-button delete-button"
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="admin-view-container">
      <div className="viewer-header">
        <button
          className="back-button"
          onClick={() => navigate("/process-info")}
        >
          <i className="fas fa-arrow-left"></i>
          Back to Dashboard
        </button>
      </div>
      <div className="main-invite-section">
        <h3>People Who Can See My Reports</h3>
        <div className="info-box-admin">
          <p>To grant someone access to your reports, share:</p>
          <div className="copy-link-container">
            <span className="reviewer-link non-clickable">
              {decodeURIComponent(shareableUrl)}
            </span>
            <button
              onClick={handleCopyLink}
              className={`copy-button ${copySuccess ? "copied" : ""}`}
              title="Copy link"
            >
              <i className={`fas ${copySuccess ? "fa-check" : "fa-copy"}`}></i>
            </button>
          </div>
        </div>

        {renderViewerTable()}
      </div>
    </div>
  );
};

export default ViewerList;
