// src/components/common/BaseUploader.jsx
import React, { useState, useRef, useEffect } from "react";
import "../styles/CameraSelector.css";
import TagSelector from "../components/TagSelector";

/**
 * Base uploader component that can be extended by specific file uploaders
 * Designed to maintain all functionality of existing uploaders while reducing code duplication
 */
const BaseUploader = ({
  onClose,
  onFilesSelected,
  onError,
  onUploadProgress,
  acceptTypes,
  maxFileSize,
  maxFiles,
  fileTypeName,
  buttonText,
  fileTypeValidationFn,
  processFiles,
  processingText = "Processing",
  autoOpenFileDialog = false, // Add this prop with default false
}) => {
  const [status, setStatus] = useState("ready");
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileErrors, setFileErrors] = useState({});
  // Add state for file tags
  const [fileTags, setFileTags] = useState({});
  // Track which tag dropdown is currently open
  const [activeTagDropdown, setActiveTagDropdown] = useState(null);

  // Prevent body scrolling when a tag dropdown is open
  useEffect(() => {
    if (activeTagDropdown) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [activeTagDropdown]);

  useEffect(() => {
    if (autoOpenFileDialog && fileInputRef.current) {
      // Set a small timeout to ensure DOM is ready
      const timer = setTimeout(() => {
        fileInputRef.current.click();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [autoOpenFileDialog]);
  // Format bytes into a human-readable string
  const formatFileSize = (bytes) => {
    if (bytes < 1024 * 1024) {
      return `${Math.round(bytes / 1024)}KB`;
    } else {
      return `${(bytes / (1024 * 1024)).toFixed(1)}MB`;
    }
  };

  // Calculate overall upload progress across all files
  const calculateOverallProgress = (progressData = uploadProgress) => {
    if (typeof progressData === "number") return progressData;
    if (Object.keys(progressData).length === 0) return 0;

    const totalProgress = Object.values(progressData).reduce(
      (sum, progress) => sum + progress,
      0
    );
    return Math.round(totalProgress / Object.keys(progressData).length);
  };

  const handleFileSelect = async (event) => {
    // Close any open dropdown when new files are selected
    setActiveTagDropdown(null);

    const newFiles = Array.from(event.target.files);
    if (!newFiles || newFiles.length === 0) return;

    try {
      // Copy existing errors so we can add to them
      let newErrors = { ...fileErrors };

      // Merge newly selected files with existing
      const allFiles = [...selectedFiles, ...newFiles];

      // Check if combined total exceeds maxFiles
      if (allFiles.length > maxFiles) {
        newErrors.TOO_MANY_FILES = `You selected ${
          allFiles.length
        } files. Maximum allowed is ${maxFiles}. Please remove ${
          allFiles.length - maxFiles
        } file(s) to proceed.`;
      } else {
        delete newErrors.TOO_MANY_FILES; // remove old error if we're under limit again
      }

      // Check each *newly added* file's size & type
      newFiles.forEach((file) => {
        if (file.size > maxFileSize) {
          const formattedSize = formatFileSize(file.size);
          const sizeLimit = formatFileSize(maxFileSize);
          newErrors[
            file.name
          ] = `Size (${formattedSize}) exceeds the ${sizeLimit} limit.`;
        } else if (!fileTypeValidationFn(file)) {
          newErrors[file.name] = `Not a supported ${fileTypeName} format`;
        }
      });

      // Update states
      setSelectedFiles(allFiles);
      setFileErrors(newErrors);
    } catch (error) {
      console.error(`File processing error:`, error);
      onError?.(`Error selecting files: ${error.message}`);
    }
  };

  // Handle tag selection for a specific file
  const handleTagSelect = (fileName, tag) => {
    setFileTags((prev) => ({
      ...prev,
      [fileName]: tag,
    }));
  };

  // Toggle tag dropdown visibility for a specific file
  const toggleTagDropdown = (fileName, isOpen) => {
    // If isOpen is explicitly provided, respect that value
    if (isOpen === false) {
      setActiveTagDropdown(null);
      return;
    }

    if (isOpen === true) {
      setActiveTagDropdown(fileName);
      return;
    }

    // Otherwise toggle based on current state
    if (activeTagDropdown === fileName) {
      // Close this dropdown
      setActiveTagDropdown(null);
    } else {
      // Open this dropdown, close any other
      setActiveTagDropdown(fileName);
    }
  };

  // Are there any files without errors?
  const hasValidFiles =
    selectedFiles.length > 0 &&
    selectedFiles.some((file) => !fileErrors[file.name]);

  // Are there any errors at all?
  const hasErrorFiles = Object.keys(fileErrors).length > 0;

  // Helper function to update both local and parent progress
  const updateProgress = (progress) => {
    // Handle both object-based and number-based progress updates
    if (typeof progress === "object") {
      setUploadProgress(progress);
      if (onUploadProgress) {
        onUploadProgress(calculateOverallProgress(progress));
      }
    } else {
      const roundedProgress = Math.round(progress);
      setUploadProgress(roundedProgress);
      if (onUploadProgress) {
        onUploadProgress(roundedProgress);
      }
    }
  };

  // Add this inside the BaseUploader component
  useEffect(() => {
    return () => {
      // Cleanup any existing portal containers
      const portal = document.getElementById("tag-selector-portal");
      if (portal) {
        document.body.removeChild(portal);
      }
    };
  }, []);

  const handleContinue = async () => {
    // Close any open dropdown
    setActiveTagDropdown(null);

    // Filter out files with errors
    const validFiles = selectedFiles.filter((file) => !fileErrors[file.name]);

    if (validFiles.length === 0) {
      return;
    }

    setStatus("processing");

    // Reset upload progress
    setUploadProgress({});
    if (onUploadProgress) {
      onUploadProgress(0);
    }

    try {
      // Add tags to valid files before processing
      const filesWithTags = validFiles.map((file) => ({
        file,
        tag: fileTags[file.name] || "",
      }));

      // Call the provided processFiles function with our callbacks and tags
      const result = await processFiles(filesWithTags, updateProgress, onError);

      if (result && result.length > 0) {
        // Pass processed items to callback
        onFilesSelected(result);
        onClose();
      } else {
        setStatus("ready");
        if (onUploadProgress) {
          onUploadProgress(0); // Reset progress on failure
        }
        if (navigator.onLine) {
          onError?.(`No files were successfully processed`);
        } else {
          // Offline case - files may have been saved for later
          onClose();
        }
      }
    } catch (error) {
      console.error(`Error during ${fileTypeName} upload:`, error);
      setStatus("ready");
      if (onUploadProgress) {
        onUploadProgress(0); // Reset progress on failure
      }
      onError?.(`Upload error: ${error.message}`);
    }
  };

  const buildErrorSummary = () => {
    if (fileErrors.TOO_MANY_FILES) {
      return <span>{fileErrors.TOO_MANY_FILES}</span>;
    }

    const errorFileNames = Object.keys(fileErrors);

    if (errorFileNames.length === 1) {
      const [fileName] = errorFileNames;
      return (
        <span>
          Please remove the file with error: "{fileErrors[fileName]}" (
          {fileName})
        </span>
      );
    } else {
      const allErrors = errorFileNames.map(
        (name) => `"${name}" (${fileErrors[name]})`
      );
      return (
        <span>
          Please remove these files with errors: {allErrors.join(", ")}.
        </span>
      );
    }
  };

  // Get format names without the leading "image/" for display
  const getFormatsDisplay = () => {
    return acceptTypes
      .split(",")
      .map((type) => type.trim())
      .map((type) => {
        const parts = type.split("/");
        return parts.length > 1 ? parts[1].toUpperCase() : type.toUpperCase();
      })
      .join(", ");
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="camera-container">
          <button onClick={onClose} className="close-xc">
            ×
          </button>
          {status === "ready" ? (
            <div className="file-select-area">
              <input
                ref={fileInputRef}
                type="file"
                accept={acceptTypes}
                onChange={handleFileSelect}
                style={{ display: "none" }}
                multiple
              />

              {/* Conditionally change the button label/icon depending on whether any files are selected */}
              {selectedFiles.length === 0 ? (
                <button
                  className="file-upload-button"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <i className="fas fa-upload"></i>
                  {buttonText.selectInitial}
                </button>
              ) : (
                <button
                  className="file-upload-button select-more-button"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <i className="fas fa-plus"></i>
                  {buttonText.selectMore}
                </button>
              )}

              <div className="file-info">
                Supported formats: {getFormatsDisplay()}
                <br />
                Maximum file size: {formatFileSize(maxFileSize)} per{" "}
                {fileTypeName}
                <br />
                Maximum files: {maxFiles}
              </div>

              {hasErrorFiles && (
                <div className="error-summary">
                  <i className="fas fa-exclamation-circle"></i>
                  {buildErrorSummary()}
                </div>
              )}

              {selectedFiles.length > 0 && (
                <div className="selected-files">
                  <h4>Selected Files ({selectedFiles.length})</h4>
                  <ul className="file-list">
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        className={fileErrors[file.name] ? "file-error" : ""}
                      >
                        <div className="file-entry-container">
                          <div className="file-details">
                            <span className="file-name">{file.name}</span>
                            <span className="file-size">
                              ({formatFileSize(file.size)})
                            </span>
                          </div>

                          {/* TagSelector component for each file */}
                          {!fileErrors[file.name] && (
                            <div
                              className="file-tag-selector"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              <TagSelector
                                selectedTag={fileTags[file.name] || ""}
                                onTagSelect={(tag) =>
                                  handleTagSelect(file.name, tag)
                                }
                                isCompact={true}
                                buttonLabel="Add Tag"
                                className={`file-tag-selector-component ${
                                  activeTagDropdown === file.name
                                    ? "active-dropdown"
                                    : ""
                                }`}
                                isDropdownOpen={activeTagDropdown === file.name}
                                onDropdownToggle={(isOpen) =>
                                  toggleTagDropdown(file.name, isOpen)
                                }
                              />
                            </div>
                          )}

                          <button
                            className="remove-file"
                            onClick={() => {
                              // Close dropdown if open for this file
                              if (activeTagDropdown === file.name) {
                                setActiveTagDropdown(null);
                              }

                              const newFiles = [...selectedFiles];
                              newFiles.splice(index, 1);
                              setSelectedFiles(newFiles);

                              // Also remove any tag for this file
                              const newTags = { ...fileTags };
                              delete newTags[file.name];
                              setFileTags(newTags);

                              const updatedErrors = { ...fileErrors };
                              if (updatedErrors[file.name]) {
                                delete updatedErrors[file.name];
                              }

                              if (
                                updatedErrors.TOO_MANY_FILES &&
                                newFiles.length <= maxFiles
                              ) {
                                delete updatedErrors.TOO_MANY_FILES;
                              }

                              setFileErrors(updatedErrors);
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {selectedFiles.length > 0 && (
                <div className="file-upload-actions">
                  <button
                    className="continue-button"
                    onClick={handleContinue}
                    disabled={!hasValidFiles || hasErrorFiles}
                  >
                    Continue
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="file-select-area">
              <div className="processing-status">
                <i className="fas fa-spinner fa-spin"></i>
                <span>
                  {processingText} {selectedFiles.length} {fileTypeName}(s)...{" "}
                  {calculateOverallProgress()}%
                </span>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar-fill"
                    style={{ width: `${calculateOverallProgress()}%` }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseUploader;
