// services/offlineStorage.js
const DB_NAME = "ExpenseBot";
const STORE_NAME = "pendingUploads";
const DB_VERSION = 2; // or whatever version you want

class OfflineStorage {
  constructor() {
    this.db = null;
    this.initPromise = null; // optional concurrency guard
  }

  async initDB() {
    if (this.db) return this.db;
    if (this.initPromise) return this.initPromise;

    this.initPromise = new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        this.db = request.result;
        console.log("[OfflineStorage] DB opened successfully:", this.db);
        resolve(this.db);
      };
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        console.log(
          "[OfflineStorage] onupgradeneeded from version",
          event.oldVersion,
          "to",
          event.newVersion
        );

        if (!db.objectStoreNames.contains(STORE_NAME)) {
          console.log("[OfflineStorage] Creating object store:", STORE_NAME);
          db.createObjectStore(STORE_NAME, {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };
    });

    return this.initPromise;
  }

  async storePhoto(blob, metadata = {}) {
    await this.initDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);

      const item = {
        blob,
        timestamp: Date.now(),
        status: "pending",
        metadata,
        retryCount: 0,
      };

      console.log("[OfflineStorage] Storing photo item:", item);

      const request = store.add(item);
      request.onsuccess = (evt) => {
        console.log(
          "[OfflineStorage] storePhoto success, ID =",
          evt.target.result
        );
        resolve(evt.target.result);
      };
      request.onerror = () => {
        console.error("[OfflineStorage] storePhoto error:", request.error);
        reject(request.error);
      };
    });
  }

  async getPendingUploads() {
    await this.initDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([STORE_NAME], "readonly");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.getAll();

      request.onsuccess = () => {
        console.log("[OfflineStorage] getPendingUploads ->", request.result);
        resolve(request.result);
      };
      request.onerror = () => {
        console.error(
          "[OfflineStorage] getPendingUploads error:",
          request.error
        );
        reject(request.error);
      };
    });
  }

  async markAsUploaded(id) {
    await this.initDB();

    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.delete(id);

      request.onsuccess = () => {
        console.log(
          "[OfflineStorage] markAsUploaded success, deleted ID =",
          id
        );
        resolve();
      };
      request.onerror = () => {
        console.error("[OfflineStorage] markAsUploaded error:", request.error);
        reject(request.error);
      };
    });
  }
}

export const offlineStorage = new OfflineStorage();
