import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import ComplianceDocuments from "./ComplianceDocuments";
import VendorContracts from "./VendorContracts";

import "../styles/AdminView.css";

const AdminView = ({ user }) => {
  // Client management states
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [newClientEmail, setNewClientEmail] = useState("");
  const [inviteError, setInviteError] = useState("");
  const [isInviting, setIsInviting] = useState(false);
  const [isResending, setIsResending] = useState({});
  const [activeTab, setActiveTab] = useState("clients");
  // State for email tooltip
  const [activeTooltip, setActiveTooltip] = useState(null);

  const location = useLocation();
  const showVendorContracts = false;

  // Handle tooltip toggle
  const handleTooltipToggle = (e, clientEmail) => {
    e.stopPropagation();
    e.preventDefault(); // Add this to prevent any default behavior

    console.log("Toggle tooltip clicked for:", clientEmail);
    console.log("Current activeTooltip:", activeTooltip);

    setActiveTooltip(activeTooltip === clientEmail ? null : clientEmail);

    // Log after state update (will show previous state due to closure)
    console.log(
      "Setting activeTooltip to:",
      activeTooltip === clientEmail ? null : clientEmail
    );
  };

  // Keep this useEffect for closing tooltips when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".email-icon") && activeTooltip) {
        setActiveTooltip(null); // Simply reset the activeTooltip state
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [activeTooltip]);

  const TabSelector = () => (
    <div className="tab-container">
      <button
        className={`tab-button ${activeTab === "clients" ? "active" : ""}`}
        onClick={() => setActiveTab("clients")}
      >
        Who you can view
      </button>

      {/* Remove the compliance docs button and only keep vendor contracts (if needed) */}
      {user.roles?.investmentManager && showVendorContracts && (
        <button
          className={`tab-button ${activeTab === "contracts" ? "active" : ""}`}
          onClick={() => setActiveTab("contracts")}
        >
          Vendor Contracts
        </button>
      )}
    </div>
  );

  const handleResendInvite = async (email, name) => {
    setIsResending((prev) => ({ ...prev, [email]: true }));

    try {
      const response = await axios.post(
        "/api/accountant/invite-client",
        {
          clientName: name,
          clientEmail: email,
          resend: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data.success) {
        throw new Error(response.data.message || "Failed to resend invite");
      }
    } catch (error) {
      console.error("Error resending invite:", error);
      setInviteError(`Failed to resend invite to ${email}`);
    } finally {
      setIsResending((prev) => ({ ...prev, [email]: false }));
    }
  };

  const fetchClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/accountant/clients", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (response.data.success) {
        setClients(response.data.clients);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Check if an initial tab was specified in the location state
  useEffect(() => {
    if (
      location.state?.initialTab &&
      ["clients", "compliance", "contracts"].includes(location.state.initialTab)
    ) {
      setActiveTab(location.state.initialTab);
    }
  }, [location.state]);

  useEffect(() => {
    if (activeTab === "clients") {
      fetchClients();
    }
  }, [activeTab, fetchClients]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInviteClient = useCallback(
    async (e, referralEmail = null) => {
      if (e) e.preventDefault();
      setInviteError("");
      setIsInviting(true);

      const emailToUse = referralEmail || newClientEmail;
      const nameToUse = referralEmail || newClientName;

      if (!referralEmail && (!nameToUse.trim() || !emailToUse.trim())) {
        setInviteError("Please provide both client name and email");
        setIsInviting(false);
        return;
      }

      if (!validateEmail(emailToUse.trim())) {
        setInviteError("Please enter a valid email address");
        setIsInviting(false);
        return;
      }

      try {
        const response = await axios.post(
          "/api/accountant/invite-client",
          {
            clientName: nameToUse.trim(),
            clientEmail: emailToUse.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setNewClientName("");
          setNewClientEmail("");
          await fetchClients();
        } else {
          setInviteError(response.data.message || "Failed to invite client");
        }
      } catch (error) {
        setInviteError("Error inviting client. Please try again.");
        console.error("Error inviting client:", error);
      } finally {
        setIsInviting(false);
      }
    },
    [newClientEmail, newClientName, fetchClients]
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referrerEmail = urlParams.get("referrer");

    if (referrerEmail && validateEmail(referrerEmail)) {
      setNewClientEmail(referrerEmail);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [location]);

  const renderClientTable = () => {
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <TailSpin color="#0033a0" height={20} width={20} />
        </div>
      );
    }

    if (clients.length === 0) {
      return (
        <div className="no-clients-message">
          You haven't been invited by a team member to view their reports.
        </div>
      );
    }

    return (
      <div className="client-table-container">
        <table className="client-table">
          <thead>
            <tr>
              <th className="th-name">Report Owner</th>
              <th className="th-email">Email</th>
              <th className="th-status">Status</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.email}>
                <td className="td-name">
                  <div className="name-with-email">
                    {client.name}
                    <button
                      className="email-icon"
                      aria-label="Show email"
                      onClick={(e) => handleTooltipToggle(e, client.email)}
                    >
                      <i className="fas fa-envelope"></i>
                    </button>
                    {activeTooltip === client.email && (
                      <div className="tooltip-content1">
                        <div className="tooltip-header">Email:</div>
                        <div className="recipient">{client.email}</div>
                      </div>
                    )}
                  </div>
                </td>
                <td className="td-email">{client.email}</td>
                <td className="td-status">
                  {client.status === "accepted" ||
                  client.status === "report-access" ? (
                    <div className="action-buttons">
                      <Link
                        to={`/client/${encodeURIComponent(
                          client.email
                        )}/reports`}
                        className="view-link"
                        state={{
                          isAccountantView: true,
                          spreadsheetId: client.spreadsheetId,
                          investmentManager: user.roles?.investmentManager,
                          from: "/reviewer",
                          initialTab: activeTab,
                          currentUserEmail: user.email,
                        }}
                      >
                        <button className="view-button">
                          <i className="fas fa-eye"></i>
                          <span className="button-text2">View</span>
                        </button>
                      </Link>
                      {/* Delete button removed */}
                    </div>
                  ) : (
                    <div className="status-actions">
                      <span className="status-badge pending">Pending</span>
                      <button
                        onClick={() =>
                          handleResendInvite(client.email, client.name)
                        }
                        className="text-button resend-link"
                        disabled={isResending[client.email]}
                      >
                        {isResending[client.email] ? (
                          <div className="loading-button-content">
                            <TailSpin color="#ffffff" height={12} width={12} />
                            <span>Resending...</span>
                          </div>
                        ) : (
                          "Resend"
                        )}
                      </button>
                      {/* Delete button removed */}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "clients":
        return (
          <div className="main-invite-section">
            {false && (
              <>
                <h6>Request Access to Someone's Reports</h6>
                <form onSubmit={handleInviteClient}>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Name"
                      value={newClientName}
                      onChange={(e) => setNewClientName(e.target.value)}
                      className="client-input"
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="email"
                      placeholder="Email"
                      value={newClientEmail}
                      onChange={(e) => setNewClientEmail(e.target.value)}
                      className="client-input"
                    />
                  </div>
                  <button
                    type="submit"
                    className="primary-button invite-button"
                    disabled={isInviting}
                  >
                    {isInviting ? (
                      <div className="loading-button-content">
                        <TailSpin color="#ffffff" height={16} width={16} />
                        <span>Sending...</span>
                      </div>
                    ) : (
                      "Send Invite"
                    )}
                  </button>
                  {inviteError && (
                    <p className="error-message">{inviteError}</p>
                  )}
                </form>
              </>
            )}

            {renderClientTable()}
          </div>
        );
      case "compliance":
        console.log(
          "Rendering ComplianceDocuments with isActive:",
          activeTab === "compliance"
        );
        return (
          <ComplianceDocuments
            isActive={activeTab === "compliance"}
            userEmail={user.email}
          />
        );

      case "contracts":
        return <VendorContracts isActive={activeTab === "contracts"} />;
      default:
        return null;
    }
  };

  return (
    <div className="admin-view-container">
      <TabSelector />
      {renderContent()}
    </div>
  );
};

export default AdminView;
