import axios from "axios";

/**
 * Handles the categorization and execution of compliance actions
 */
class ComplianceActionHandler {
  /**
   * Categorizes a compliance action using Gemini
   * @param {string} action - The action text
   * @param {object} item - The expense item context
   * @returns {Promise<object>} - The categorized action
   */
  static async categorizeAction(action, item) {
    try {
      const response = await axios.post(
        "/api/compliance/categorize-action",
        { action, item },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        return response.data.result;
      } else {
        throw new Error(response.data.error || "Failed to categorize action");
      }
    } catch (error) {
      console.error("Error categorizing action:", error);
      return this.fallbackCategorization(action);
    }
  }

  /**
   * Fallback categorization when API call fails
   * @param {string} action - The action text
   * @returns {object} - The categorized action
   */
  static fallbackCategorization(action) {
    const lowerAction = action.toLowerCase();

    // Check for override actions first
    if (
      lowerAction.includes("override") ||
      lowerAction.includes("approve despite") ||
      lowerAction.includes("manual approval")
    ) {
      return {
        type: "APPROVAL_OVERRIDE",
        confidence: 0.9,
        details: {
          fieldToUpdate: "approval",
          actionType: "override",
          originalAction: action,
        },
      };
    }

    if (
      lowerAction.includes("business purpose") ||
      lowerAction.includes("purpose for") ||
      lowerAction.includes("document purpose")
    ) {
      return {
        type: "BUSINESS_PURPOSE",
        confidence: 0.8,
        details: {
          fieldToUpdate: "notes",
          actionType: "add",
          originalAction: action,
        },
      };
    }

    if (
      lowerAction.includes("mileage rate") ||
      lowerAction.includes("rate is") ||
      lowerAction.includes("prevailing automobile") ||
      (lowerAction.includes("rate") && lowerAction.includes("mile"))
    ) {
      return {
        type: "MILEAGE_RATE",
        confidence: 0.8,
        details: {
          fieldToUpdate: "rate",
          actionType: "update",
          originalAction: action,
        },
      };
    }

    if (
      lowerAction.includes("receipt") ||
      lowerAction.includes("itemized") ||
      lowerAction.includes("documentation")
    ) {
      return {
        type: "RECEIPT",
        confidence: 0.8,
        details: {
          fieldToUpdate: "receipt",
          actionType: "add",
          originalAction: action,
        },
      };
    }

    if (
      lowerAction.includes("approval") ||
      lowerAction.includes("authorize") ||
      lowerAction.includes("approver")
    ) {
      return {
        type: "APPROVAL",
        confidence: 0.8,
        details: {
          fieldToUpdate: "approval",
          actionType: "add",
          originalAction: action,
        },
      };
    }

    if (
      lowerAction.includes("start location") ||
      lowerAction.includes("end location") ||
      lowerAction.includes("destination")
    ) {
      return {
        type: "LOCATION",
        confidence: 0.8,
        details: {
          fieldToUpdate: "location",
          actionType: "add",
          originalAction: action,
        },
      };
    }

    // Default fallback for unknown actions
    return {
      type: "UNKNOWN",
      confidence: 0.5,
      details: {
        originalAction: action,
      },
    };
  }

  /**
   * Execute the appropriate action based on categorization
   * @param {object} categorizedAction - The categorized action
   * @param {object} item - The expense item
   * @param {function} callbacks - The callback functions for different action types
   */
  static executeAction(categorizedAction, item, callbacks) {
    const { type } = categorizedAction;

    // If callback exists for this action type, call it
    if (callbacks[type]) {
      return callbacks[type](item, categorizedAction);
    }

    // Default behavior for unknown action types
    console.warn(`No handler implemented for action type: ${type}`);
    return callbacks.UNKNOWN
      ? callbacks.UNKNOWN(item, categorizedAction)
      : null;
  }

  /**
   * Creates a manual override action object
   * @param {string} violation - The violation text to override
   * @returns {object} - Formatted override action
   */
  static createManualOverrideAction(violation) {
    return {
      type: "APPROVAL_OVERRIDE",
      confidence: 1.0,
      details: {
        fieldToUpdate: "approval",
        actionType: "override",
        originalAction: `Override: ${violation || "Policy violation"}`,
      },
    };
  }
}

export default ComplianceActionHandler;
