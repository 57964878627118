// ExpenseCategoryManagementModal.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "../styles/ExpenseCategoryManagementModal.css";

const ExpenseCategoryManagementModal = ({
  isOpen,
  onClose,
  onCategoriesUpdated,
}) => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newInstructions, setNewInstructions] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const MAX_CATEGORIES = 25;
  const MAX_INSTRUCTIONS_LENGTH = 200;

  // Fetch categories when modal opens
  useEffect(() => {
    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  // Fetch all categories from the server
  const fetchCategories = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/categories", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (res.data.success) {
        setCategories(res.data.categories);
      } else {
        toast.error("Failed to load expense categories.");
      }
    } catch (error) {
      console.error("Error fetching expense categories:", error);
      toast.error("An error occurred while fetching expense categories.");
    } finally {
      setLoading(false);
    }
  };

  // Delete a category
  const handleDeleteCategory = (categoryName) => {
    if (window.confirm(`Are you sure you want to delete "${categoryName}"?`)) {
      setCategories((prevCategories) =>
        prevCategories.filter(
          (category) =>
            category.category.toLowerCase() !== categoryName.toLowerCase()
        )
      );
    }
  };

  // Start editing a category
  const startEditCategory = (category) => {
    setEditingCategory({
      originalName: category.category,
      category: category.category,
      instructions: category.instructions,
    });
  };

  // Cancel editing
  const cancelEdit = () => {
    setEditingCategory(null);
  };

  // Save edited category
  const saveEditedCategory = () => {
    if (!editingCategory.instructions.trim()) {
      // Default to category name if instructions are empty
      editingCategory.instructions = editingCategory.category;
    }

    setCategories((prevCategories) =>
      prevCategories.map((cat) =>
        cat.category === editingCategory.originalName
          ? {
              category: editingCategory.category,
              instructions: editingCategory.instructions,
            }
          : cat
      )
    );
    setEditingCategory(null);
  };

  // Add a new category ensuring the count is less than MAX_CATEGORIES
  const handleAddCategory = () => {
    if (categories.length >= MAX_CATEGORIES) {
      toast.error(
        `Maximum of ${MAX_CATEGORIES} categories reached. Delete one to add a new category.`
      );
      return;
    }
    if (newCategory.trim() === "") {
      toast.error("Please enter a category name.");
      return;
    }

    let instructions = newInstructions.trim();
    if (instructions === "") {
      // Default to category name if instructions are empty
      instructions = newCategory.trim();
    }

    // Prevent duplicates (case-insensitive)
    if (
      categories.some(
        (category) =>
          category.category.toLowerCase() === newCategory.trim().toLowerCase()
      )
    ) {
      toast.error("Category already exists.");
      return;
    }

    setCategories((prevCategories) => [
      ...prevCategories,
      {
        category: newCategory.trim(),
        instructions: instructions,
      },
    ]);
    setNewCategory("");
    setNewInstructions("");
  };

  // Save updated categories to the server
  const handleSave = async () => {
    try {
      setSaving(true);
      const res = await axios.post(
        "/api/categories",
        { categories },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res.data.success) {
        toast.success("Expense categories updated successfully.");
        if (onCategoriesUpdated) {
          onCategoriesUpdated();
        }
        onClose();
      } else {
        toast.error(res.data.message || "Failed to update expense categories.");
      }
    } catch (error) {
      console.error("Error updating expense categories:", error);
      toast.error("An error occurred while saving expense categories.");
    } finally {
      setSaving(false);
    }
  };

  // Do not render if modal is not open
  if (!isOpen) return null;

  return (
    <div className="expense-cat-backdrop" onClick={onClose}>
      <div className="expense-cat-modal" onClick={(e) => e.stopPropagation()}>
        <div className="expense-cat-header">
          <h3>Manage Expense Categories</h3>
          <button className="expense-cat-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="expense-cat-info-banner">
          <p>
            <strong>About Expense Categories:</strong> Categories help organize
            your expenses. Each category requires instructions to help AI
            classify expenses properly. If left blank, the category name will be
            used as the instruction.
          </p>
        </div>
        <div className="expense-cat-body">
          {loading ? (
            <div className="expense-cat-loading-indicator">
              Loading categories...
            </div>
          ) : (
            <>
              <div className="expense-cat-group">
                <h4>
                  Categories{" "}
                  <span className="expense-cat-count">
                    ({categories.length}/{MAX_CATEGORIES})
                  </span>
                </h4>
                {categories.length === 0 ? (
                  <p className="expense-cat-empty-message">
                    No categories added yet.
                  </p>
                ) : (
                  <ul>
                    {categories.map((category, index) => (
                      <li key={index}>
                        {editingCategory &&
                        editingCategory.originalName === category.category ? (
                          <div className="expense-cat-edit-form">
                            <div className="expense-cat-edit-row">
                              <label>Name:</label>
                              <input
                                type="text"
                                value={editingCategory.category}
                                onChange={(e) =>
                                  setEditingCategory({
                                    ...editingCategory,
                                    category: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="expense-cat-edit-row">
                              <label>Instructions for AI:</label>
                              <textarea
                                value={editingCategory.instructions}
                                onChange={(e) =>
                                  setEditingCategory({
                                    ...editingCategory,
                                    instructions: e.target.value.substring(
                                      0,
                                      MAX_INSTRUCTIONS_LENGTH
                                    ),
                                  })
                                }
                                maxLength={MAX_INSTRUCTIONS_LENGTH}
                                placeholder="Instructions to help AI categorize expenses"
                              />
                              <div className="expense-cat-char-count">
                                {editingCategory.instructions.length}/
                                {MAX_INSTRUCTIONS_LENGTH}
                              </div>
                            </div>
                            <div className="expense-cat-edit-actions">
                              <button
                                onClick={saveEditedCategory}
                                className="expense-cat-save-edit-button"
                              >
                                Save
                              </button>
                              <button
                                onClick={cancelEdit}
                                className="expense-cat-cancel-edit-button"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="expense-cat-item">
                            <div className="expense-cat-details">
                              <span className="expense-cat-name">
                                {category.category}
                              </span>
                              <span className="expense-cat-instructions">
                                {category.instructions}
                              </span>
                            </div>
                            <div className="expense-cat-actions">
                              <button
                                onClick={() => startEditCategory(category)}
                                className="expense-cat-edit-button"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteCategory(category.category)
                                }
                                className="expense-cat-delete-button"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="expense-cat-add-section">
                <h4>Add New Category</h4>
                <div className="expense-cat-add-form">
                  <div className="expense-cat-input-group">
                    <label>Category Name:</label>
                    <input
                      type="text"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      placeholder="Enter new category name"
                      onKeyPress={(e) =>
                        e.key === "Enter" && handleAddCategory()
                      }
                    />
                  </div>
                  <div className="expense-cat-input-group">
                    <label>
                      Instructions for AI:
                      <span className="expense-cat-required-hint">
                        {" "}
                        (Will default to category name if left blank)
                      </span>
                    </label>
                    <textarea
                      value={newInstructions}
                      onChange={(e) =>
                        setNewInstructions(
                          e.target.value.substring(0, MAX_INSTRUCTIONS_LENGTH)
                        )
                      }
                      placeholder="Instructions to help AI categorize expenses"
                      maxLength={MAX_INSTRUCTIONS_LENGTH}
                    />
                    <div className="expense-cat-char-count">
                      {newInstructions.length}/{MAX_INSTRUCTIONS_LENGTH}
                    </div>
                  </div>
                  <button
                    onClick={handleAddCategory}
                    className="expense-cat-add-button"
                    disabled={!newCategory.trim()}
                  >
                    Add Category
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="expense-cat-footer">
          <button
            onClick={handleSave}
            disabled={saving || loading}
            className="expense-cat-save-button"
          >
            {saving ? "Saving..." : "Save Changes"}
          </button>
          <button
            onClick={onClose}
            disabled={saving}
            className="expense-cat-cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpenseCategoryManagementModal;
