import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import "../styles/ComplianceCheck.css";
import { useCompliance } from "./ComplianceContext";
import BusinessPurposeModal from "./BusinessPurposeModal";
import { toast } from "react-toastify";
import ComplianceActionHandler from "./utils/ComplianceActionHandler";
import ApprovalOverrideModal from "./ApprovalOverrideModal";

// Helper function to categorize violations for UI display
// Updated categorizeViolations function to prevent duplicates

const hasSpecificActionHandler = (violation) => {
  const lowerViolation = violation.toLowerCase();

  // Check for violations that have specific handlers
  if (
    lowerViolation.includes("business purpose") ||
    lowerViolation.includes("document purpose")
  ) {
    return true;
  }

  // Add more specific handlers as you implement them

  return false;
};

const isInformationalViolation = (violation, hasSpecificHandler = false) => {
  // If it already has a specific handler, it's not just informational
  if (hasSpecificHandler) return false;

  const lowerViolation = violation.toLowerCase();

  // Check if it's a mileage rate violation
  if (
    (lowerViolation.includes("mileage rate") ||
      lowerViolation.includes("rate of $")) &&
    lowerViolation.includes("current irs")
  ) {
    return true;
  }

  // If there's no specific handler, treat as informational
  return !hasSpecificHandler;
};

const categorizeViolations = (results) => {
  if (!results || !Array.isArray(results)) return [];

  const categories = {
    mileage: { title: "Mileage Issues", items: [], icon: "fa-route" },
    receipt: { title: "Missing Receipts", items: [], icon: "fa-receipt" },
    approval: { title: "Approval Needed", items: [], icon: "fa-check-square" },
    purpose: { title: "Business Purpose", items: [], icon: "fa-comment-alt" },
    other: { title: "Other Issues", items: [], icon: "fa-exclamation-circle" },
  };

  // Track processed expense IDs for each category to prevent duplicates
  const processedIds = {
    mileage: new Set(),
    receipt: new Set(),
    approval: new Set(),
    purpose: new Set(),
    other: new Set(),
  };

  // Process each non-compliant result
  results
    .filter((r) => !r.analysis.isCompliant)
    .forEach((result) => {
      const violations = result.analysis.violations || [];

      // Create the base item with all necessary properties
      const baseItem = {
        id: result.expenseId,
        merchant: result.expense.merchant,
        amount: result.expense.amount,
        violations: [], // Store all violations for this expense
        actions: result.analysis.requiredActions || [],
        rowIndex: result.rowIndex,
      };

      // Track categories this expense belongs to
      const expenseCategories = new Set();

      // Check each violation and categorize
      violations.forEach((violation) => {
        const lowerViolation = violation.toLowerCase();
        baseItem.violations.push(violation);

        // Determine category
        let category = "other";

        if (
          lowerViolation.includes("mileage") ||
          lowerViolation.includes("rate") ||
          lowerViolation.includes("distance") ||
          lowerViolation.includes("miles")
        ) {
          category = "mileage";
        } else if (
          lowerViolation.includes("receipt") ||
          lowerViolation.includes("documentation") ||
          lowerViolation.includes("itemized")
        ) {
          category = "receipt";
        } else if (
          lowerViolation.includes("approv") ||
          lowerViolation.includes("authoriz")
        ) {
          category = "approval";
        } else if (
          lowerViolation.includes("purpose") ||
          lowerViolation.includes("reason") ||
          lowerViolation.includes("justification")
        ) {
          category = "purpose";
        }

        expenseCategories.add(category);
      });

      // Add the expense item to each relevant category, but only if it hasn't been added before
      expenseCategories.forEach((category) => {
        if (!processedIds[category].has(result.expenseId)) {
          processedIds[category].add(result.expenseId);

          // Clone the base item for this category
          const categoryItem = {
            ...baseItem,
            // Only show the violations relevant to this category
            violation: baseItem.violations
              .filter((v) => {
                const lowerV = v.toLowerCase();

                switch (category) {
                  case "mileage":
                    return (
                      lowerV.includes("mileage") ||
                      lowerV.includes("rate") ||
                      lowerV.includes("distance") ||
                      lowerV.includes("miles")
                    );
                  case "receipt":
                    return (
                      lowerV.includes("receipt") ||
                      lowerV.includes("documentation") ||
                      lowerV.includes("itemized")
                    );
                  case "approval":
                    return (
                      lowerV.includes("approv") || lowerV.includes("authoriz")
                    );
                  case "purpose":
                    return (
                      lowerV.includes("purpose") ||
                      lowerV.includes("reason") ||
                      lowerV.includes("justification")
                    );
                  default:
                    return true;
                }
              })
              .join("; "), // Join multiple violations for display
          };

          categories[category].items.push(categoryItem);
        }
      });
    });

  // Return only non-empty categories
  return Object.values(categories).filter(
    (category) => category.items.length > 0
  );
};

const ComplianceCheck = ({
  report,
  spreadsheetId,
  onStatusUpdate,
  clientEmail,
}) => {
  const { hasComplianceDocuments, isLoading } = useCompliance();
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState(null);
  const [showViolations, setShowViolations] = useState(false);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [isProcessingAction, setIsProcessingAction] = useState(false);
  const [approvalOverrideModalOpen, setApprovalOverrideModalOpen] =
    useState(false);
  const [selectedViolation, setSelectedViolation] = useState(null);

  // New states for business purpose modal
  const [businessPurposeModalOpen, setBusinessPurposeModalOpen] =
    useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);

  // Get the appropriate report ID
  const getReportId = () => {
    // For AccountantView, use report.id
    if (report.id) {
      return report.id;
    }
    // For ProcessInfo, use report.reportId
    if (report.reportId) {
      return report.reportId;
    }
    console.error("No valid report ID found in report object:", report);
    return null;
  };

  const handleApprovalOverrideSuccess = (overrideData) => {
    // Show success message first
    toast.success("Approval override recorded successfully", {
      position: "top-right",
      autoClose: 3000,
    });

    // Then refresh compliance check to reflect the changes
    setTimeout(() => {
      handleCheck(); // This will re-run the compliance check
    }, 500); // Small delay to ensure the toast is visible
  };

  const handleCheck = async () => {
    setIsChecking(true);
    setError(null);

    try {
      const reportId = getReportId();

      if (!reportId) {
        throw new Error("No valid report ID found");
      }

      console.log("Checking compliance with:", {
        reportId,
        spreadsheetId,
        clientEmail,
      });

      const response = await axios.post(
        "/api/compliance/check-report",
        {
          reportId,
          spreadsheetId,
          clientEmail,
          forceRefresh: true, // Add this parameter to force a fresh check
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        // Create a fresh summary using our improved categorizeViolations function
        const results = response.data.results;
        const violationCategories = categorizeViolations(results);

        // Update the summary with our newly categorized violations
        const summary = {
          ...response.data.summary,
          violationCategories,
        };

        // Pass the updated data to the parent component
        onStatusUpdate({
          results,
          summary,
        });

        // Automatically expand violations if there are any
        if (summary.violations > 0) {
          setShowViolations(true);
        }
      }
    } catch (error) {
      console.error("Error checking compliance:", error);
      console.error("Error details:", {
        status: error.response?.status,
        message: error.response?.data?.error || error.message,
        data: error.response?.data,
      });

      setError(
        error.response?.data?.error ||
          "Failed to check compliance. Please try again."
      );
    } finally {
      setIsChecking(false);
    }
  };

  const toggleCategory = (categoryIndex) => {
    setExpandedCategory(
      expandedCategory === categoryIndex ? null : categoryIndex
    );
    setExpandedItem(null); // Close any expanded items when toggling category
  };

  const toggleItem = (itemId) => {
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };

  // Add these functions inside your ComplianceCheck component
  // Place them after handleBatchFix and before handleActionClick functions

  // Render function for action buttons
  // Render function for action buttons
  const renderActionButton = (action, actionIndex, item) => {
    // Check if this is an override-related action
    const isOverrideAction =
      action.toLowerCase().includes("override") ||
      action.toLowerCase().includes("approve despite") ||
      action.toLowerCase().includes("manual approval");

    // If it's an override action, give it a special style and direct it to the override handler
    if (isOverrideAction) {
      return (
        <li key={actionIndex} className="action-item">
          <button
            className="action-button-d"
            onClick={() => {
              setSelectedExpense(item);
              setSelectedViolation(action);
              setApprovalOverrideModalOpen(true);
            }}
            disabled={isProcessingAction}
            data-action-type="override"
          >
            {isProcessingAction ? (
              <span className="action-button-loading">
                <i className="fas fa-spinner fa-spin"></i>
                Processing...
              </span>
            ) : (
              <>
                <i className="fas fa-check-circle mr-2"></i>
                {action}
              </>
            )}
          </button>
        </li>
      );
    }

    // Otherwise, use the regular action button logic
    return (
      <li key={actionIndex} className="action-item">
        <button
          className="action-button-d"
          onClick={() => handleActionClick(action, item)}
          disabled={isProcessingAction}
          data-action-type={getButtonStyle(action)}
        >
          {isProcessingAction ? (
            <span className="action-button-loading">
              <i className="fas fa-spinner fa-spin"></i>
              Processing...
            </span>
          ) : (
            <>
              <i className={`fas ${getActionIcon(action)} mr-2`}></i>
              {action}
            </>
          )}
        </button>
      </li>
    );
  };

  // Add a manual override button option at the end of action lists
  const renderManualOverrideButton = (item) => {
    // Skip if the item is already marked as approved via override
    if (item.approvalOverride && item.approvalOverride.approved) {
      return (
        <li className="action-item manual-override-info">
          <div className="override-status">
            <i className="fas fa-check-circle"></i>
            <span>
              Already approved by{" "}
              {item.approvalOverride.approver || "a reviewer"}
            </span>
          </div>
        </li>
      );
    }

    // Only add this if there isn't already an override action
    const alreadyHasOverride =
      item.actions &&
      item.actions.some(
        (action) =>
          action.toLowerCase().includes("override") ||
          action.toLowerCase().includes("approve despite") ||
          action.toLowerCase().includes("manual approval")
      );

    if (alreadyHasOverride) return null;

    return (
      <li className="action-item manual-override-button">
        <button
          className="action-button-d"
          onClick={() => {
            setSelectedExpense(item);
            setSelectedViolation(
              `Override policy violation for ${item.merchant}`
            );
            setApprovalOverrideModalOpen(true);
          }}
          disabled={isProcessingAction}
          data-action-type="override"
        >
          {isProcessingAction ? (
            <span className="action-button-loading">
              <i className="fas fa-spinner fa-spin"></i>
              <span>Processing...</span>
            </span>
          ) : (
            <>
              <i className="fas fa-check-circle mr-2"></i>
              Manual Approval Override
            </>
          )}
        </button>
      </li>
    );
  };

  // Helper function to determine button style class
  const getButtonStyle = (action) => {
    const lowerAction = action.toLowerCase();

    if (
      lowerAction.includes("business purpose") ||
      lowerAction.includes("purpose for") ||
      lowerAction.includes("document purpose")
    ) {
      return "purpose";
    }

    if (
      lowerAction.includes("mileage") ||
      lowerAction.includes("rate") ||
      lowerAction.includes("distance")
    ) {
      return "mileage";
    }

    if (lowerAction.includes("approval") || lowerAction.includes("authorize")) {
      return "approval";
    }

    if (
      lowerAction.includes("receipt") ||
      lowerAction.includes("document") ||
      lowerAction.includes("itemized")
    ) {
      return "receipt";
    }

    return ""; // Default style
  };

  // Helper function to get appropriate icon for action
  const getActionIcon = (action) => {
    const lowerAction = action.toLowerCase();

    if (
      lowerAction.includes("business purpose") ||
      lowerAction.includes("purpose for") ||
      lowerAction.includes("document purpose")
    ) {
      return "fa-comment-alt";
    }

    if (
      lowerAction.includes("mileage") ||
      lowerAction.includes("rate") ||
      lowerAction.includes("distance")
    ) {
      return "fa-route";
    }

    if (lowerAction.includes("approval") || lowerAction.includes("authorize")) {
      return "fa-check-square";
    }

    if (
      lowerAction.includes("receipt") ||
      lowerAction.includes("document") ||
      lowerAction.includes("itemized")
    ) {
      return "fa-receipt";
    }

    return "fa-tasks"; // Default icon
  };

  // New function to handle individual action button clicks
  // New enhanced function to handle individual action button clicks
  const handleActionClick = async (action, item) => {
    try {
      setIsProcessingAction(true);

      // Get action categorization from Gemini
      const categorizedAction = await ComplianceActionHandler.categorizeAction(
        action,
        item
      );

      console.log("Action categorization:", categorizedAction);

      // Define callbacks for each action type
      const actionCallbacks = {
        BUSINESS_PURPOSE: (item) => {
          // Open business purpose modal
          setSelectedExpense(item);
          setBusinessPurposeModalOpen(true);
        },

        APPROVAL_OVERRIDE: (item, actionData) => {
          // Open approval override modal
          setSelectedExpense(item);
          // Use the original action text as the violation description
          setSelectedViolation(actionData.details.originalAction || action);
          setApprovalOverrideModalOpen(true);
        },

        MILEAGE_RATE: (item) => {
          // For now, offer the override option for mileage rate adjustments
          toast.info(
            `Mileage rate adjustment will be available soon. Using override function instead.`,
            {
              autoClose: 5000,
              closeButton: true,
              onClick: () => {
                setSelectedExpense(item);
                setSelectedViolation(`Mileage rate override: ${action}`);
                setApprovalOverrideModalOpen(true);
              },
            }
          );

          // Automatically open the override modal after a short delay
          setTimeout(() => {
            setSelectedExpense(item);
            setSelectedViolation(`Mileage rate override: ${action}`);
            setApprovalOverrideModalOpen(true);
          }, 1000);
        },

        RECEIPT: (item) => {
          toast.info(
            `Receipt management will be available soon. Using override function instead.`,
            {
              autoClose: 5000,
              closeButton: true,
              onClick: () => {
                setSelectedExpense(item);
                setSelectedViolation(
                  `Receipt verification override: ${action}`
                );
                setApprovalOverrideModalOpen(true);
              },
            }
          );

          // Automatically open the override modal after a short delay
          setTimeout(() => {
            setSelectedExpense(item);
            setSelectedViolation(`Receipt verification override: ${action}`);
            setApprovalOverrideModalOpen(true);
          }, 1000);
        },

        APPROVAL: (item) => {
          // This is already essentially an override
          setSelectedExpense(item);
          setSelectedViolation(`Manual approval: ${action}`);
          setApprovalOverrideModalOpen(true);
        },

        LOCATION: (item) => {
          toast.info(
            `Location management will be available soon. Using override function instead.`,
            {
              autoClose: 5000,
              closeButton: true,
              onClick: () => {
                setSelectedExpense(item);
                setSelectedViolation(`Location update override: ${action}`);
                setApprovalOverrideModalOpen(true);
              },
            }
          );

          // Automatically open the override modal after a short delay
          setTimeout(() => {
            setSelectedExpense(item);
            setSelectedViolation(`Location update override: ${action}`);
            setApprovalOverrideModalOpen(true);
          }, 1000);
        },

        UNKNOWN: (item, actionData) => {
          // For unknown actions, use the override option directly
          console.log("Using fallback override for unknown action type");
          setSelectedExpense(item);
          setSelectedViolation(actionData.details.originalAction || action);
          setApprovalOverrideModalOpen(true);
        },
      };

      // Execute the appropriate action
      ComplianceActionHandler.executeAction(
        categorizedAction,
        item,
        actionCallbacks
      );
    } catch (error) {
      console.error("Error processing compliance action:", error);
      toast.error("Failed to process action. Please try again.");

      // In case of error, offer override as a fallback
      if (item) {
        setTimeout(() => {
          toast.info("You can use the override function instead", {
            autoClose: 5000,
            onClick: () => {
              setSelectedExpense(item);
              setSelectedViolation(action);
              setApprovalOverrideModalOpen(true);
            },
          });
        }, 1000);
      }
    } finally {
      setIsProcessingAction(false);
    }
  };
  // Add this right after your handleActionClick function

  const handleGenericOverride = (violation, item) => {
    // First show a toast message about future improvements
    toast.info(
      "Specialized handling for this type of issue is coming soon. Using override for now.",
      { autoClose: 3000 }
    );

    // Then open the override modal
    setSelectedExpense(item);
    setSelectedViolation(violation);
    setApprovalOverrideModalOpen(true);
  };

  // Handle successful business purpose update
  const handleBusinessPurposeSuccess = (updatedPurpose) => {
    // Refresh compliance check to reflect the changes
    handleCheck();

    // Show success message
    toast?.success("Business purpose updated successfully") ||
      alert("Business purpose updated successfully");
  };

  // Render results - enhanced with progressive disclosure
  const renderResults = () => {
    if (!report.compliance?.results) return null;

    const total = report.compliance.results.length;
    const compliant = report.compliance.results.filter(
      (r) => r.analysis.isCompliant
    ).length;
    const violations = total - compliant;

    // Get violation categories
    const categories =
      report.compliance.summary?.violationCategories ||
      categorizeViolations(report.compliance.results);

    return (
      <div className="compliance-summary">
        <div className="summary-header">
          <h4>Compliance Check Results</h4>
          <span
            className={`status-badge ${
              violations === 0 ? "success" : "warning"
            }`}
          >
            {violations === 0 ? "All Compliant" : `${violations} Issues`}
          </span>
        </div>

        <div className="summary-stats">
          <div className="stat">
            <span className="label">Total Items</span>
            <span className="value">{total}</span>
          </div>
          <div className="stat">
            <span className="label">Compliant</span>
            <span className="value success">{compliant}</span>
          </div>
          {violations > 0 && (
            <div className="stat">
              <span className="label">Issues</span>
              <span className="value warning">{violations}</span>
            </div>
          )}
        </div>

        {/* Progressive disclosure of violations */}
        {violations > 0 && (
          <div className="violations-container">
            <div className="violations-header">
              <h5>Issues Requiring Attention</h5>
              <button
                className="toggle-button"
                onClick={() => setShowViolations(!showViolations)}
              >
                <i
                  className={`fas ${
                    showViolations ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
                {showViolations ? "Hide Details" : "Show Details"}
              </button>
            </div>

            {showViolations && (
              <div className="violation-categories">
                {categories.map((category, index) => (
                  <div key={category.title} className="violation-category">
                    <div
                      className="category-header"
                      onClick={() => toggleCategory(index)}
                    >
                      <div className="category-title">
                        <i className={`fas ${category.icon}`}></i>
                        <span>{category.title}</span>
                        <span className="item-count">
                          {category.items.length}
                        </span>
                      </div>
                      <i
                        className={`fas ${
                          expandedCategory === index
                            ? "fa-chevron-up"
                            : "fa-chevron-down"
                        }`}
                      ></i>
                    </div>

                    {expandedCategory === index && (
                      <>
                        <ul className="violation-items">
                          {category.items.map((item) => (
                            <li key={item.id} className="violation-item">
                              <div
                                className="item-header"
                                onClick={() => toggleItem(item.id)}
                              >
                                <div className="item-title">
                                  <span className="merchant">
                                    {item.merchant}
                                  </span>
                                  <span className="amount">
                                    ${parseFloat(item.amount).toFixed(2)}
                                  </span>
                                </div>
                                <i
                                  className={`fas ${
                                    expandedItem === item.id
                                      ? "fa-chevron-up"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </div>

                              {expandedItem === item.id && (
                                <div className="item-details">
                                  {/* Consolidated violations - group by type */}
                                  {item.violations &&
                                  Array.isArray(item.violations) ? (
                                    <div className="violation-list">
                                      {/* Group similar violations */}
                                      {(() => {
                                        // Group violations by type
                                        const mileageViolations =
                                          item.violations.filter(
                                            (v) =>
                                              v
                                                .toLowerCase()
                                                .includes("mileage") ||
                                              v
                                                .toLowerCase()
                                                .includes("rate") ||
                                              v
                                                .toLowerCase()
                                                .includes("distance") ||
                                              v.toLowerCase().includes("miles")
                                          );

                                        const purposeViolations =
                                          item.violations.filter(
                                            (v) =>
                                              v
                                                .toLowerCase()
                                                .includes("purpose") ||
                                              v
                                                .toLowerCase()
                                                .includes("reason") ||
                                              v
                                                .toLowerCase()
                                                .includes("justification")
                                          );

                                        const receiptViolations =
                                          item.violations.filter(
                                            (v) =>
                                              v
                                                .toLowerCase()
                                                .includes("receipt") ||
                                              v
                                                .toLowerCase()
                                                .includes("documentation") ||
                                              v
                                                .toLowerCase()
                                                .includes("itemized")
                                          );

                                        const approvalViolations =
                                          item.violations.filter(
                                            (v) =>
                                              v
                                                .toLowerCase()
                                                .includes("approv") ||
                                              v
                                                .toLowerCase()
                                                .includes("authoriz")
                                          );

                                        // Other violations that don't fit the categories
                                        const otherViolations =
                                          item.violations.filter(
                                            (v) =>
                                              !v
                                                .toLowerCase()
                                                .includes("mileage") &&
                                              !v
                                                .toLowerCase()
                                                .includes("rate") &&
                                              !v
                                                .toLowerCase()
                                                .includes("distance") &&
                                              !v
                                                .toLowerCase()
                                                .includes("miles") &&
                                              !v
                                                .toLowerCase()
                                                .includes("purpose") &&
                                              !v
                                                .toLowerCase()
                                                .includes("reason") &&
                                              !v
                                                .toLowerCase()
                                                .includes("justification") &&
                                              !v
                                                .toLowerCase()
                                                .includes("receipt") &&
                                              !v
                                                .toLowerCase()
                                                .includes("documentation") &&
                                              !v
                                                .toLowerCase()
                                                .includes("itemized") &&
                                              !v
                                                .toLowerCase()
                                                .includes("approv") &&
                                              !v
                                                .toLowerCase()
                                                .includes("authoriz")
                                          );

                                        // Render grouped violations
                                        return (
                                          <>
                                            {mileageViolations.length > 0 && (
                                              <div className="violation-group mileage">
                                                <p className="violation-group-title">
                                                  <i className="fas fa-route"></i>{" "}
                                                  Mileage Issues:
                                                </p>
                                                {mileageViolations.map(
                                                  (v, i) => {
                                                    const hasHandler =
                                                      hasSpecificActionHandler(
                                                        v
                                                      );
                                                    const isInfo =
                                                      isInformationalViolation(
                                                        v,
                                                        hasHandler
                                                      );

                                                    return (
                                                      <div
                                                        key={`mileage-${i}`}
                                                        className="violation-container"
                                                      >
                                                        <p
                                                          className={`violation ${
                                                            isInfo
                                                              ? "informational"
                                                              : ""
                                                          }`}
                                                        >
                                                          {isInfo ? (
                                                            <>
                                                              <i
                                                                className="fas fa-info-circle"
                                                                style={{
                                                                  marginRight:
                                                                    "5px",
                                                                }}
                                                              ></i>
                                                              {v}
                                                              <span className="info-note">
                                                                {v
                                                                  .toLowerCase()
                                                                  .includes(
                                                                    "mileage rate"
                                                                  )
                                                                  ? "(This is for your information only)"
                                                                  : "(No specific handler available yet)"}
                                                              </span>
                                                            </>
                                                          ) : (
                                                            v
                                                          )}
                                                        </p>

                                                        {/* Show a single override button for violations without specific handlers */}
                                                        {!hasHandler && (
                                                          <div className="single-action-container">
                                                            <button
                                                              className="override-action-button"
                                                              onClick={() =>
                                                                handleGenericOverride(
                                                                  v,
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              <i className="fas fa-check-circle"></i>
                                                              Override
                                                            </button>
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            )}

                                            {purposeViolations.length > 0 && (
                                              <div className="violation-group purpose">
                                                <p className="violation-group-title">
                                                  <i className="fas fa-comment-alt"></i>{" "}
                                                  Business Purpose:
                                                </p>
                                                {purposeViolations.map(
                                                  (v, i) => (
                                                    <p
                                                      key={`purpose-${i}`}
                                                      className="violation"
                                                    >
                                                      {v}
                                                    </p>
                                                  )
                                                )}
                                              </div>
                                            )}

                                            {receiptViolations.length > 0 && (
                                              <div className="violation-group receipt">
                                                <p className="violation-group-title">
                                                  <i className="fas fa-receipt"></i>{" "}
                                                  Receipt Issues:
                                                </p>
                                                {receiptViolations.map(
                                                  (v, i) => (
                                                    <p
                                                      key={`receipt-${i}`}
                                                      className="violation"
                                                    >
                                                      {v}
                                                    </p>
                                                  )
                                                )}
                                              </div>
                                            )}

                                            {approvalViolations.length > 0 && (
                                              <div className="violation-group approval">
                                                <p className="violation-group-title">
                                                  <i className="fas fa-check-square"></i>{" "}
                                                  Approval Needed:
                                                </p>
                                                {approvalViolations.map(
                                                  (v, i) => (
                                                    <p
                                                      key={`approval-${i}`}
                                                      className="violation"
                                                    >
                                                      {v}
                                                    </p>
                                                  )
                                                )}
                                              </div>
                                            )}

                                            {otherViolations.length > 0 && (
                                              <div className="violation-group other">
                                                <p className="violation-group-title">
                                                  <i className="fas fa-exclamation-circle"></i>{" "}
                                                  Other Issues:
                                                </p>
                                                {otherViolations.map((v, i) => (
                                                  <p
                                                    key={`other-${i}`}
                                                    className="violation"
                                                  >
                                                    {v}
                                                  </p>
                                                ))}
                                              </div>
                                            )}
                                          </>
                                        );
                                      })()}
                                    </div>
                                  ) : (
                                    <p className="violation">
                                      {item.violation}
                                    </p>
                                  )}

                                  {/* Recommended actions section with consolidated buttons */}
                                  {item.actions && item.actions.length > 0 && (
                                    <div className="item-actions">
                                      <p className="actions-heading">
                                        Recommended Actions:
                                      </p>
                                      <ul className="action-list">
                                        {(() => {
                                          // Get unique actions
                                          const uniqueActions = Array.from(
                                            new Set(item.actions)
                                          );

                                          // Filter out actions related to mileage rate adjustment
                                          // Filter out actions for informational violations or those that will get a default override button
                                          const filteredActions =
                                            uniqueActions.filter((action) => {
                                              const lowerAction =
                                                action.toLowerCase();

                                              // Filter out standard rate actions that are just informational
                                              if (
                                                (lowerAction.includes(
                                                  "standard rate"
                                                ) ||
                                                  lowerAction.includes(
                                                    "mileage rate"
                                                  )) &&
                                                lowerAction.includes(
                                                  "current irs"
                                                ) &&
                                                !lowerAction.includes(
                                                  "exceed"
                                                ) &&
                                                !lowerAction.includes("limit")
                                              ) {
                                                return false;
                                              }

                                              return true;
                                            });

                                          // Group similar actions (like we did with violations)
                                          const businessPurposeActions =
                                            filteredActions.filter(
                                              (a) =>
                                                a
                                                  .toLowerCase()
                                                  .includes(
                                                    "business purpose"
                                                  ) ||
                                                a
                                                  .toLowerCase()
                                                  .includes("purpose for") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("document purpose")
                                            );

                                          const mileageActions =
                                            filteredActions.filter(
                                              (a) =>
                                                a
                                                  .toLowerCase()
                                                  .includes("mileage") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("rate") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("distance") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("miles")
                                            );
                                          const receiptActions =
                                            filteredActions.filter(
                                              (a) =>
                                                a
                                                  .toLowerCase()
                                                  .includes("receipt") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("document") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("itemized")
                                            );

                                          const approvalActions =
                                            filteredActions.filter(
                                              (a) =>
                                                a
                                                  .toLowerCase()
                                                  .includes("approval") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("authorize") ||
                                                a
                                                  .toLowerCase()
                                                  .includes("override")
                                            );

                                          const otherActions =
                                            filteredActions.filter(
                                              (a) =>
                                                !a
                                                  .toLowerCase()
                                                  .includes(
                                                    "business purpose"
                                                  ) &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("purpose for") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes(
                                                    "document purpose"
                                                  ) &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("mileage") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("rate") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("distance") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("miles") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("receipt") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("document") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("itemized") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("approval") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("authorize") &&
                                                !a
                                                  .toLowerCase()
                                                  .includes("override")
                                            );

                                          // Return our consolidated action buttons
                                          return (
                                            <>
                                              {/* Take only one action from each category to avoid duplication */}
                                              {businessPurposeActions.length >
                                                0 &&
                                                renderActionButton(
                                                  businessPurposeActions[0],
                                                  "bp",
                                                  item
                                                )}

                                              {mileageActions.length > 0 &&
                                                renderActionButton(
                                                  mileageActions[0],
                                                  "mi",
                                                  item
                                                )}

                                              {receiptActions.length > 0 &&
                                                renderActionButton(
                                                  receiptActions[0],
                                                  "re",
                                                  item
                                                )}

                                              {approvalActions.length > 0 &&
                                                renderActionButton(
                                                  approvalActions[0],
                                                  "ap",
                                                  item
                                                )}

                                              {/* For other actions, show all */}
                                              {otherActions.map((action, idx) =>
                                                renderActionButton(
                                                  action,
                                                  `other-${idx}`,
                                                  item
                                                )
                                              )}

                                              {/* Always add manual override option if not already present */}
                                              {renderManualOverrideButton(item)}
                                            </>
                                          );
                                        })()}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Show loading state
  if (isLoading) {
    return (
      <div className="compliance-check-container">
        <div className="compliance-controls">
          <button disabled className="compliance-button-placeholder">
            <div className="loading-button-content">
              <TailSpin color="#6c757d" height={16} width={16} />
              <span>Loading...</span>
            </div>
          </button>
        </div>
      </div>
    );
  }
  if (!hasComplianceDocuments && !report.compliance) {
    return null;
  }

  // If already have compliance results
  if (report.compliance) {
    return (
      <div className="compliance-check-container">
        <div className="compliance-controls">
          <button
            onClick={handleCheck}
            disabled={isChecking || !hasComplianceDocuments}
            className={`compliance-button ${
              !hasComplianceDocuments ? "disabled" : ""
            }`}
          >
            {isChecking ? (
              <div className="loading-button-content">
                <TailSpin color="#ffffff" height={16} width={16} />
                <span>Checking...</span>
              </div>
            ) : (
              <>
                <i className="fas fa-check-circle"></i>
                {hasComplianceDocuments
                  ? "Re-check Compliance"
                  : "No Policy Documents"}
              </>
            )}
          </button>
          {error && <div className="error-message">{error}</div>}
          {!hasComplianceDocuments && (
            <div className="info-message">
              Upload policy documents to run compliance checks
            </div>
          )}
        </div>

        {renderResults()}

        {/* Business Purpose Modal */}
        {businessPurposeModalOpen && selectedExpense && (
          <BusinessPurposeModal
            isOpen={businessPurposeModalOpen}
            onClose={() => {
              setBusinessPurposeModalOpen(false);
              setSelectedExpense(null);
            }}
            expenseId={selectedExpense.id}
            reportId={getReportId()}
            spreadsheetId={spreadsheetId}
            clientEmail={clientEmail}
            rowIndex={selectedExpense.rowIndex}
            currentPurpose={selectedExpense.notes || ""}
            onSuccess={handleBusinessPurposeSuccess}
          />
        )}

        {/* ADD THIS: Approval Override Modal */}
        {approvalOverrideModalOpen && selectedExpense && selectedViolation && (
          <ApprovalOverrideModal
            isOpen={approvalOverrideModalOpen}
            onClose={() => {
              setApprovalOverrideModalOpen(false);
              setSelectedExpense(null);
              setSelectedViolation(null);
            }}
            expenseId={selectedExpense.id}
            reportId={getReportId()}
            spreadsheetId={spreadsheetId}
            clientEmail={clientEmail}
            rowIndex={selectedExpense.rowIndex}
            merchant={selectedExpense.merchant}
            amount={selectedExpense.amount}
            category={selectedExpense.category}
            violation={selectedViolation}
            onSuccess={handleApprovalOverrideSuccess}
          />
        )}
      </div>
    );
  }

  return (
    <div className="compliance-check-container">
      <div className="compliance-controls">
        <button
          onClick={handleCheck}
          disabled={isChecking}
          className="compliance-button"
        >
          {isChecking ? (
            <div className="loading-button-content">
              <TailSpin color="#ffffff" height={16} width={16} />
              <span>Checking...</span>
            </div>
          ) : (
            <>
              <i className="fas fa-check-circle"></i> Check Compliance
            </>
          )}
        </button>
        {error && <div className="error-message">{error}</div>}
      </div>

      {renderResults()}

      {/* Business Purpose Modal */}
      {businessPurposeModalOpen && selectedExpense && (
        <BusinessPurposeModal
          isOpen={businessPurposeModalOpen}
          onClose={() => {
            setBusinessPurposeModalOpen(false);
            setSelectedExpense(null);
          }}
          expenseId={selectedExpense.id}
          reportId={getReportId()}
          spreadsheetId={spreadsheetId}
          clientEmail={clientEmail}
          rowIndex={selectedExpense.rowIndex}
          currentPurpose={selectedExpense.notes || ""}
          onSuccess={handleBusinessPurposeSuccess}
        />
      )}
      {/* Approval Override Modal */}
      {approvalOverrideModalOpen && selectedExpense && selectedViolation && (
        <ApprovalOverrideModal
          isOpen={approvalOverrideModalOpen}
          onClose={() => {
            setApprovalOverrideModalOpen(false);
            setSelectedExpense(null);
            setSelectedViolation(null);
          }}
          expenseId={selectedExpense.id}
          reportId={getReportId()}
          spreadsheetId={spreadsheetId}
          clientEmail={clientEmail}
          rowIndex={selectedExpense.rowIndex}
          merchant={selectedExpense.merchant}
          amount={selectedExpense.amount}
          category={selectedExpense.category} // Add this to pass the category
          violation={selectedViolation}
          onSuccess={handleApprovalOverrideSuccess}
        />
      )}
    </div>
  );
};

export default ComplianceCheck;
