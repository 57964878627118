// src/components/GmailProcessor.jsx
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Mail, RefreshCw, Check, Tag } from "lucide-react";
import "../styles/GmailProcessor.css";
import UploadProgressBar from "./UploadProgressBar";

const GmailProcessor = ({ onClose, onProcessingStarted }) => {
  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Start with loading true
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(0);
  const [redirectingToAuth, setRedirectingToAuth] = useState(false);

  // Tag management state
  const [availableTags, setAvailableTags] = useState([]);
  const [emailTags, setEmailTags] = useState({}); // Store tags for each email
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [activeTagsMenu, setActiveTagsMenu] = useState(null); // Track which email has open tag menu
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [newTagInput, setNewTagInput] = useState("");
  const [internalError, setInternalError] = useState("");
  const [isCreatingNewTag, setIsCreatingNewTag] = useState(false);

  const MAX_EMAILS = 25;

  // Fetch available tags
  const fetchAvailableTags = async () => {
    try {
      setIsLoadingTags(true);
      const response = await fetch("/api/tags", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch tags");
      }

      const data = await response.json();

      if (data.success && data.tags) {
        // Filter out any empty tags at the data level
        const validTags = data.tags.filter(
          (tagObj) => tagObj.tag && tagObj.tag.trim() !== ""
        );
        setAvailableTags(validTags);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
      // We don't show toast here to avoid cluttering the UI when loading the component
    } finally {
      setIsLoadingTags(false);
    }
  };

  // Fetch emails on component mount
  const fetchEmails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/gmail/list-emails", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.success) {
        // Limit to MAX_EMAILS
        const emails = response.data.emails || [];
        const limitedEmails = emails.slice(0, MAX_EMAILS);

        setEmailList(limitedEmails);

        // Initialize selectedEmails object - all selected by default
        const initialSelection = {};
        // Initialize emailTags object - no tags by default
        const initialTags = {};

        limitedEmails.forEach((email) => {
          initialSelection[email.id] = true;
          initialTags[email.id] = ""; // No tag selected initially
        });

        setSelectedEmails(initialSelection);
        setEmailTags(initialTags);

        // Notify user if we limited the emails
        if (emails.length > MAX_EMAILS) {
          toast.info(
            `Showing ${MAX_EMAILS} emails of ${emails.length} total. Process these first.`
          );
        }
      } else {
        // Handle auth error specially
        if (response.data.needsAuth) {
          handleAuthRedirect(response.data.authUrl);
          return;
        }

        toast.error("Failed to retrieve emails");
      }
    } catch (error) {
      console.error("Error fetching emails:", error);

      // Check for 401/403 status that might indicate auth issues
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        try {
          // Request the auth URL
          const authResponse = await axios.get("/api/gmail/auth-url");
          if (authResponse.data.authUrl) {
            // Show a more informative toast before redirecting
            toast.info(
              "Gmail permissions needed. Redirecting you to Google login...",
              {
                autoClose: 3000,
              }
            );

            setTimeout(() => {
              handleAuthRedirect(authResponse.data.authUrl);
            }, 1000);
            return;
          }
        } catch (authError) {
          console.error("Error getting auth URL:", authError);
        }
      }

      // More descriptive error toast for all other errors
      toast.error(
        "Unable to load emails. This may be due to Gmail permissions. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Handle redirect to Google auth page
  const handleAuthRedirect = (authUrl) => {
    setRedirectingToAuth(true);
    toast.info("Redirecting to Gmail for permission...", {
      autoClose: 2500,
    });

    // Short timeout to allow the toast to be visible
    setTimeout(() => {
      window.location.href = authUrl;
    }, 1000);
  };

  // Function to create a new tag
  const createNewTag = async (tagName) => {
    if (!tagName.trim()) return;

    try {
      const response = await fetch("/api/tags", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tags: [{ tag: tagName, isActive: true }],
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create tag");
      }

      // Refresh the tag list
      await fetchAvailableTags();

      return true;
    } catch (error) {
      console.error("Error creating tag:", error);
      setInternalError("Failed to create tag. Please try again.");
      return false;
    }
  };

  // Function to handle new tag input changes
  const handleNewTagChange = (e) => {
    setNewTagInput(e.target.value);
  };

  // Function to toggle between tag creation and selection
  const toggleNewTagCreation = (e) => {
    e.stopPropagation();

    // Check if tag limit is reached
    if (
      !isCreatingTag &&
      availableTags.filter((t) => t.tag && t.tag.trim() !== "").length >= 20
    ) {
      toast.warning(
        "Maximum of 20 tags allowed. Delete some tags in your spreadsheet before creating a new one."
      );
      return;
    }

    setIsCreatingTag(!isCreatingTag);
    setNewTagInput("");
  };

  // Function to save a new tag for an email
  const saveNewTagForEmail = async (emailId, e) => {
    e.stopPropagation();

    if (!newTagInput.trim()) {
      setInternalError("Tag name cannot be empty");
      return;
    }

    // Set loading state to true before starting
    setIsCreatingNewTag(true);

    try {
      const success = await createNewTag(newTagInput.trim());
      if (success) {
        // Set the new tag for the email
        selectTagForEmail(emailId, newTagInput.trim());

        // Reset creation mode
        setIsCreatingTag(false);
        setNewTagInput("");
      }
    } catch (error) {
      console.error("Error saving new tag:", error);
      setInternalError("Failed to save tag");
    } finally {
      // Always set loading state back to false when done
      setIsCreatingNewTag(false);
    }
  };

  useEffect(() => {
    fetchEmails();
    fetchAvailableTags();
  }, [fetchEmails]);

  const processEmails = async () => {
    console.log("processEmails function called");

    // Get selected email IDs
    const emailsToProcess = Object.keys(selectedEmails).filter(
      (id) => selectedEmails[id]
    );

    if (emailsToProcess.length === 0) {
      toast.warning("Please select at least one email to process");
      return;
    }

    setLoading(true);
    setCurrentEmailIndex(0);

    // Reset upload progress
    setUploadProgress(0);

    const processedItems = [];
    const totalEmails = emailsToProcess.length;
    const progressIncrement = 100 / totalEmails;

    // Process one email at a time to avoid Vercel timeout
    // Declare a variable to capture the processedLabelId from the responses
    let commonLabelId = null;

    // Process each email in the loop
    for (let i = 0; i < emailsToProcess.length; i++) {
      setCurrentEmailIndex(i);
      const emailId = emailsToProcess[i];

      try {
        // Process a single email
        const response = await axios.post(
          "/api/gmail/process-single-email",
          { emailId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        // If successful, add the processed items to our collection
        if (response.data.success && response.data.processedItems) {
          const labelId = response.data.processedLabelId; // Read label ID from this response
          if (!commonLabelId) {
            commonLabelId = labelId; // Capture from first successful response
          }

          // Get any tag associated with this email
          const emailTag = emailTags[emailId] || "";

          const enhancedItems = response.data.processedItems.map((item) => ({
            ...item,
            fromGmail: true,
            emailId, // So Cloud Run knows which email(s)
            processedLabelId: labelId, // Attach the label ID here as well
            tag: emailTag, // Add the tag selected by the user
          }));

          processedItems.push(...enhancedItems);
        }
      } catch (error) {
        console.error(`Error processing email ${emailId}:`, error);
        toast.error(`Failed to process email: ${error.message}`);
        // Continue with other emails despite errors
      }

      // Update progress after each email
      setUploadProgress(Math.floor((i + 1) * progressIncrement));
    }

    // Set progress to 100% when done
    setUploadProgress(100);
    setLoading(false);

    if (processedItems.length > 0) {
      toast.success(
        `Successfully processed ${processedItems.length} items from ${emailsToProcess.length} email(s)`
      );

      // Use the captured commonLabelId in gmailMetadata
      onProcessingStarted &&
        onProcessingStarted({
          userSelectedPhotos: processedItems,
          gmailMetadata: {
            emailIds: emailsToProcess, // Just pass the email IDs
            processedLabelId: commonLabelId, // Use the captured value
          },
        });

      onClose();
    } else {
      toast.info(
        "No processable items found in the selected emails. Please select emails with PDF attachments or readable content."
      );
    }
  };

  const toggleEmailSelection = (emailId) => {
    setSelectedEmails((prev) => ({
      ...prev,
      [emailId]: !prev[emailId],
    }));
  };

  // Function to handle tag selection for an email
  const selectTagForEmail = (emailId, tag) => {
    setEmailTags((prevTags) => ({
      ...prevTags,
      [emailId]: tag,
    }));

    // Close the tags menu
    setActiveTagsMenu(null);
  };

  // Function to toggle the tag menu for an email
  const toggleTagMenu = (emailId, event) => {
    // Stop propagation to prevent toggling the email selection
    event.stopPropagation();

    // Toggle the active menu
    setActiveTagsMenu(activeTagsMenu === emailId ? null : emailId);
  };

  const selectedCount = Object.values(selectedEmails).filter(Boolean).length;

  // If we're redirecting, show a simple loading state
  if (redirectingToAuth) {
    return (
      <div className="gmail-processor-modal">
        <div className="gmail-processor-content">
          <div className="gmail-processor-header">
            <Mail size={32} className="gmail-icon" />
            <h2>Connecting to Gmail</h2>
          </div>
          <div className="redirecting-container">
            <RefreshCw className="spin-icon" size={32} />
            <p>Redirecting to Gmail for permission...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="gmail-processor-modal">
      <div className="gmail-processor-content">
        <button onClick={onClose} className="close-x">
          ×
        </button>

        <div className="gmail-processor-header">
          <Mail size={32} className="gmail-icon" />
          <h2>Process Email Receipts</h2>
        </div>

        {/* Progress bar for upload/processing */}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="upload-progress-container">
            <UploadProgressBar progress={uploadProgress} />
            {loading && currentEmailIndex >= 0 && (
              <div className="processing-status">
                Processing email {currentEmailIndex + 1} of {selectedCount}
              </div>
            )}
          </div>
        )}

        <div className="email-selection">
          <div className="email-list-header">
            <h3>Emails to Process</h3>
            {!isLoading && emailList.length > 0 && (
              <p className="email-count">
                {`${selectedCount} of ${emailList.length} emails selected`}
              </p>
            )}
          </div>

          <div className="email-list1">
            {isLoading ? (
              <div className="loading-emails">
                <RefreshCw className="spin-icon" size={24} />
                <p>Checking your Gmail for labeled emails...</p>
              </div>
            ) : emailList.length === 0 ? (
              <div className="no-emails-container">
                <div className="no-emails-content">
                  <h4 className="no-emails-title">No emails found</h4>

                  {/* Brief intro paragraph */}
                  <p className="intro-text">
                    We've created an{" "}
                    <span className="label-expensebot">ExpenseBot</span> label
                    in Gmail for receipts. If you’ve already labeled your
                    emails, tap <strong>Refresh List</strong> below.
                  </p>

                  {/* Subheading for instructions */}
                  <h5 className="label-instructions-heading">
                    How to label receipts in Gmail
                  </h5>
                  <ul className="label-instructions-list">
                    <li>
                      <strong>Select</strong> your receipt emails
                    </li>
                    <li>
                      <strong>Click</strong> the <em>Label icon</em>
                      <strong> or </strong> the <em>three-dot menu</em> →{" "}
                      <strong>“Label as”</strong>
                    </li>
                    <li>
                      <strong>Pick</strong>{" "}
                      <span className="label-expensebot">ExpenseBot</span>
                      from the list
                    </li>
                  </ul>

                  <p className="processed-note">
                    We’ll mark them with a{" "}
                    <span className="label-processed">Processed</span> label
                    after we're done processing them.
                  </p>

                  <p className="help-link">
                    Need more help?{" "}
                    <a
                      href="https://www.expensebot.ai/faq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See our FAQ
                    </a>
                    .
                  </p>

                  <button className="refresh-button" onClick={fetchEmails}>
                    <RefreshCw size={16} />
                    <span>Refresh List</span>
                  </button>
                </div>
              </div>
            ) : (
              <>
                {emailList.map((email) => (
                  <div
                    key={email.id}
                    className={`email-item ${
                      selectedEmails[email.id] ? "selected" : ""
                    }`}
                    onClick={() => toggleEmailSelection(email.id)}
                  >
                    <div className="email-checkbox">
                      {selectedEmails[email.id] && <Check size={16} />}
                    </div>
                    <div className="email-details">
                      <div className="email-sender">{email.from}</div>
                      <div className="email-subject">{email.subject}</div>
                      <div className="email-date">{email.date}</div>

                      {/* Tag selection UI */}
                      <div
                        className="email-tag-container"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          className="email-tag-button"
                          onClick={(e) => toggleTagMenu(email.id, e)}
                        >
                          <Tag size={12} />
                          <span>{emailTags[email.id] || "Add Tag"}</span>
                        </button>

                        {/* Tag selection dropdown */}
                        {activeTagsMenu === email.id && (
                          <div className="email-tag-dropdown">
                            {isLoadingTags ? (
                              <div className="tag-loading">Loading tags...</div>
                            ) : isCreatingTag ? (
                              // New tag creation UI
                              <div className="create-tag-container">
                                <input
                                  type="text"
                                  value={newTagInput}
                                  onChange={handleNewTagChange}
                                  placeholder="Enter new tag name"
                                  className="new-tag-input"
                                  maxLength={30}
                                  onClick={(e) => e.stopPropagation()}
                                />
                                {internalError && (
                                  <div className="tag-error">
                                    {internalError}
                                  </div>
                                )}
                                <div className="tag-actions">
                                  <button
                                    className="tag-cancel-btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsCreatingTag(false);
                                      setNewTagInput("");
                                    }}
                                    disabled={isCreatingNewTag}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="tag-save-btn"
                                    onClick={(e) =>
                                      saveNewTagForEmail(email.id, e)
                                    }
                                    disabled={isCreatingNewTag}
                                  >
                                    {isCreatingNewTag ? (
                                      <span className="tag-loading-spinner">
                                        <RefreshCw
                                          size={12}
                                          className="spin-icon"
                                        />
                                      </span>
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                </div>
                              </div>
                            ) : availableTags.length > 0 ? (
                              <>
                                <div className="tag-header">
                                  <span>Select a tag</span>
                                  <button
                                    className="create-new-tag-btn"
                                    onClick={toggleNewTagCreation}
                                  >
                                    + New
                                  </button>
                                </div>
                                <div
                                  className="tag-option no-tag"
                                  onClick={() =>
                                    selectTagForEmail(email.id, "")
                                  }
                                >
                                  No Tag
                                </div>
                                {availableTags.map((tagObj) => (
                                  <div
                                    key={tagObj.tag}
                                    className={`tag-option ${
                                      emailTags[email.id] === tagObj.tag
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      selectTagForEmail(email.id, tagObj.tag)
                                    }
                                  >
                                    {tagObj.tag}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="no-tags-available">
                                <p>No tags available</p>
                                <button
                                  className="create-first-tag-btn"
                                  onClick={toggleNewTagCreation}
                                >
                                  Create your first tag
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="action-buttons1">
            <button
              className="back-button"
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </button>

            <button
              className="process-button"
              onClick={processEmails}
              disabled={
                loading || emailList.length === 0 || selectedCount === 0
              }
            >
              {loading ? (
                <RefreshCw className="spin-icon" size={16} />
              ) : (
                <Mail size={16} />
              )}{" "}
              {loading
                ? "Processing..."
                : `Process ${selectedCount} Email${
                    selectedCount !== 1 ? "s" : ""
                  }`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GmailProcessor;
