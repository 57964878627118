import React, { useState, useEffect, useRef } from "react";
import ComplianceDocuments from "./ComplianceDocuments";
import "../styles/ComplianceDocumentsModal.css";

const ComplianceDocumentsModal = ({ onClose, userEmail }) => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const modalContentRef = useRef(null);

  // Prevent body scrolling when modal is open
  useEffect(() => {
    // Save current body overflow style
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Prevent scrolling on the main body
    document.body.style.overflow = "hidden";

    // Mark initial load as complete after the first render
    setInitialLoadComplete(true);

    // Cleanup function to restore body overflow when modal is closed
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  // Handle clicks outside modal content or on close button
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="compliance-modal-overlay" onClick={handleBackdropClick}>
      <div
        ref={modalContentRef}
        className="compliance-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="compliance-modal-header">
          <h3>Compliance Documents</h3>
          <button onClick={onClose} className="compliance-close-button">
            &times;
          </button>
        </div>

        <div className="compliance-modal-body">
          <ComplianceDocuments
            isActive={true}
            userEmail={userEmail || ""}
            skipRefreshes={initialLoadComplete}
          />
        </div>
      </div>
    </div>
  );
};

export default ComplianceDocumentsModal;
