// TagManagementModal.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "../styles/TagManagementModal.css";

const TagManagementModal = ({ isOpen, onClose, onTagsUpdated }) => {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  // Fetch tags when modal opens
  useEffect(() => {
    if (isOpen) {
      fetchTags();
    }
  }, [isOpen]);

  // Fetch all tags from the server
  const fetchTags = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/tags?includeInactive=true", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (res.data.success) {
        // Including all tags, not just active ones
        setTags(res.data.tags);
      } else {
        toast.error("Failed to load tags.");
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("An error occurred while fetching tags.");
    } finally {
      setLoading(false);
    }
  };

  // Toggle tag active status
  const handleToggleTag = (tagName) => {
    setTags((prevTags) =>
      prevTags.map((tag) =>
        tag.tag.toLowerCase() === tagName.toLowerCase()
          ? { ...tag, isActive: !tag.isActive }
          : tag
      )
    );
  };

  // Add a new tag ensuring the active tags count is less than 20
  const handleAddTag = () => {
    const activeCount = tags.filter((tag) => tag.isActive).length;
    if (activeCount >= 20) {
      toast.error(
        "Maximum of 20 active tags reached. Deactivate one to add a new tag."
      );
      return;
    }
    if (newTag.trim() === "") {
      toast.error("Please enter a tag name.");
      return;
    }
    // Prevent duplicates (case-insensitive)
    if (
      tags.some((tag) => tag.tag.toLowerCase() === newTag.trim().toLowerCase())
    ) {
      toast.error("Tag already exists.");
      return;
    }
    setTags((prevTags) => [
      ...prevTags,
      { tag: newTag.trim(), columnB: "", isActive: true },
    ]);
    setNewTag("");
  };

  // Save updated tags to the server
  const handleSave = async () => {
    try {
      setSaving(true);
      const res = await axios.post(
        "/api/tags",
        { tags },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res.data.success) {
        toast.success("Tags updated successfully.");
        if (onTagsUpdated) {
          onTagsUpdated();
        }
        onClose();
      } else {
        toast.error(res.data.message || "Failed to update tags.");
      }
    } catch (error) {
      console.error("Error updating tags:", error);
      toast.error("An error occurred while saving tags.");
    } finally {
      setSaving(false);
    }
  };

  // Group tags into Active and Not Active
  // A tag is inactive if isActive is explicitly false
  const activeTags = tags.filter((tag) => tag.isActive);
  const inactiveTags = tags.filter((tag) => tag.isActive === false);

  // Do not render if modal is not open
  if (!isOpen) return null;

  return (
    <div className="tag-mgmt-backdrop" onClick={onClose}>
      <div className="tag-mgmt-modal" onClick={(e) => e.stopPropagation()}>
        <div className="tag-mgmt-header">
          <h3>Manage Tags</h3>
          <button className="tag-mgmt-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="tag-mgmt-description">
          <p>
            Tags help you group expenses by client name, project name, trip
            name, or any other category that works for you. When you submit an
            expense, you can select a tag that will appear in your spreadsheet
            to make tracking and reporting easier.
          </p>
        </div>
        <div className="tag-mgmt-body">
          {loading ? (
            <div className="tag-mgmt-loading-indicator">Loading tags...</div>
          ) : (
            <>
              <div className="tag-mgmt-group">
                <h4>
                  Active Tags{" "}
                  <span className="tag-mgmt-count">
                    ({activeTags.length}/20)
                  </span>
                </h4>
                {activeTags.length === 0 ? (
                  <p className="tag-mgmt-empty-message">No active tags.</p>
                ) : (
                  <ul>
                    {activeTags.map((tag, index) => (
                      <li key={index}>
                        <span>{tag.tag}</span>
                        <button
                          onClick={() => handleToggleTag(tag.tag)}
                          className="tag-mgmt-toggle-button"
                        >
                          Deactivate
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="tag-mgmt-group">
                <h4>Inactive Tags</h4>
                {inactiveTags.length === 0 ? (
                  <p className="tag-mgmt-empty-message">No inactive tags.</p>
                ) : (
                  <ul>
                    {inactiveTags.map((tag, index) => (
                      <li key={index}>
                        <span>{tag.tag}</span>
                        <button
                          onClick={() => handleToggleTag(tag.tag)}
                          className="tag-mgmt-toggle-button"
                        >
                          Activate
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="tag-mgmt-add-section">
                <h4>Add New Tag</h4>
                <div className="tag-mgmt-input-group">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Enter new tag name"
                    onKeyPress={(e) => e.key === "Enter" && handleAddTag()}
                  />
                  <button
                    onClick={handleAddTag}
                    className="tag-mgmt-add-button"
                  >
                    Add
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="tag-mgmt-footer">
          <button
            onClick={handleSave}
            disabled={saving || loading}
            className="tag-mgmt-save-button"
          >
            {saving ? "Saving..." : "Save Changes"}
          </button>
          <button
            onClick={onClose}
            disabled={saving}
            className="tag-mgmt-cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagManagementModal;
